<template>
  <section>
    <component
      v-for="(block, index) in content"
      :is="ComponentMap[block.type]"
      :key="index"
      :index="index"
      :component="block"
      :prop="block"
      :class="block.class"
      content="content"
      v-bind="getBlockProps(block, index)"
      class="mn-b-regular"
    />
  </section>  
</template>

<script setup>
  import Image  from '@pf/src/modules/constructor/components/elements/Image.vue';
  import VideoPlayer  from '@pf/src/modules/constructor/components/elements/VideoPlayer.vue';
  import Embed  from '@pf/src/modules/constructor/components/elements/Embed.vue';

  const props = defineProps([
    'content'
  ])

  const ComponentMap = {
    'Textarea': 'p',
    'H2': 'h2',
    'Bullets': 'p',
    'Caption': 'p',
    'ImageUpload': Image,
    'Video': VideoPlayer,
    'Link': 'a',
    'Embed': Embed
  };

  function getBlockProps(block, index) {
    if (block.type === 'Textarea' && index !== 0) {
      return { innerHTML: block.content, class: "p-big"  };
    } else if (block.type === 'Textarea' && index === 0) { 
       return { innerHTML: block.content, class: "h2" };
    }
    else if (block.type === 'H2') {
      return { innerHTML: block.content, class: "h3" };
    } else if (block.type === 'Caption') {
      return { innerHTML: block.content };
    } else if (block.type === 'ImageUpload') {
      return { src: block.content };
    }
    return {};
  }
</script>