<template>
	<div v-if="order && orderOrganization" class="pd-thin"> 
	  
	

	  <div v-if="order" class="mn-b-thin pos-relative w-100 bg-main radius-big pd-big">

	  	<h2 class="mn-b-small">Order is {{order.status}}</h2>
	  	<p class="mn-b-medium w-m-40r p-medium">Your order has been successfully created. Our manager will contact you within five minutes to confirm your order.</p>
	  
	  	
	  	<!-- <p class="p-medium mn-b-thin t-semi">{{ statuses.find(status => status.value === order.status).nextstatus}}</p> -->

	  	<div class="radius-extra  mn-b-medium flex-nowrap flex h-1r pos-relativew-40r">
	  		<div 
	  			class="bg-light br-anim br-glow radius-extra mn-r-thin h-100 w-20"
	  			:class="
	  				{'br-anim br-glow br-glow-anim': ['created','confirmed', 'packing','in delivery','delivered'].indexOf(order.status) === 0 }
	  			"
	  		/>
	  		<div 
	  			class="bg-light br-anim br-glow radius-extra mn-r-thin h-100 w-20"
	  			:class="
	  				{'br-anim br-glow br-glow-anim': ['created','confirmed', 'packing','in delivery','delivered'].indexOf(order.status) === 0 },
	  					{'t-transp': ['created','confirmed', 'packing','in delivery','delivered'].indexOf(order.status) === -1 },
	  				{'blink': order.status === 'created' }
	  			"
	  		/>
	  		<div 
	  			:class="
	  				{'br-anim br-glow br-glow-anim': ['confirmed', 'packing','in delivery','delivered'].indexOf(order.status) === 0 },
	  				{'t-transp': ['confirmed', 'packing','in delivery','delivered'].indexOf(order.status) === -1 },
	  				{'blink': order.status === 'confirmed' }
	  			"
	  			class="bg-light radius-extra mn-r-thin h-100 w-40"
	  		/>
	  		<div 
	  			class="bg-light radius-extra mn-r-thin h-100 w-40"
	  			:class="
	  				{'br-anim br-glow br-glow-anim': ['packing','in delivery','delivered'].indexOf(order.status) === 0 },
	  				{'t-transp': ['packing','in delivery','delivered'].indexOf(order.status) === -1 },
	  				{'blink': order.status === 'packing' }
	  			"
	  		/>
	  		<div 
	  			class="bg-light radius-extra h-100 w-20"
	  			:class="
	  				{'br-anim br-glow br-glow-anim': ['in delivery','delivered'].indexOf(order.status) === 0 },
	  				{'t-transp': ['in delivery','delivered'].indexOf(order.status) === -1 },
	  				{'blink': order.status === 'in delivery' }
	  			"
	  		/>
	  	</div>

	  	<!-- <div class="w-max pd-thin radius-small bg-white-transp-20"> -->
		  	<p>Estimated Arrival {{formatDate(calculateDeliveryTime(orderOrganization[0]?.distance, order.createdAt))}}</p>
		  	<!-- Estimated in minutes -->
	  		<h3>{{
	  			orderOrganization[0]?.distance ? 
          Math.ceil((5 + 5 + 3 * orderOrganization[0]?.distance) / 5) * 5 
          + '-' 
          + Math.ceil((15 + 5 + 3 * orderOrganization[0]?.distance) / 5) * 5 
          + ' MIN' : 'Unavailable' 
	  		}}</h3>
	  	<!-- </div> -->
	  
	  
    	<p class=" pos-absolute pos-t-medium pos-r-medium t-medium p-medium">
        Order No: <span class="p-small t-transp">#{{order._id}}</span>
      </p>
	  </div>


	  
	  <div v-if="order" class="gap-thin cols-2">

	    <div class="w-100">

		    <CardOrganization 
		    	v-if="orderOrganization[0]"
		      :organization="orderOrganization[0]"
		      :showRating="true"
		      :showFollowers="false"
		      :showProducts="false"
		      class="bg-light mn-b-thin w-100 o-hidden radius-big pd-small "
		    />

		    <div 
		    	class="mn-b-thin bg-light w-100 o-hidden radius-medium "
		    >
		    	<div class="pd-small flex-nowrap flex">
			    	<div class="mn-r-auto">
				    	<span class="d-block t-medium p-medium">Your order is</span>
					    <span class=" t-lh-075 h2 d-block mn-b-small">{{order.status}}</span>
					    <p class="pd-t-nano pd-b-nano pd-r-thin pd-l-thin radius-extra bg-black t-white w-max">as on 29 aug 2021, Friday</p>
					  </div>
					  <!-- <div class="t-right">
					  	Cancel order<br>
					  	Exchange item
					  	<hr class="mn-b-thin mn-t-thin">
					  	For Delivery Queries Contact Us
					  	
					  </div> -->
					</div>

					<div class="pd-small cols-2 br-t br-black-transp-10 br-solid">
			    	<p class="w-100 t-medium p-medium">
			      	Phone
			      </p>

			      <p class="w-100 t-right ">
			        {{order.user?.number}} 
			      </p>
			    </div>

			    <div v-if="order.user?.messenger.type" class="pd-small cols-2 br-t br-black-transp-10 br-solid">
			    	<p class="w-100 t-medium p-medium">
			      	{{order.user?.messenger.type}}
			      </p>

			      <p class="w-100 t-right ">
			        {{order.user?.messenger.value}} 
			      </p>
			    </div>

			    <div class="pd-small  cols-2  br-t br-black-transp-10 br-solid">
			    	<p class="t-medium p-medium">
			      	Address
			      </p>

			      <p class="w-100 t-right">
			        {{order.delivery?.address.slice(0,64)}} 
			      </p>
			    </div>

			    <div class="pd-small  cols-2  br-t br-black-transp-10 br-solid">
			    	<p class="mn-b-small t-medium p-medium">
			      	Delivery
			      </p>

			      <p class="w-100 t-right mn-b-small">
			        {{order.delivery?.type}} 
			      </p>
			    </div>

				</div>
				
	     	<StatusHistory 
	     		v-if="orders.state.current.status_history"
	     		:statusHistory="orders.state.current.status_history"
	     		:statusCurrent="orders.state.current.status"
	     		:edit="route.name === 'Order Edit'"
	     	/>

	     <!-- 	<button 
	     	@click="requestPayment(order)"
	     	v-if="
	     		order.status === 'Подтвержден' 
	     		&& order.payment.type === 'Online'
	     		&& payment.Status !== 'CONFIRMED'
	     		&& payment.Status !== 'REFUNDED'
	     	" 
	     	class='w-100 button'>Оплатить заказ</button> -->
	     	<span class="t-transp" v-if="payment && payment.Status === 'CONFIRMED'">Paid</span> 
	    </div>  

	    <div class="bg-light radius-big pd-medium  mn-r-bold pd-r-big br-right-dark w-100">
	  		<h3 class="mn-b-small">Order Summary</h3>

	  		<ul class="flex gap-thin flex-column mn-b-semi">
	  			<CardOrderItem
	  				v-for="(product, index) in orderProducts" :key="product._id"
	  				:editable="false" 
	  				:product="product" 
	  			/>
	      </ul>
	      
	      <div class="mn-b-medium radius-small br-solid br-1px br-black-transp-10 w-100">
		      <div class="pd-small br-solid br-b br-black-transp-10  flex">
		        <p class="mn-r-auto t-transp">In total</p>
		        <p>{{ cartTotalPrice }} {{returnCurrency()}} </p>
		      </div>
		      <div class="pd-small br-solid br-b br-black-transp-10  flex">
		        <p class="mn-r-auto  t-transp">Delivery</p>
		        <p>{{returnCurrency()}} {{ Math.trunc(100 + 20 * orderOrganization[0]?.distance - 7,5 * (orderOrganization[0]?.distance  / 10))}} </p>
		      </div>
		      <div class="pd-small  flex-bottom flex">
		        <p class="mn-r-auto t-transp">In total to pay</p>
		        <p class="p-big t-semi">{{returnCurrency()}} {{ Math.trunc(100 + 20 * orderOrganization[0]?.distance - 7,5 * (orderOrganization[0]?.distance  / 10)) + cartTotalPrice }}</p>
		      </div>
		    </div>

		    <h3 class="mn-b-small">Chat With Us</h3>

		    <ChatWindow
		    	:username="
		    		route.name === 'Order Status' ? order.user?.name || 'User' : 'Weeder Support'
		    	"
		    	:chatID="route.params.order"
		    	class="radius-semi bg-white o-hidden"
		    />

	    </div>
	  </div>
	</div>		

</template>


<script setup="props">
	import { computed, ref, onMounted  } from 'vue'
	import { useRouter, useRoute } from 'vue-router'
	
	import CardOrderItem  from '@pf/src/modules/orders/components/blocks/CardOrderItem.vue'
	import CardOrganization from '@pf/src/modules/organizations/components/blocks/CardOrganization.vue'

	import ChatWindow from '@pf/src/modules/chats/components/pages/ChatPage.vue';

	import StatusHistory from '@pf/src/modules/orders/components/blocks/StatusHistory.vue'

	import * as globals 	from '@pf/src/modules/globals/views/store/globals'
	import * as orders 	from '@pf/src/modules/orders/store/orders'
	import * as products 	from '@pf/src/modules/products/store/products'
	import * as organizations 	from '@pf/src/modules/organizations/store/organizations'

	const router = useRouter()
	const route = useRoute()

	const order = ref(null)
	const productsOrganization = ref(null)
	const orderOrganization = ref(null)

	onMounted(async()=>{
		order.value = await orders.actions.read({_id: route.params.order})
		order.value = order.value[0]

		productsOrganization.value = await products.actions.read({
	    organization: order.value?.owner.target._id
	  });

	  orderOrganization.value = await organizations.actions.read({
	    _id: order.value?.owner.target._id,
	    location: globals.state.position?.location,
	  });
	})

	let cartTotalPrice = computed(() => {
    return Number(order.value?.positions.reduce((total, product) => {
      return total + product.price * product.quantity;
    }, 0));
  })

  let cartTotalAmount = computed(() => {
    return Number(order.value?.positions.reduce((total, product) => {
      return total + product.quantity;
    }, 0));
  })

  let orderProducts = computed(() => {
    return order.value?.positions.map(({ _id, quantity }) => {

	    const product = productsOrganization.value.find(product => product._id === _id)

		    return {
	      name: product.name,
	      images: product.images,
	      price: product.price,
	      _id: product._id,
	      quantity
	    }
	  })
  })

	function requestPayment(order) {
		 // store.dispatch("payments/newPayment", order);
	}

	function calculateDeliveryTime(distance, date) {
	  if (!distance) {
	    return null;
	  }

	  const basePreparationTime = 10 * 60 * 1000; // Convert to milliseconds
	  const travelTime = Math.ceil((15 + 5 + 3 * distance) / 5) * 5 * 60 * 1000;

	  // Преобразование даты начала в миллисекунды и добавление времени подготовки и доставки
	  const startTime = new Date(date).getTime();
	  const estimatedDeliveryTime = new Date(startTime + basePreparationTime + travelTime);

	  console.log(date)
	  console.log(estimatedDeliveryTime)

	  return estimatedDeliveryTime;
	}


</script>

<style lang="scss" scoped>
	.blink {
	  animation: blink-animation 1s ease infinite;
	}

	@keyframes blink-animation {
	  0%, 100% {
	    opacity: 1;
	  }
	  50% {
	    opacity: 0.8;
	  }
	}
</style>