<template>
	<svg 
		viewBox="0 0 42 42"
		fill="none" 
		xmlns="http://www.w3.org/2000/svg">
		<circle 
			fill="rgb(var(--main))" 
			cx="21" 
			cy="21" 
			r="21" 
		/>
		<path 
			fill="rgb(var(--black))" 
			fill-opacity="0.1" 
			fill-rule="evenodd" 
			clip-rule="evenodd" 
			d="M8.00233 37.4954C8.00078 37.4458 8 37.3961 8 37.3462C8 32.9066 11.5673 29.3077 15.9677 29.3077H26.0323C30.4328 29.3077 34 32.9066 34 37.3462C34 37.3961 33.9992 37.4458 33.9977 37.4954C30.4223 40.3165 25.9078 42 21 42C16.0922 42 11.5777 40.3164 8.00233 37.4954ZM21 9C16.5995 9 13.0323 12.5989 13.0323 17.0385C13.0323 21.478 16.5995 25.0769 21 25.0769C25.4005 25.0769 28.9677 21.478 28.9677 17.0385C28.9677 12.5989 25.4005 9 21 9Z"
		/>
	</svg>
</template>