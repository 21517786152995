import layoutEmpty from '@pf/src/modules/globals/views/components/layouts/Empty.vue';

const orders = [
   {
    path: 'orders',
    meta: {
      title: {
        en: 'Backoffice Orders',
        ru: 'Управление Заказами'
      },
    },
    component: layoutEmpty,
    children: [{
      path: '',
      name: 'Backoffice Orders',
      meta: {
        title: {
          en: 'Backoffice Orders',
          ru: 'Управление Заказами'
        },
      },
      component: () => import(/* webpackChunkName: 'BackofficeOrders' */ '@pf/src/modules/orders/components/pages/Orders.vue'),
    },{
      path: 'add',
      name: 'AdminOrderAdd',
      component: () => import(/* webpackChunkName: 'BackofficeOrders' */ '@pf/src/modules/orders/components/pages/OrderCreateBackoffice.vue')
    },{
      path: ':order',
      name: 'AdminOrderEdit',
      component: () => import(/* webpackChunkName: 'BackofficeOrders' */ '@pf/src/modules/orders/components/pages/OrderBackoffice.vue')
    }]
  }
];



export default orders;