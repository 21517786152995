<template>
  <div>
    <div @click="showSpoiler = !showSpoiler" class="flex-v-center flex-nowrap flex">
      <slot name="header" :is-open="showSpoiler"></slot>
    </div>

    <transition name="collapse">
      <div v-show="showSpoiler" class="spoiler">
        <slot name="content"></slot>
      </div>
    </transition>

  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';

  const props = defineProps({
    status: {
      type: Boolean,
      default: false
    }
  });

  const showSpoiler = ref(false);

  if (props.status) showSpoiler.value = props.status
</script>

<style lang="scss">
  .spoiler {
    transition: max-height 0.3s cubic-bezier(.15,.68,.33,.93);
  }
  .collapse-enter-active,
  .collapse-leave-active {
    max-height: 2000px;
  }

  .collapse-enter-from,
  .collapse-leave-to {
    max-height: 0;
  }
</style>  