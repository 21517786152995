<script setup>
  import { ref, computed, onUpdated, onMounted, nextTick } from 'vue'

  const emit = defineEmits(['update:field', 'focus', 'blur']);

  const props = defineProps({
    label: null,
    symbol: null,
    type: 'text',
    placeholder: 'Enter something here',
    field: null,
    value: null,
    validation: false,
    disabled: null,
    tabindex: -1
  });

  if (props.value) {
    emit('update:field', props.value)
  }

  const textarea = ref(null);

  function resize() {
    if (textarea.value) textarea.value.style.height = "1rem";
    if (textarea.value) textarea.value.style.height = textarea.value?.scrollHeight + "px";
  }


  onUpdated(() => {
   if (props.type === 'textarea') nextTick(() => {  { focus()} })
    resize()
  });

  onMounted(() => {
    if (props.type === 'textarea') nextTick(() => {  { focus()} })
    resize()
  });

  const text = ref(props.field);
</script>

<template>
  <div 
    :class="[
      $attrs.class, 
      { 
        'bg-fourth-nano': validation 
      }
    ]" 
    class="flex-center flex-nowrap flex"
  >
    <div 
      v-if="label" 
      class="t-transp mn-r-small"
    >
      <span>
        {{label}}
      </span>
    </div>

    <div class="w-100">
      <input 
        v-if="type !== 'textarea'"
        @input="event => text = emit('update:field', event.target.value)"
        @focus="event => emit('focus', event)"
        @blur="event => emit('blur', event)"
        :type="type"
        class="w-100"
        :value="field"
        :placeholder="placeholder"
        :tabindex="tabindex"
        :disabled="disabled"
      />
      <textarea 
        v-else
        ref="textarea"
        @input="event => text = emit('update:field', event.target.value)"
        @focus="event => emit('focus', event)"
        @blur="event => emit('blur', event)"
        class="w-100"
        style="resize: vertical;"
        :value="field"
        :placeholder="placeholder"
        :tabindex="tabindex"
         :disabled="disabled"
      />
    </div> 

    <slot></slot>

    <div 
      v-if="symbol" 
      class="t-transp mn-l-small"
    >
      <span>
        {{symbol}}
      </span>
    </div>
  </div>

  <!-- Validation -->
  <transition name="fade">
    <div v-if="validation" class="mn-t-thin mn-b-thin mn-b-thin invalid-feedback">
      * {{validation.message}}
    </div>
  </transition>
</template>

<style lang="scss" scoped>
  input, textarea, span {
    line-height: 1;
    color: inherit;
  }
</style>