<template>
  <!-- <div class="chat-page h-max-20r"> -->
    <!-- <aside class="chat-list"> -->
      <!-- Примерный список чатов. Должен быть дополнен реальной логикой -->
      <!-- <div v-for="chat in chats" :key="chat.id" @click="selectChat(chat.id)">
        Чат {{ chat.name }}
      </div> -->
    <!-- </aside> -->
    <!-- <main class="chat-main"> -->
      <ChatWindow />
    <!-- </main> -->
  <!-- </div> -->
</template>

<script setup>
import { ref, onMounted } from 'vue';
import ChatWindow from '../sections/ChatWindow.vue';
import chatStore from '../../store/chat.store.js';

const props = defineProps({
  username: {
    type: String,
    required: true
  },
  chatID: {
    type: String,
    required: true
  },
})

// const chats = ref([
//   // Примерный список. В реальном приложении, список чатов должен загружаться с сервера.
//   { id: 'chat1', name: 'Чат 1' },
//   { id: 'chat2', name: 'Чат 2' }
// ]);

const selectChat = (chatId) => {
  chatStore.methods.setCurrentChat(chatId);
};

onMounted(async() => {
  chatStore.methods.setUsername(props.username || 'user'); // Установка имени пользователя
  await chatStore.methods.connectWebSocket(); // Подключение к WebSocket
  chatStore.methods.setCurrentChat(props.chatID);
});
</script>

<style>
/* Стили остаются без изменений */
</style>
