<template>
	<div 
    class="gap-small pos-relative cols-1"
  >

    <div class="pos-relative  w-100 gap-thin flex-stretch flex-nowrap flex">
      <div class="bg-light h-100 o-hidden pos-relative radius-small aspect-1x1"
        style="flex: 1 0 auto"
      >
        <!-- Item Image -->
        <img loading="lazy" 
          class="w-100 object-fit-cover pos-absolute h-100" 
          v-if="product.images[0]"    
          :src="(FILE_SERVER_URL || '') + product.images[0]"
        >
        <PlaceholderImage
          v-if="!product.images[0]" 
          class="bg-light pos-absolute radius-small w-100 h-100"
        />
      </div>
      
     
      <!-- Item Description -->
      <div class="w-100 pos-relative">
        <!-- Name -->
        <p  class="t-truncate t-medium">{{ product.name }}
          <span v-if="product.size" class="t-transp">, {{ product.size}}</span>
          <span v-if="typeof product.color === 'string' && product.size" class="t-transp">, </span>
          <span v-if="typeof product.color === 'string' " class="t-transp">{{product.color}}</span>
        </p>
        <!-- Price -->
        <p 
          class="t-main t-demi"
        >
          <span v-if="product.quantity">{{ product.quantity }} x </span>{{product.price}} {{returnCurrency()}}
          <span v-if="product.sale"  class="pd-r-small t-transp t-transp p-medium line-through price">{{(product.price / 100) * (100 + product.sale) }} {{returnCurrency()}} </span>
        </p> 
        <!-- Quantity -->
        <div v-if="editable" class="mn-t-thin gap-thin flex-nojustify flex">
          <IconAdd
            @click="increase_(product)"
            class="hover-scale-1 cursor-pointer bg-light i-big pd-thin radius-small"
          />
          <IconMinus
            @click="decrease(product)"
            class="hover-scale-1 cursor-pointer bg-light i-big pd-thin radius-small"
          />
          <IconDelete
            @click="remove(product)"
            class="hover-scale-1 cursor-pointer bg-light i-big pd-thin radius-small"
          />
        </div>

      </div>

     <!--  <div class="flex-nowrap flex">
        <button @click="openStatusPopup" class="w-100 bg-black t-white button button-small">
          Confirm
        </button>

      </div> -->

    </div>
  </div>

</template>


<script setup="props">
	import { computed } from 'vue'
	import { useRouter } from 'vue-router'

  import IconAdd from '@pf/src/modules/icons/navigation/IconAdd.vue'
  import IconMinus from '@pf/src/modules/icons/navigation/IconMinus.vue'
  import IconDelete from '@pf/src/modules/icons/navigation/IconDelete.vue'


  import PlaceholderImage from '@pf/src/modules/icons/placeholders/PlaceholderImage.vue'

	const router = useRouter()

  const emits = defineEmits(['increase', 'decrease', 'remove'])

	const props = defineProps({
		product: Object,
		editable: Boolean,
		increase: Function,
		decrease: Function,
		remove: Function
	})

  function increase_ (product)  {
    if (props.increase) {
      props.increase(product)
    }
    emits('increase', product)
  }

  function decrease (product) {
    if (props.decrease) {
      props.decrease(product)
    }
    emits('decrease', product)
  }

  function remove (product) {
    if (props.remove) {
      props.remove(product)
    }
    emits('remove', product)
  }

</script>

<style lang="scss">
</style>


