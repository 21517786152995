<template>
	<div style="grid-template-columns: 2fr 4fr 2fr;" class="cols-3 gap-thin pd-t-zero pd-thin">

		<ul class="align-self-start pos-sticky pos-t-0 w-100 bg-light radius-big pd-medium p-medium gap-thin flex-column mobile:flex-row flex-nowrap flex z-index-2 o-scroll t-nowrap scroll-hide">
			<li  	
				:class="{'t-black bg-white': route.params.category === 'featured' }" 					
				@click="() => { blog.state.sort.param = 'createdAt'; router.push({name: 'Blog', params: { category: 'featured' } })}" 
				class="cursor-pointer flex-v-center flex t-medium  pd-thin radius-extra"
			>
				<IconFeatured class="i-medium mn-r-thin t-transp"/>
				Featured
			</li>
			<li  	
				:class="{'t-black bg-white': route.params.category === 'popular' }"					 				
				@click="() => { blog.state.sort.param = 'views'; router.push({name: 'Blog', params: { category: 'popular' } }) }" 
				class="cursor-pointer t-medium flex-v-center flex  pd-thin radius-extra"
			>	
				<IconPopular class="i-medium mn-r-thin t-transp"/>
				Popular
			</li>
			<li  	
				:class="{'t-black bg-white': route.params.category === 'new'}" 					
				@click="() => { blog.state.sort.param = 'createdAt'; router.push({name: 'Blog', params: { category: 'new' } })}" 
				class="cursor-pointer t-medium flex-v-center flex  pd-thin radius-extra"
			>
				<IconRecent class="i-medium mn-r-thin t-transp"/>
				Recent
			</li>
			<li 	
				v-if="auth.state.user._id"
				:class="{'t-black bg-white': route.params.category === 'following'}" 		
				@click="() => { blog.state.sort.param = 'createdAt'; router.push({name: 'Blog', params: { category: 'following' } })}" 
				class="cursor-pointer t-medium flex-v-center flex pd-thin radius-extra"
			>
				<IconFollowing class="i-medium mn-r-thin t-transp"/>
				Following
			</li>

			<router-link 
				v-if="auth.state.user._id"
				:to="{
					name: 'Create BlogPost'
				}" 
				class="button t-medium bg-main w-100"
			>
				Create Post
			</router-link>
		</ul>

		<section class="pos-relative">

			<header v-if="!route.params.url" class="bg-light t-black radius-big pd-medium pos-relative  mn-b-thin flex-v-center t-left flex-nowrap flex">
	    	<h3 class="pos-relative flex-v-center flex-nowrap flex">
	    		<span class="mn-r-thin">Topics for </span>

	    		<Select 
		        :options="['today','week','month','year','all time']"
		        v-model:select="blog.state.filter.period" 
		        placeholder="all time"
		        class="
		        	t-semi
		        	pos-relative 
		        	w-max
		        	bg-main t-black
		        	pd-thin
		        	radius-medium
		          h3
		         	t-center
		        "
		      />
	    	</h3>
			</header>

			<router-view v-slot="{ Component, route }">
				<transition name="scaleIn" mode="out-in">
					<component ref="page" :key="route.query" :is="Component" />
				</transition>
			</router-view>

		</section>

		<div class="align-self-start  bg-light radius-big pd-medium">
			<div class="gap-thin flex-nowrap flex">
				<p class="t-medium mn-b-small">Popular Communities</p>
				
				<router-link 
					v-if="auth.state.user._id"
					:to="{
						name: 'Create Organization'
					}"
					class="t-medium cursor-pointer hover-scale-1 radius-extra flex-center flex i-medium bg-main"
				>
					+
				</router-link>
			</div>


			<transition name="slide-fade">
				<Feed
		      :states="{
		        empty: {
		          title: 'No Organization Found',
		          description: 'Currently, there are no organization available.'
		        }
		      }"
		      :store="{
		        read: (options) => organizations.actions.read(options),
		        state: organizations.state
		      }"
		      :options="{
		        	user: auth.state.user._id,
							sort: 'numberOfMemberships',
							contain: ['blogposts'],
							limit: 10
		      }"
		      v-slot="{ 
		        items 
		      }"
		    >
		    	<CardOrganization 
	    			v-for="organization in items"
	        	:key="organization._id" 
		      	:organization="organization"
		      	:user="auth.state.user"
		      	:showProducts="false"
		      	:showRating="false"
		      	:showFeatured="false"
		      	:showFollowers="false"
						@updateMembership="handleMembershipUpdate"
						class="mn-b-thin w-100 pd-0 bg-white radius-big o-hidden"
			    />
		    </Feed>
			</transition>
		</div>
</div>
</template>

<script setup="props">
	import { computed,onMounted, toRefs,ref } from 'vue'
  import { useRoute,useRouter } from 'vue-router'

	import Select         from '@pf/src/components/Select/Select.vue'
	import Feed from '@pf/src/components/Feed/Feed.vue'
	import CardOrganization from '@pf/src/modules/organizations/components/blocks/CardOrganization.vue'

	import IconFeatured from '@pf/src/modules/icons/entities/IconFeatured.vue'
	import IconPopular from '@pf/src/modules/icons/entities/IconPopular.vue'
	import IconRecent from '@pf/src/modules/icons/entities/IconRecent.vue'
	import IconFollowing from '@pf/src/modules/icons/entities/IconFollowing.vue'

 	// Import state
	import * as blog from '@pf/src/modules/community/store/blogposts.js';
	import * as auth from '@pf/src/modules/auth/views/store/auth'
	import * as organizations from '@pf/src/modules/organizations/store/organizations'
	import * as memberships from '@pf/src/modules/organizations/store/memberships'

	// State
	const route = useRoute();
	const router = useRouter();

	if (route.params.category === 'popular') blog.state.sort.param = 'views'; 
	if (route.params.category === 'new') blog.state.sort.param = 'createdAt'; 


	const handleMembershipUpdate = ({ membership, status, target }, statusName, statusNumber) => {
	  memberships.mutations.handleMembershipUpdate(organizations.state.current, membership, status, target, statusName, statusNumber)
	};

</script>

<style lang="scss">

</style>
