// useGlobalMixins.js
export function useGlobalMixins() {
  const isAdmin = (roles) => {
    if (roles) {
      const isAdmin = roles.includes('ROLE_ADMIN') ? roles.includes('ROLE_ADMIN') : false;

      if (isAdmin !== true) {
        return false
      }

      return true
    } else {
      return false
    }
  }
  const hasAccess = (organizationId, rightCategory, rightType, accesses) => {
    const accessArray = accesses;
    
    const organizationAccess = accessArray.find(access => access.organization === organizationId);

    if (!organizationAccess) {
      return false;
    }

    const categoryAccess = organizationAccess.rights[rightCategory];

    if (!categoryAccess) {
      return false;
    }

    return categoryAccess[rightType] === true;
  }

  const returnCurrency = () => {
    const currency = '฿'
    return currency
  }

  const formatPrice = (number) => {
    const currency = returnCurrency();
    let formattedNumber = number.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return `${formattedNumber} ${currency}`;
  }

  const formatDate = (d, options = {}) => {
    var fixedDate = new Date(d);
    var yyyy = fixedDate.getFullYear().toString();
    var mm = (fixedDate.getMonth() + 1).toString();
    var dd = fixedDate.getDate().toString();
    var hh = fixedDate.getHours().toString();
    var minmin = fixedDate.getMinutes().toString();

    var format = {
      dateOnly: options.dateOnly || false,
      timeOnly: options.timeOnly || false,
      dayMonth: options.dayMonth || false,
      dayTime: options.dayTime || false,
      monthYear: options.monthYear || false,
      yearOnly: options.yearOnly || false,
      custom: options.custom || ""
    };

    if (format.dateOnly) {
      return yyyy + '.' + (mm[1] ? mm : "0" + mm[0]) + '.' + (dd[1] ? dd : "0" + dd[0]);
    } else if (format.timeOnly) {
      return (hh[1] ? hh : "0" + hh[0]) + ':' + (minmin[1] ? minmin : "0" + minmin[0]);
    } else if (format.dayMonth) {
      return (dd[1] ? dd : "0" + dd[0]) + '.' + (mm[1] ? mm : "0" + mm[0]);
    } else if (format.dayTime) {
      return (dd[1] ? dd : "0" + dd[0]) + " в " + (hh[1] ? hh : "0" + hh[0]) + ':' + (minmin[1] ? minmin : "0" + minmin[0]);
    } else if (format.monthYear) {
      return (mm[1] ? mm : "0" + mm[0]) + '.' + yyyy;
    } else if (format.yearOnly) {
      return yyyy;
    } else if (format.custom) {
      return format.custom.replace("yyyy", yyyy).replace("mm", (mm[1] ? mm : "0" + mm[0])).replace("dd", (dd[1] ? dd : "0" + dd[0])).replace("hh", (hh[1] ? hh : "0" + hh[0])).replace("min", (minmin[1] ? minmin : "0" + minmin[0]));
    }

    return (hh[1] ? hh : "0" + hh[0]) + ':' + (minmin[1] ? minmin : "0" + minmin[0]) + ", " + yyyy + '.' + (mm[1] ? mm : "0" + mm[0]) + '.' + (dd[1] ? dd : "0" + dd[0]);
  }

  const normalizeUrlParam = (param) => {
    if (!param) param = ''

    return param
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^a-z0-9-]/g, '');
  }

  const joinArrayToUrl = (arr) => {
    return arr.join('/')
  }

  const getMarketplaceLink = (categories, country, state, city) => {
    let country_normalized = country ? normalizeUrlParam(country) : '';
    let state_normalized = state ? normalizeUrlParam(state) : '';
    let city_normalized = city ? normalizeUrlParam(city) : '';

    let base = '/marketplace';
    base += country_normalized ? `/${country_normalized}` : '';
    base += country_normalized && state_normalized ? `/${state_normalized}` : '';
    base += country_normalized && state_normalized && city_normalized ? `/${city_normalized}` : '';

    if (categories && categories.length) {
      base += `?categories=${categories.join(",")}`;
    }

    return base;
  }

  const getSpotsLink = (country, state, city) => {
    let country_normalized = country ? normalizeUrlParam(country) : '';
    let state_normalized = state ? normalizeUrlParam(state) : '';
    let city_normalized = city ? normalizeUrlParam(city) : '';

    let base = '/spots';
    base += country_normalized ? `/${country_normalized}` : '';
    base += country_normalized && state_normalized ? `/${state_normalized}` : '';
    base += country_normalized && state_normalized && city_normalized ? `/${city_normalized}` : '';

    return base;
  }

  return {
    isAdmin,
    hasAccess,
    returnCurrency,
    formatPrice,
    formatDate,
    normalizeUrlParam,
    joinArrayToUrl,
    getMarketplaceLink,
    getSpotsLink
  }
}

// Экспорт для глобальной регистрации
export const globalMixins = {
  methods: useGlobalMixins()
}