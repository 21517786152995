/////////////////////////////
// DEPENDENCIES
/////////////////////////////
import { reactive, watch, computed  } from "vue";
// Globals 
import { setError }   from '@pf/src/modules/globals/views/store/globals'
// Setup Axios
import $axios from '@pf/src/modules/globals/views/utils/axios-instance.js';
/////////////////////////////
// STATE
/////////////////////////////
const state = reactive({
  all: [],
  current: {
    recommendation: false,
    category: [],
    status: 'unpublished',
    information: [],
    images: [],
    files: [],
    image3d: false,
    name: '',
    sale: 0,
    price: 0,
    localization: [],
    modifications: [],
    quantityDiscount: [],
    ingredients: [],
    description: ''
  }
});

/////////////////////////////
// ACTIONS
/////////////////////////////
const actions = {

  async create(organization, product) {
    const newProduct = {
      ...product,
      organization: organization
    }
    return await $axios.post("/api/products/create", newProduct).then(
      (response) => {
        set(response.data, 'current')
        return Promise.resolve(response.data);
      },
      (error) => {
        setError(error);
        return Promise.reject(error);
      }
    );
  },

  async read(options = {}) {
    // query.params = options.params || {};
    
    let query = {}

    // Handle fetching a specific post by _id
    if (options._id) {
      query._id = options._id ;
    }

    // Handle fetching posts by category
    if (options.category) {
      query.category = options.category;
    }

    if (options.organization) {
      query.organization = options.organization;
    }

    if (options.count) {
      query.count = options.count
    }

    if (options.categories) {
      query.categories = options.categories;
    }

    if (options.prices) {
      query.prices = options.prices;
    }

    if (options.delivery) {
      query.delivery = options.delivery;
    }

    if (options.status) {
      query.status = options.status;
    }

    if (options.search) {
      query.search = options.search;
    }

    query.sort = 'createdAt';
    
    query.skip = options.skip;
    query.limit = options.limit;

    try {
      const response = await $axios.get('/api/products/read', { params: query });
      
      if (options._id) {
        state.current = {...response.data[0]}; 
        return Promise.resolve(response.data[0]);
      } else {
        return Promise.resolve(response.data);
      }
      return Promise.resolve(response.data);
    } catch (error) {
      setError(error)
      return Promise.reject(error);
    }
  },

  async update(_id, product) {
    return await $axios.post("/api/products/" + _id, product).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        setError(error);
        return Promise.reject(error);
      }
    );
  },

  async deleteProduct(_id) {
    return await $axios.delete("/api/products/" + _id).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        setError(error);
        return Promise.reject(error);
      }
    );
  },

  async submitMood(presetMood = null) {
    return $axios.post('/api/product/recommended', {mood: presetMood}).then(
      response => {
        state.current = response.data.product
        state.current.recommendation = response.data.recommendationText
        return Promise.resolve(response.data);
      },
      error => {
        setError(error);
        return Promise.reject(error);
      });
  },
  
  async fetchProducts() {
    return await $axios.get(`/api/products/read`).then(
      products => {
        state.all = products.data;
        return Promise.resolve(products.data);
      },
      error => {
        setError(error);
        return Promise.reject(error);
      }
    );
  },

  async fetchProductsPopular() {
    return await $axios.get(`/api/products/popular`).then(
      (products) => {
        state.popular = products.data;
        return Promise.resolve(products.data);
      },
      (error) => {
        setError(error);
        return Promise.reject(error);
      }
    );
  },

  async fetchProductsPublished() {
    return await $axios.get(`/api/products`).then(
      products => {
        let publishedProduct = products.data.filter(function (product, index) {
          return product.status !== "unpublished";
        });

        state.all = publishedProduct;
        state.filtered = publishedProduct.slice();
        state.filteredPrice = state.filtered;

        state.filteredPrice.sort(function (l, r) {
          return l[state.sort.current] > r[state.sort.current] ? (state.sort.ascending ? 1 : -1) : l[state.sort.current] < r[state.sort.current] ? (state.sort.ascending ? -1 : 1) : 0;
        });

        return Promise.resolve(products.data);
      },
      error => {
        setError(error);
        return Promise.reject(error);
      }
    );
  },


  async fetchProductPopular() {
    return await $axios.get(`/api/products`).then(
      (products) => {
        state.all = products.data.slice(1, 5);
        return Promise.resolve(products.data.slice(1, 5));
      },
      (error) => {
        setError(error);
        return Promise.reject(error);
      }
    );
  },
};

const mutations = {
  resetProduct(product) {
    state.current = {
      recommendation: false,
      category: [],
      status: 'unpublished',
      information: [],
      images: [],
      files: [],
      image3d: false,
      name: '',
      sale: 0,
      price: 0,
      localization: [],
      modifications: [],
      quantityDiscount: [],
      ingredients: [],
      description: ''
    }
  }
}

function set(product,property) { 
  state[property] = product
}

/////////////////////////////
// GETTERS
/////////////////////////////
const getters = {
  
};

/////////////////////////////
// UTILS
/////////////////////////////

/////////////////////////////
// HISTORY
/////////////////////////////
const history = []
history.push(state) 

/////////////////////////////
// WATCH
/////////////////////////////
watch(state, (newState, oldState) => {
  history.push(newState)
})

/////////////////////////////
// Module Export
/////////////////////////////
export {
  state,
  actions,
  mutations,
  getters
}