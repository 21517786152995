import layoutEmpty from '@pf/src/modules/globals/views/components/layouts/Empty.vue';

import * as validationAuth from '@pf/src/modules/auth/views/middlewares/auth.validation';

const backofficeRoutes = [{
  path: 'backoffice',
  component: layoutEmpty,
  // beforeEnter: [
  //   validationAuth.requiresAdmin,
  // ],
  children: [{
    path: '',
    name: 'Backoffice',
    meta: {
      title: {
        en: 'Backoffice',
        ru: 'Управление'
      },
      authorize: []
    },
    component: () => import(/* webpackChunkName: 'Organizations' */ '@pf/src/modules/organizations/components/pages/OrganizationBackoffice.vue'),
  }]
}];

export default backofficeRoutes;
