<template>
<svg width="205" height="59" viewBox="0 0 205 59" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M203.096 52.1958C203.096 55.34 200.496 57.8869 197.281 57.8869H6.9581C3.74381 57.8869 1.13477 55.34 1.13477 52.1958V6.81174C1.13477 3.66904 3.74381 1.11328 6.9581 1.11328H197.279C200.496 1.11328 203.095 3.66904 203.095 6.81174L203.096 52.1958Z" fill="rgb(var(--black))"/>
	<path d="M196.667 1.18185C200.169 1.18185 203.019 3.96038 203.019 7.375V51.625C203.019 55.0396 200.169 57.8182 196.667 57.8182H7.5641C4.06192 57.8182 1.21215 55.0396 1.21215 51.625V7.375C1.21215 3.96038 4.06192 1.18185 7.5641 1.18185H196.667ZM196.667 5.4802e-06H7.5641C3.40574 5.4802e-06 0 3.3206 0 7.375V51.625C0 55.6794 3.40574 59 7.5641 59H196.667C200.825 59 204.231 55.6794 204.231 51.625V7.375C204.231 3.3206 200.825 5.4802e-06 196.667 5.4802e-06Z" fill="#A6A6A6"/>
	<path d="M45.578 29.1812C45.5341 24.4273 49.5703 22.1145 49.7549 22.0068C47.469 18.7574 43.926 18.3134 42.6809 18.278C39.7052 17.9727 36.8187 20.0141 35.3029 20.0141C33.7568 20.0141 31.4225 18.3075 28.9067 18.3577C25.6692 18.4064 22.6406 20.2339 20.9795 23.0718C17.5515 28.8582 20.1081 37.3616 23.3925 42.0388C25.0354 44.3295 26.9551 46.8871 29.4679 46.7972C31.9263 46.6983 32.8446 45.2691 35.8112 45.2691C38.7506 45.2691 39.6129 46.7972 42.1756 46.7396C44.814 46.6983 46.4751 44.4386 48.0605 42.1273C49.9591 39.5018 50.7216 36.9161 50.7518 36.7834C50.6898 36.7627 45.6279 34.8792 45.578 29.1812Z" fill="rgb(var(--white))"/>
	<path d="M40.7369 15.2012C42.0591 13.589 42.9638 11.3957 42.7127 9.16992C40.799 9.25252 38.4057 10.4605 37.0275 12.0373C35.8082 13.4268 34.7189 15.7042 35.0003 17.8459C37.15 18.0022 39.3572 16.7883 40.7369 15.2012Z" fill="rgb(var(--white))"/>
	<path d="M81.1547 46.4691H77.7191L75.8372 40.7034H69.2957L67.503 46.4691H64.1582L70.6391 26.8398H74.642L81.1547 46.4691ZM75.2699 38.2844L73.5679 33.1587C73.3879 32.6351 73.0506 31.402 72.5528 29.4609H72.4923C72.2941 30.2958 71.9749 31.5289 71.5362 33.1587L69.8646 38.2844H75.2699V38.2844Z" fill="rgb(var(--white))"/>
	<path d="M97.8218 39.218C97.8218 41.6252 97.1546 43.5279 95.8203 44.9248C94.6252 46.1682 93.1411 46.7892 91.3696 46.7892C89.4574 46.7892 88.0837 46.1195 87.2472 44.7802H87.1866V52.2363H83.9613V36.9745C83.9613 35.4612 83.9205 33.908 83.8418 32.315H86.6783L86.8584 34.5585H86.9189C87.9945 32.8681 89.6268 32.0244 91.8174 32.0244C93.5299 32.0244 94.9595 32.6837 96.1032 34.0039C97.2499 35.3255 97.8218 37.063 97.8218 39.218ZM94.5359 39.333C94.5359 37.9554 94.2182 36.8196 93.5798 35.9258C92.8824 34.9936 91.946 34.5275 90.772 34.5275C89.9763 34.5275 89.2532 34.7871 88.6072 35.2989C87.9597 35.8152 87.5361 36.4892 87.3379 37.3241C87.2381 37.7135 87.1882 38.0321 87.1882 38.2828V40.6428C87.1882 41.6724 87.5119 42.5412 88.1594 43.2506C88.8069 43.9601 89.648 44.3141 90.6828 44.3141C91.8976 44.3141 92.8431 43.8569 93.5193 42.9453C94.1971 42.0323 94.5359 40.8287 94.5359 39.333Z" fill="rgb(var(--white))"/>
	<path d="M114.518 39.218C114.518 41.6252 113.85 43.5279 112.515 44.9248C111.321 46.1682 109.837 46.7892 108.065 46.7892C106.153 46.7892 104.779 46.1195 103.944 44.7802H103.884V52.2363H100.659V36.9745C100.659 35.4612 100.618 33.908 100.539 32.315H103.376L103.556 34.5585H103.616C104.69 32.8681 106.323 32.0244 108.515 32.0244C110.226 32.0244 111.655 32.6837 112.802 34.0039C113.944 35.3255 114.518 37.063 114.518 39.218ZM111.232 39.333C111.232 37.9554 110.912 36.8196 110.274 35.9258C109.577 34.9936 108.643 34.5275 107.468 34.5275C106.671 34.5275 105.949 34.7871 105.301 35.2989C104.654 35.8152 104.232 36.4892 104.034 37.3241C103.935 37.7135 103.884 38.0321 103.884 38.2828V40.6428C103.884 41.6724 104.208 42.5412 104.852 43.2506C105.5 43.9586 106.341 44.3141 107.379 44.3141C108.593 44.3141 109.539 43.8569 110.215 42.9453C110.893 42.0323 111.232 40.8287 111.232 39.333Z" fill="rgb(var(--white))"/>
	<path d="M133.187 40.964C133.187 42.6337 132.593 43.9922 131.399 45.0409C130.087 46.187 128.261 46.7593 125.915 46.7593C123.749 46.7593 122.012 46.3522 120.697 45.5365L121.445 42.9154C122.861 43.7503 124.414 44.1692 126.107 44.1692C127.322 44.1692 128.267 43.9007 128.947 43.3668C129.623 42.8328 129.96 42.116 129.96 41.2221C129.96 40.4256 129.682 39.7545 129.124 39.2102C128.568 38.6659 127.641 38.16 126.346 37.6924C122.821 36.4107 121.06 34.533 121.06 32.0638C121.06 30.4502 121.678 29.1271 122.914 28.0976C124.145 27.0665 125.788 26.5518 127.842 26.5518C129.674 26.5518 131.196 26.863 132.411 27.484L131.605 30.0475C130.47 29.4457 129.187 29.1448 127.752 29.1448C126.617 29.1448 125.73 29.4177 125.095 29.9605C124.558 30.4458 124.289 31.0372 124.289 31.7379C124.289 32.5137 124.596 33.1553 125.213 33.6598C125.75 34.1259 126.726 34.6303 128.142 35.1746C129.874 35.8546 131.146 36.6496 131.965 37.5612C132.78 38.4698 133.187 39.607 133.187 40.964Z" fill="rgb(var(--white))"/>
	<path d="M143.851 34.6748H140.296V41.5468C140.296 43.2947 140.922 44.1679 142.178 44.1679C142.754 44.1679 143.232 44.1192 143.61 44.0219L143.699 46.4099C143.064 46.6415 142.227 46.758 141.191 46.758C139.917 46.758 138.922 46.3789 138.203 45.6223C137.488 44.8641 137.128 43.5927 137.128 41.8064V34.6719H135.01V32.3119H137.128V29.7203L140.296 28.7881V32.3119H143.851V34.6748Z" fill="rgb(var(--white))"/>
	<path d="M159.891 39.275C159.891 41.4506 159.252 43.2369 157.979 44.6337C156.643 46.0718 154.87 46.7887 152.66 46.7887C150.53 46.7887 148.834 46.0998 147.569 44.7222C146.304 43.3445 145.672 41.6055 145.672 39.5095C145.672 37.3162 146.322 35.5197 147.628 34.1228C148.93 32.7245 150.688 32.0254 152.899 32.0254C155.029 32.0254 156.743 32.7142 158.036 34.0933C159.274 35.4312 159.891 37.1584 159.891 39.275ZM156.545 39.3768C156.545 38.0714 156.259 36.9519 155.679 36.0182C155.003 34.8884 154.036 34.3249 152.784 34.3249C151.487 34.3249 150.502 34.8898 149.826 36.0182C149.247 36.9534 148.961 38.0906 148.961 39.4358C148.961 40.7412 149.247 41.8607 149.826 42.7929C150.523 43.9227 151.498 44.4862 152.755 44.4862C153.986 44.4862 154.953 43.9109 155.65 42.7634C156.245 41.812 156.545 40.6807 156.545 39.3768Z" fill="rgb(var(--white))"/>
	<path d="M170.374 35.0806C170.055 35.0231 169.715 34.9936 169.358 34.9936C168.223 34.9936 167.346 35.411 166.728 36.2473C166.191 36.9848 165.922 37.917 165.922 39.0425V46.4691H162.698L162.728 36.7724C162.728 35.1411 162.688 33.6558 162.607 32.3165H165.417L165.535 35.0246H165.624C165.964 34.0938 166.501 33.3445 167.237 32.7826C167.955 32.2766 168.731 32.0244 169.568 32.0244C169.866 32.0244 170.135 32.0451 170.374 32.0819V35.0806Z" fill="rgb(var(--white))"/>
	<path d="M184.8 38.7216C184.8 39.285 184.762 39.76 184.682 40.1479H175.006C175.044 41.5462 175.511 42.6156 176.41 43.3531C177.225 44.0124 178.28 44.3428 179.574 44.3428C181.007 44.3428 182.314 44.1201 183.49 43.6732L183.995 45.8562C182.621 46.4403 181 46.7308 179.128 46.7308C176.877 46.7308 175.11 46.0848 173.824 44.7942C172.541 43.5035 171.898 41.7704 171.898 39.5963C171.898 37.4619 172.496 35.6846 173.693 34.2671C174.945 32.7537 176.638 31.9971 178.768 31.9971C180.86 31.9971 182.444 32.7537 183.52 34.2671C184.372 35.4692 184.8 36.956 184.8 38.7216ZM181.724 37.9059C181.745 36.9737 181.535 36.1684 181.098 35.4884C180.54 34.6137 179.682 34.1771 178.528 34.1771C177.473 34.1771 176.615 34.6034 175.96 35.4589C175.423 36.1389 175.104 36.9545 175.006 37.9044H181.724V37.9059Z" fill="rgb(var(--white))"/>
	<path d="M74.2041 14.7631C74.2041 16.4992 73.6701 17.806 72.6036 18.6836C71.6157 19.4934 70.2118 19.899 68.3934 19.899C67.4917 19.899 66.7202 19.8607 66.0742 19.784V10.2983C66.9169 10.1655 67.8246 10.0977 68.8049 10.0977C70.537 10.0977 71.8426 10.4649 72.7231 11.1995C73.7094 12.0299 74.2041 13.2173 74.2041 14.7631ZM72.5325 14.8059C72.5325 13.6804 72.2269 12.8176 71.6157 12.2158C71.0045 11.6154 70.1119 11.3145 68.9365 11.3145C68.4372 11.3145 68.0121 11.347 67.6597 11.4148V18.6261C67.8548 18.6556 68.2118 18.6689 68.7307 18.6689C69.944 18.6689 70.8805 18.34 71.54 17.6821C72.1996 17.0243 72.5325 16.0655 72.5325 14.8059Z" fill="rgb(var(--white))"/>
	<path d="M83.0687 16.2794C83.0687 17.3488 82.7555 18.2249 82.1292 18.9123C81.4727 19.6188 80.6028 19.9713 79.5166 19.9713C78.4697 19.9713 77.6361 19.6335 77.0144 18.955C76.3941 18.278 76.084 17.424 76.084 16.3944C76.084 15.3177 76.4032 14.4342 77.0446 13.7483C77.6861 13.0624 78.5484 12.7188 79.6346 12.7188C80.6814 12.7188 81.5226 13.0565 82.1595 13.7335C82.7646 14.3914 83.0687 15.241 83.0687 16.2794ZM81.4242 16.3295C81.4242 15.6879 81.282 15.1377 80.9991 14.679C80.6663 14.1244 80.1928 13.8471 79.5771 13.8471C78.9402 13.8471 78.4561 14.1244 78.1233 14.679C77.8389 15.1377 77.6982 15.6968 77.6982 16.3576C77.6982 16.9992 77.8404 17.5494 78.1233 18.0081C78.4667 18.5627 78.9447 18.84 79.562 18.84C80.1671 18.84 80.6421 18.5583 80.984 17.9933C81.2775 17.5258 81.4242 16.9712 81.4242 16.3295Z" fill="rgb(var(--white))"/>
	<path d="M94.9517 12.8604L92.7203 19.8135H91.268L90.3437 16.7942C90.1092 16.0405 89.9185 15.2912 89.7703 14.5478H89.7415C89.6039 15.3118 89.4133 16.0596 89.1682 16.7942L88.1864 19.8135H86.7174L84.6191 12.8604H86.2484L87.0548 16.1658C87.2499 16.9476 87.4103 17.6925 87.5389 18.3975H87.5676C87.6856 17.8164 87.8808 17.0759 88.1561 16.1806L89.1682 12.8618H90.4601L91.4299 16.1098C91.6643 16.9019 91.855 17.6644 92.0017 18.399H92.0456C92.153 17.6836 92.3149 16.921 92.5297 16.1098L93.395 12.8618H94.9517V12.8604Z" fill="rgb(var(--white))"/>
	<path d="M103.171 19.813H101.585V15.8305C101.585 14.6033 101.107 13.9897 100.148 13.9897C99.6778 13.9897 99.2981 14.1579 99.0031 14.4957C98.7111 14.8334 98.5628 15.2317 98.5628 15.6875V19.8116H96.9774V14.8467C96.9774 14.2361 96.9577 13.5738 96.9199 12.8569H98.3132L98.3874 13.944H98.4312C98.6158 13.6062 98.8911 13.3275 99.2527 13.1047C99.6823 12.8451 100.163 12.7139 100.69 12.7139C101.355 12.7139 101.909 12.9233 102.349 13.3437C102.897 13.8585 103.171 14.6269 103.171 15.6476V19.813V19.813Z" fill="rgb(var(--white))"/>
	<path d="M107.543 19.8135H105.959V9.66992H107.543V19.8135Z" fill="rgb(var(--white))"/>
	<path d="M116.877 16.2794C116.877 17.3488 116.564 18.2249 115.938 18.9123C115.281 19.6188 114.41 19.9713 113.325 19.9713C112.277 19.9713 111.443 19.6335 110.823 18.955C110.203 18.278 109.893 17.424 109.893 16.3944C109.893 15.3177 110.212 14.4342 110.853 13.7483C111.495 13.0624 112.357 12.7188 113.442 12.7188C114.49 12.7188 115.33 13.0565 115.968 13.7335C116.573 14.3914 116.877 15.241 116.877 16.2794ZM115.231 16.3295C115.231 15.6879 115.089 15.1377 114.806 14.679C114.475 14.1244 114 13.8471 113.386 13.8471C112.747 13.8471 112.263 14.1244 111.932 14.679C111.647 15.1377 111.507 15.6968 111.507 16.3576C111.507 16.9992 111.649 17.5494 111.932 18.0081C112.275 18.5627 112.753 18.84 113.371 18.84C113.976 18.84 114.449 18.5583 114.791 17.9933C115.086 17.5258 115.231 16.9712 115.231 16.3295Z" fill="rgb(var(--white))"/>
	<path d="M124.55 19.8135H123.126L123.008 19.0126H122.964C122.477 19.6512 121.783 19.9713 120.881 19.9713C120.208 19.9713 119.664 19.7604 119.254 19.3415C118.881 18.9609 118.695 18.4875 118.695 17.9255C118.695 17.0759 119.058 16.4284 119.789 15.98C120.518 15.5316 121.544 15.3118 122.865 15.3221V15.1923C122.865 14.2763 122.371 13.8191 121.384 13.8191C120.68 13.8191 120.06 13.9917 119.524 14.3339L119.202 13.3191C119.865 12.9193 120.683 12.7188 121.648 12.7188C123.512 12.7188 124.447 13.6775 124.447 15.595V18.1556C124.447 18.8503 124.482 19.4034 124.55 19.8135ZM122.904 17.424V16.3517C121.155 16.3222 120.281 16.7897 120.281 17.7529C120.281 18.1158 120.381 18.3872 120.585 18.5686C120.789 18.75 121.049 18.84 121.359 18.84C121.707 18.84 122.033 18.7323 122.329 18.5184C122.627 18.3031 122.81 18.0302 122.878 17.6954C122.895 17.6202 122.904 17.5287 122.904 17.424Z" fill="rgb(var(--white))"/>
	<path d="M133.56 19.8135H132.153L132.079 18.6969H132.035C131.585 19.5465 130.82 19.9713 129.744 19.9713C128.885 19.9713 128.169 19.6424 127.602 18.9845C127.035 18.3267 126.752 17.4727 126.752 16.4239C126.752 15.2985 127.059 14.387 127.676 13.6908C128.274 13.0418 129.006 12.7173 129.877 12.7173C130.835 12.7173 131.505 13.0314 131.886 13.6613H131.917V9.66992H133.504V17.9402C133.504 18.6173 133.522 19.2412 133.56 19.8135ZM131.917 16.8812V15.7218C131.917 15.5212 131.902 15.359 131.873 15.2351C131.784 14.8634 131.591 14.5507 131.299 14.2985C131.004 14.0462 130.649 13.9194 130.239 13.9194C129.647 13.9194 129.185 14.148 128.844 14.6067C128.507 15.0655 128.336 15.651 128.336 16.3664C128.336 17.0538 128.498 17.6113 128.823 18.0405C129.166 18.4978 129.629 18.7264 130.209 18.7264C130.729 18.7264 131.145 18.5361 131.461 18.1541C131.767 17.8016 131.917 17.3768 131.917 16.8812Z" fill="rgb(var(--white))"/>
	<path d="M147.119 16.2794C147.119 17.3488 146.806 18.2249 146.18 18.9123C145.523 19.6188 144.655 19.9713 143.567 19.9713C142.522 19.9713 141.688 19.6335 141.065 18.955C140.445 18.278 140.135 17.424 140.135 16.3944C140.135 15.3177 140.454 14.4342 141.095 13.7483C141.737 13.0624 142.599 12.7188 143.687 12.7188C144.732 12.7188 145.575 13.0565 146.21 13.7335C146.815 14.3914 147.119 15.241 147.119 16.2794ZM145.477 16.3295C145.477 15.6879 145.334 15.1377 145.051 14.679C144.717 14.1244 144.245 13.8471 143.628 13.8471C142.992 13.8471 142.508 14.1244 142.174 14.679C141.89 15.1377 141.749 15.6968 141.749 16.3576C141.749 16.9992 141.891 17.5494 142.174 18.0081C142.517 18.5627 142.996 18.84 143.613 18.84C144.218 18.84 144.694 18.5583 145.036 17.9933C145.328 17.5258 145.477 16.9712 145.477 16.3295Z" fill="rgb(var(--white))"/>
	<path d="M155.644 19.813H154.06V15.8305C154.06 14.6033 153.582 13.9897 152.621 13.9897C152.151 13.9897 151.771 14.1579 151.478 14.4957C151.184 14.8334 151.037 15.2317 151.037 15.6875V19.8116H149.451V14.8467C149.451 14.2361 149.432 13.5738 149.395 12.8569H150.786L150.86 13.944H150.904C151.09 13.6062 151.366 13.3275 151.726 13.1047C152.157 12.8451 152.637 12.7139 153.164 12.7139C153.829 12.7139 154.382 12.9233 154.823 13.3437C155.372 13.8585 155.644 14.6269 155.644 15.6476V19.813V19.813Z" fill="rgb(var(--white))"/>
	<path d="M166.314 14.0184H164.568V17.3962C164.568 18.2546 164.878 18.6839 165.493 18.6839C165.777 18.6839 166.013 18.6603 166.199 18.6116L166.24 19.7842C165.927 19.8993 165.515 19.9568 165.008 19.9568C164.382 19.9568 163.895 19.7709 163.543 19.3992C163.189 19.0275 163.013 18.4021 163.013 17.5245V14.0184H161.971V12.8606H163.013V11.5862L164.567 11.1289V12.8591H166.312V14.0184H166.314Z" fill="rgb(var(--white))"/>
	<path d="M174.707 19.8135H173.12V15.8605C173.12 14.6141 172.642 13.9902 171.685 13.9902C170.949 13.9902 170.447 14.3516 170.172 15.0743C170.125 15.2262 170.098 15.4121 170.098 15.6304V19.812H168.514V9.66992H170.098V13.8604H170.128C170.627 13.0978 171.343 12.7173 172.27 12.7173C172.927 12.7173 173.47 12.9267 173.901 13.3471C174.438 13.8707 174.707 14.6495 174.707 15.6791V19.8135V19.8135Z" fill="rgb(var(--white))"/>
	<path d="M183.366 16.0076C183.366 16.2849 183.344 16.518 183.307 16.7082H178.552C178.573 17.3956 178.8 17.9192 179.24 18.2821C179.643 18.6066 180.161 18.7688 180.797 18.7688C181.5 18.7688 182.142 18.6597 182.72 18.4399L182.968 19.5137C182.292 19.7998 181.496 19.9429 180.574 19.9429C179.47 19.9429 178.6 19.6258 177.971 18.9915C177.339 18.3573 177.025 17.5062 177.025 16.4383C177.025 15.3896 177.317 14.5164 177.906 13.8202C178.52 13.0768 179.351 12.7051 180.399 12.7051C181.425 12.7051 182.204 13.0768 182.73 13.8202C183.155 14.4102 183.366 15.1403 183.366 16.0076ZM181.853 15.6079C181.865 15.1492 181.761 14.7539 181.546 14.4205C181.27 13.9913 180.851 13.7759 180.284 13.7759C179.767 13.7759 179.345 13.9854 179.022 14.4058C178.759 14.7406 178.603 15.1403 178.552 15.6079H181.853Z" fill="rgb(var(--white))"/>
</svg>
</template>