<template>
  <div class="">
    <Menu 
      class="bg-light mn-b-semi"
    >
      <MenuItem 
        @click="router.push({
          name: 'Organization Members'
        })" 
        class="cursor-pointer"
      >
        <IconGroups class="i-semi" :icon="true" :fill="'rgb(var(--white))'"/> 
        <span>
          Members
        </span>
      </MenuItem>

      <MenuItem 
        @click="router.push({
          name: 'Backoffice Community'
        })" 
        class="cursor-pointer">
        <span>
          Community
        </span>
      </MenuItem>

      <MenuItem 
        @click="router.push({
          name: 'Products'
        })" 
        class="cursor-pointer">
        <IconProducts class="i-semi" :icon="true" :fill="'rgb(var(--white))'"/> 
        <span>
          Products
        </span>
      </MenuItem>

      <MenuItem 
        @click="router.push({
          name: 'Leftovers'
        })" 
        class="cursor-pointer">
        <IconLeftovers class="i-semi" :icon="true" :fill="'rgb(var(--white))'"/> 
        <span>
          Leftovers
        </span>
      </MenuItem>

      <MenuItem 
        @click="router.push({
          name: 'Backoffice Orders'
        })" 
        class="cursor-pointer">
        <IconOrders class="i-semi" :icon="true" :fill="'rgb(var(--white))'"/> 
        <span>
          Orders
        </span>
      </MenuItem>

      <MenuItem 
        @click="router.push({
          name: 'Backoffice Gallery'
        })" 
        class="cursor-pointer">
        <IconGallery class="i-semi" :icon="true" :fill="'rgb(var(--white))'"/> 
        <span>
          Gallery
        </span>
      </MenuItem>
      
      <MenuItem 
        @click="router.push({
          name: 'Backoffice Events'
        })" 
        class="cursor-pointer">
        <IconEvents class="i-semi" :icon="true" :fill="'rgb(var(--white))'"/> 
        <span>
          Events
        </span>
      </MenuItem>

      <MenuItem 
        @click="router.push({
          name: 'Payments'
        })" 
        class="cursor-pointer">
        <span>
          Payments
        </span>
      </MenuItem>
    </Menu>

    <Menu 
      class="bg-light"
    >
      <MenuItem 
        @click="router.push({
          name: 'Backoffice Organizations'
        })" 
        class="cursor-pointer">
        <span>
          Organizations
        </span>
      </MenuItem>

      <MenuItem 
        @click="router.push({
          name: 'Backoffice Pages'
        })" 
        class="cursor-pointer">
        <span>
          Pages
        </span>
      </MenuItem>

      <MenuItem 
        @click="router.push({
          name: 'Backoffice Reports'
        })" 
        class="cursor-pointer">
        <span>
          Reports
        </span>
      </MenuItem>
    </Menu>
  </div>
</template>


<script setup="props">
/////////////////////////////
// COMPONENT DEPENDENCIES
/////////////////////////////
import { ref, onMounted } from 'vue';

// Mobile Module
import Menu from '@pf/src/components/Menu/Menu.vue'
import MenuItem from '@pf/src/components/Menu/MenuItem.vue'
import SelectMulti    from '@pf/src/components/SelectMulti/SelectMulti.vue'

import IconProducts from '@pf/src/modules/icons/entities/IconProducts.vue'
import IconEvents from '@pf/src/modules/icons/entities/IconEvents.vue'
import IconGroups from '@pf/src/modules/icons/entities/IconGroups.vue'
import IconCommunity from '@pf/src/modules/icons/entities/IconCommunity.vue'
import IconGallery from '@pf/src/modules/icons/entities/IconGallery.vue'
import IconOrders from '@pf/src/modules/icons/entities/IconOrders.vue'
import IconLeftovers from '@pf/src/modules/icons/entities/IconLeftovers.vue'
/////////////////////////////
// HELPERS
/////////////////////////////
import { useRoute, useRouter } from 'vue-router'

import * as auth  from '@pf/src/modules/auth/views/store/auth'
import * as organizations  from '@pf/src/modules/organizations/store/organizations'

const route = useRoute()
const router = useRouter()
/////////////////////////////
// CREATED
/////////////////////////////
const props = defineProps({
  favorites: Array,
});

const selectedOrganization = ref(null);
const publics = ref([]);
/////////////////////////////
// MOUNTED
/////////////////////////////
onMounted(async () =>{
})
</script>

<style lang="scss">
</style>
