<template>
	<section class="">
		<!-- Header -->
		<img loading="lazy" :src="'/logo/logo.svg'" class="i-extra radius-small mn-b-small">

		<h3 class="mn-b-medium">
			{{ t('title') }}
			<br>
			<span class="t-semi">{{ t('subtitle') }}</span>
		</h3>

		<p class="mn-b-big">
			<router-link :to="{name: 'Sign Up'}" class="underline t-second">{{ t('signup') }}</router-link>
		</p>
		
			<!-- Select -->
		<div class="mn-b-small p-small uppercase t-semi bg-light radius-small o-hidden">
			<Tab 
				v-model:selected="tabAuth"
				:tabs="[
					{name: t('phone'), value: 'phone'},
					{name: t('email'), value: 'email'}
				]"
				tabClass="pd-small pd-r-medium pd-l-medium w-100 pd-small radius-small"
			/>
		</div>

		<!-- Form -->
		<div class="pos-relative">
		<!-- Phone -->
			<transition name="slide-fade">
				<div v-show="tabAuth === 'phone'" class="mn-b-thin radius-small">
					<FieldPhone
						@change="(event) => auth.state.user.phone = event" 	
						:dropdownOptions="{
							showDialCodeInSelection: true,
							showFlags: true,
							showDialCodeInList: true
						}"
						:validCharactersOnly="true"
						:validation="phoneValidation" 
						mode="national"
						:inputOptions="{placeholder: t('phonePlaceholder'), type: 'tel'}"
						class="bg-light h-4r pd-small radius-small mn-b-thin" 
					/>
					
				</div>
			</transition>
			<transition name="slide-fade">
				<div v-show="tabAuth === 'email'" class="mn-b-thin radius-small o-hidden">
					<Field 
						v-model:field="auth.state.user.email" 	
						:placeholder="t('emailPlaceholder')" 	
						:validation="emailValidation"
						class="bg-light h-4r pd-medium radius-small" 
					/>
				</div>
			</transition>
		</div>

		<!-- Password -->
		<Field 
			v-model:field="auth.state.user.password"
			type="password" 	
			:validation="passswordValidation" 
			:placeholder="t('passwordPlaceholder')" 
			class="
        bg-light
        pd-medium
        radius-small
        mn-b-semi
        h-4r 
      "
		/>
		<!-- Links -->
		<div class="w-100 mn-b-big">
			<router-link
				to="/auth/reset-password" 
				class="underline d-block t-blue"
			>
				{{ t('forgotPassword') }}
			</router-link>
		</div>
		<!-- Button -->
		<Button 
			:submit="onSubmit" 
			:callback="redirectTo" 
			class="w-100 bg-main mn-b-thin"
		>
			{{ t('signin') }}
		</Button>
		
		<!-- <Button 
			:submit="onSubmitApple" 
			:callback="redirectTo" 
			class="mn-b-thin bg-black t-white"
		>
			{{ t('signin_apple') }}
		</Button> -->
	</section> 
</template>

<script setup>
// Import components
import Tab           from '@pf/src/components/Tab/Tab.vue'
import Field         from '@pf/src/components/Field/Field.vue'
import FieldPhone    from '@pf/src/components/FieldPhone/FieldPhone.vue'
import Button        from '@pf/src/components/Button/Button.vue'
// Import libs
import { computed, onMounted, ref, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
// Import localization
import text from '@pf/src/modules/auth/views/localization/SignIn.json'
// Import state
import * as auth from '@pf/src/modules/auth/views/store/auth'
// Import validation
import * as inputsValidation from '@pf/src/modules/auth/views/validations/inputs.validation'

// Init validation
const phoneValidation = ref(null)
const passswordValidation = ref(null)
const emailValidation = ref(null)
// Accessing router
const route = useRoute()
const router = useRouter()
// Localization
const { t } = useI18n({
	useScope: 'global', 
	...text
})

// Accessing state
const tabAuth = ref('phone')

const loadExternalScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
}

onMounted(async () => {
	await loadExternalScript('https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js');
});
// Methods
async function onSubmit() {
	try {
	  if (tabAuth.value === 'phone') await inputsValidation.validateInputs(
			phoneValidation, 
			inputsValidation.validatePhone, 
			auth.state.user.phone, 
			'Некорректный телефон'
		)
		if (tabAuth.value === 'email') await inputsValidation.validateInputs(
			emailValidation, 
			inputsValidation.validateEmail, 
			auth.state.user.email, 
			'Некорректный email'
		)
	} catch (error) {
		console.log(error)
		throw new Error
	}

	// COSTIL PLEASE GOD MAKE IT GOOD, FUKEN INPUT COMPONENT RETURN OBJECT NUT NUMBER
	const userCopy = {...auth.state.user};
	// COSTIL PLEASE GOD MAKE IT GOOD, FUKEN INPUT COMPONENT RETURN OBJECT NUT NUMBER

 	await auth.actions.login(userCopy, tabAuth.value)
}

async function onSubmitApple() {
  try {
    window.AppleID.auth.init({
      clientId: 'com.thecommune.app.signin',
      scope: 'email name',
      redirectURI: 'https://thecommunephuket.com/auth/signin',
      usePopup: true,
    });

    let response = await window.AppleID.auth.signIn();

    if (response) {
      console.log(response);
      await auth.actions.login(response, 'apple');
    }

  } catch (error) {
    console.error(error);
    throw new Error('Error during Apple authentication');
  }
}


function redirectTo () {
	if (route.query.returnUrl) {
		router.push({ 
			path: route.query.returnUrl,
			query: { 
				afterAuth: 'true' 
			}
		})
	} else { 
		router.push({ 
			name: 'User Profile', 
			params: { 
				_id: auth.state.user._id 
			},
			query: { 
				afterAuth: 'true' 
			}
		})
	}
}
</script>

<style lang="scss">
	.soon-label {
		position: absolute;
		right: -0.25em;
		top: -0.25rem;
		padding-left: 1rem;
		padding-right: 1rem;
		line-height: 1.5rem;
	}
	.slide-fade-enter-active {
	  transition: all  0.3s cubic-bezier(0.4, 0, 0.2, 1);
	}

	.slide-fade-leave-active {
	  transition: all  0.3s cubic-bezier(0.4, 0, 0.2, 1);

	}

	.slide-fade-enter-from,
	.slide-fade-leave-to {
		position: absolute;
	  transform: translateX(20px);
	  opacity: 0;
	  left: 0;
	  top: 0;
	}

	.slide-fade-leave-to {

	}

	.input-error {
  border: 1px solid red;
  box-shadow: 0 0 3px 0 red;
}
</style>