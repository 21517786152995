const legal = [
  {
    path: 'legal',
    name: 'Legal',
    meta: {
      title: {
        en: 'Legal',
        ru: 'Юридическая информация'
      },
    },
    component: () => import(/* webpackChunkName: 'Legal' */ '../components/pages/Legal.vue'),
  },
  {
    path: 'legal/privacy-policy',
    name: 'Privacy Policy',
    meta: {
      title: {
        en: 'Privacy Policy',
        ru: 'Политика конфиденциальности'
      },
    },
    component: () => import(/* webpackChunkName: 'Privacy' */ '../components/pages/legal/Privacy.vue'),
  },
  {
    path: 'legal/terms-of-use',
    name: 'Terms of Use',
    meta: {
      title: {
        en: 'Terms of Use',
        ru: 'Условия использования'
      },
    },
    component: () => import(/* webpackChunkName: 'Terms' */ '../components/pages/legal/Terms.vue'),
  },
  {
    path: 'legal/cookies-policy',
    name: 'Cookie Policy',
    meta: {
      title: {
        en: 'Cookie Policy',
        ru: 'Политика Сookies'
      },
    },
    component: () => import(/* webpackChunkName: 'Cookies' */ '../components/pages/legal/Cookies.vue'),
  },
  {
    path: 'legal/eula',
    name: 'EULA',
    meta: {
      title: {
        en: 'EULA',
        ru: 'EULA'
      },
    },
    component: () => import(/* webpackChunkName: 'Cookies' */ '../components/pages/legal/EULA.vue'),
  }
];

export default legal;
