import * as validationAuth from '@pf/src/modules/auth/views/middlewares/auth.validation';

const users = [{
  path: 'profile/:_id',
  name: 'User Profile',
   meta: {
      title: {
        en: 'Profile',
        ru: 'Профиль'
      }
    },
  component: () => import(/* webpackChunkName: "profile" */ '../components/pages/Profile.vue'),
},{
  path: 'profile/:_id/edit',
  name: 'User Edit Profile',
  beforeEnter: [
    validationAuth.requiresAuth,
  ],
  meta: {
    title: {
      en: 'Edit Profile',
      ru: 'Редактировать Профиль'
    }
  },
  component: () => import(/* webpackChunkName: "profile" */ '../components/pages/ProfileEdit.vue'),
},{
  path: 'profile/:_id/blogposts',
  name: 'User Blogposts',
  meta: {
    title: {
      en: 'Posts',
      ru: 'Посты'
    }
  },
  component: () => import(/* webpackChunkName: "profile" */ '../components/pages/ProfileBlogposts.vue'),
},{
  path: 'profile/:user/events',
  name: 'User Events',
  meta: {
    title: {
      en: 'Events',
      ru: 'События'
    }
  },
  component: () => import(/* webpackChunkName: "profile" */ '@pf/src/modules/events/components/pages/EventsBackoffice.vue'),
},{
  path: 'profile/:_id/organizations',
  name: 'User Organizations',
   meta: {
      title: {
        en: 'Groups',
        ru: 'Группы'
      }
    },
  component: () => import(/* webpackChunkName: "profile" */ '@pf/src/modules/organizations/components/pages/Organizations.vue'),
},{
  path: 'profile/:_id/wallet',
  name: 'User Wallet',
   meta: {
      title: {
        en: 'Wallet',
        ru: 'Кошелек'
      }
    },
  component: () => import(/* webpackChunkName: "profile" */ '@pf/src/modules/wallet/views/components/pages/Wallet.vue'),
}
// ,{
//   path: 'profile/:_id/likes',
//   name: 'User Likes',
//    meta: {
//       title: {
//         en: 'Likes',
//         ru: 'Лайки'
//       }
//     },
//   component: () => import(/* webpackChunkName: "profile" */ '../components/pages/ProfileLikes.vue'),
// },{
//   path: 'profile/:_id/comments',
//   name: 'User Comments',
//    meta: {
//       title: {
//         en: 'Comments',
//         ru: 'Комментарии'
//       }
//     },
//   component: () => import(/* webpackChunkName: "profile" */ '../components/pages/ProfileComments.vue'),
// },
];

export default users;