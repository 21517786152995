<template>
	<div class="flex-nowrap flex-v-center gap-micro pd-micro flex pos-relative">

		<div  
			v-for="(tab,index) in tabs" 
			:key="index" 
			@click="selectTab(index,tab)" 
			class="z-index-1 t-center"
			:class="[
			  index !== selectedTab ? 'bg-white' : 'bg-main',
			  tabClass ? tabClass : 'pd-small pd-r-medium pd-l-medium w-max pd-small radius-small'
			]"
		>
			<label class="">{{tab.name}}</label>
		</div>
	</div>
</template>


<script setup="props">
	import { ref, computed } from 'vue'

	const emit = defineEmits(['update:selected']);

	const props = defineProps({
		tabs: Array,
		tabClass: String,
		selected: String,
		callback: Function
	})

	const selectedTab = ref(0)

	if (props.selected) {
		const selectedIndex = props.tabs.findIndex(tab => tab.value === props.selected)
		if (selectedIndex !== -1) {
			selectedTab.value = selectedIndex
		}
	}
	
	function selectTab(index,tab) {
	  selectedTab.value = index;
	  emit('update:selected', tab.value);
	  if (props.callback) props.callback()
	}
</script>

<style lang="scss">
	.tab-selector {
		position: absolute;
		width: 50%;
		height: 100%;


		left: 0;
		top: 0;

		z-index: 0;

		transition: left 0.3s cubic-bezier(0.4, 0, 0.2, 1);
	}
</style>


