import layoutEvents from '../components/layouts/layoutEvents.vue'
import * as validationAuth from '@pf/src/modules/auth/views/middlewares/auth.validation';

const eventsRoutes = [
  {
    path: 'events',
    component: layoutEvents,
    meta: {
      title: {
        en: 'Events',
        ru: 'События'
      },
      title_hide: true
    },
    children: [{
      path: ':category?',
      name: 'Events',
      meta: {
        title: {
          en: 'Events',
          ru: 'События'
        },
        title_hide: true
      },
      component: () => import(/* webpackChunkName: 'EventList' */ '../components/pages/Events.vue')
    }]
  },{
    path: 'events/backoffice',
    name: 'Events Backoffice',
    meta: {
      title: {
        en: 'Manage Events',
        ru: 'Управление Событиями'
      },
      authorize: []
    },
    component: () => import(/* webpackChunkName: 'CreateEvent' */ '../components/pages/EventsBackoffice.vue')
  },{
    path: 'events/create',
    name: 'Create Event',
    meta: {
      title: {
        en: 'Create Event',
        ru: 'Создать Событие'
      },
      authorize: []
    },
    component: () => import(/* webpackChunkName: 'CreateEvent' */ '../components/pages/EditEvent.vue')
  },{
    path: 'events/:url',
    name: 'Event',
    meta: {
      title: {
        en: 'Event',
        ru: 'Событие'
      }
    },
    component: () => import(/* webpackChunkName: 'EventDetail' */ '../components/pages/Event.vue')
  },{
    path: 'events/:url/edit',
    name: 'Edit Event',
    meta: {
      title: {
        en: 'Edit Event',
        ru: 'Редактировать Событие'
      }
    },
    component: () => import(/* webpackChunkName: 'EditEvent' */ '../components/pages/EditEvent.vue')
  },{
    path: 'events/:url/edit/tickets',
    name: 'Edit Event Tickets',
    meta: {
      title: {
        en: 'Event Tickets',
        ru: 'Билеты События'
      }
    },
    component: () => import(/* webpackChunkName: 'EditEvent' */ '../components/pages/EditEventTickets.vue')
  }
];

export default eventsRoutes;
