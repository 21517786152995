import customerInitState from "@pf/src/modules/orders/store/models/customer"

const orderInitState = {
  _id: undefined,
  positions: [],
  status: null,
  comment: '',

  owner: {
    type: 'Organization',
    target: null,
  },
  
  creator: {
    type: 'Customer',
    target: null,
  },

  customer: { ...customerInitState },

  payment: {
    type: 'Cash',
    status: 'Unpaid',
  },

  delivery: {
    type: null,
    address: null,
    status: '',
  }
};

export default orderInitState