<template>

		<transition name="TransitionTranslateY" appear>
				<teleport to="body" v-if="isPopupOpen">
					<div v-if="isPopupOpen" key="popup-content" class="pd-small z-index-6 popup-wrapper">

						<transition name="TransitionTranslateY" mode="out-in">
							<section class="pos-relative z-index-4" v-bind="$attrs" :class="$attrs.class">
									<h4 v-if="title" class="mn-b-small">{{title}}</h4>
									<IconCross :fill="'rgb(var(--white))'" @click="closePopup()" class="cursor-pointer bg-second pd-micro radius-extra pos-absolute pos-t-0 pos-r-0 mn-t-semi mn-r-semi hover-scale-1 i-medium z-index-5"/>
									<slot></slot>
							</section>  
						</transition>

						<div 
							@click="closePopup()" 
					    :class="{'active':isPopupOpen === true}" 
					    class="color-overlay z-index-3">
						</div>

					</div>
			</teleport>
		</transition>

</template>

<script setup>
import { computed, watch, onMounted, ref, nextTick } from 'vue';
// Import libs
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
// Icons
import IconCross from '@pf/src/modules/icons/navigation/IconCross.vue';
// Define props
const props = defineProps({
  isPopupOpen: {
  	type: Boolean,
	  default: false
  },
  style: String,
  title: String,
});
const emits = defineEmits(['close-popup'])

function closePopup() {
  emits("close-popup");
} 

watch(() => props.isPopupOpen, (newVal) => {
  if (newVal) {
    document.body.classList.add('no-scroll');
  } else {
    document.body.classList.remove('no-scroll');
  }
});

// Accessing router and store
const route = useRoute()
const router = useRouter()
// Localization
const text = {
  	messages: {
	    en: {},
	    ru: {}
	}
}
const { t } = useI18n(text)
</script>

<style lang="scss">
	.no-scroll {
	  overflow: hidden;
	}

	.popup-wrapper {
		display: flex;
		position: fixed;

		align-items: center;
		justify-content: center;

		width: 100%;
		height: 100%;

		left: 0;
		top: 0;

		z-index: 40;

		.popup {
			position: absolute;
			height: auto;
			padding: 2rem;
			min-width: 24rem;
			border-radius: 2rem;
			overflow: hidden;

			color: white;
			background: black;

			z-index: 40;
		}
	}

	.color-overlay {
		display: block;
		position: fixed;

		left: 0;
		top: 0;

		width: 100%;
		height: 100%;

		background: rgba(0,0,0,0.0);
		pointer-events: none;

		transform: scale(1.5);

		transition: all 0.5s cubic-bezier(.31,.79,.24,.92);

		&.active {
			background: rgba(#000,0.25);
			pointer-events: all;
		}
	}
</style>
