<template>
  <Block title="Delivery" class="mn-b-thin" :tooltip="'Delivery by mail takes 3-5 days.'">

    <!-- <h4 class="mn-b-thin">Select spot</h4>
    <div class="mn-b-small flex-nowrap flex">

    </div> -->

    <h4 class="font-second mn-b-thin">Select deliverty type</h4>
    <div class="mn-b-small flex-nowrap flex">
      <Radio 
        v-model:radio="order.delivery.type"
        label="Pickup"
        value="pickup"
        class="bg-white pd-medium radius-small w-100 mn-r-small"
      />
      <Radio 
        v-model:radio="order.delivery.type"
        label="Courier"
        value="courier"
        class="bg-white pd-medium radius-small w-100 mn-r-small"
      />
      <Radio 
        v-model:radio="order.delivery.type"
        label="Post"
        value="mail"
        class="bg-white pd-medium radius-small w-100"
      />
    </div>

    
    
    <h4 class="font-second mn-b-thin">Address</h4>
    <Address
      label="Location"    
      :apiKey="GOOGLE_MAPS_API_KEY" 
      :address="order.delivery.address" 
      :location="order.delivery.location"  
      placeholder="Enter your address"  
      class="bg-white radius-tl-medium radius-tr-medium t-black pd-medium"
      @update:location="newLocation => {
        order.delivery.location = newLocation;
      }"
      @update:address="newAddress => {
        order.delivery.address = newAddress;
      }"
    />

    <LocationMarker 
      :apiKey="GOOGLE_MAPS_API_KEY" 
      :address="order.delivery.address" 
      :location="order.delivery.location"
      class="mn-b-thin radius-bl-medium radius-br-medium h-15r o-hidden"
      @update:location="newLocation => {
        order.delivery.location = newLocation;
      }"
      @update:address="newAddress => {
        order.delivery.address = newAddress;
      }"
    />

    <Field 
      v-model:field="order.comment"     
      placeholder="Comments regarding the address (apartment unit, floor level, building section)"
      type="textarea"  
      class="bg-white pd-medium radius-small"
    />
  </Block>
</template>

<script setup>
import { onMounted } from 'vue'
import * as globals from '@pf/src/modules/globals/views/store/globals';
import Block from '@pf/src/components/Block/Block.vue'
import Address from '@pf/src/components/Address/Address.vue'
import LocationMarker from "@pf/src/components/LocationMarker/LocationMarker.vue";
import Radio from '@pf/src/components/Radio/Radio.vue'
import Field from '@pf/src/components/Field/Field.vue'

const props = defineProps({
  order: Object,
});

onMounted(() => {
  if (globals.state.position) props.order.delivery.address = globals.state.position.address
  if (globals.state.position) props.order.delivery.location = globals.state.position.location
})
</script>