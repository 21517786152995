<template>
  <div class="bg-white radius-semi bg-white o-hidden pos-relative">
    
      <div ref="allMessages"  class="chat-messages o-scroll h-max-20r">
        <div  class="pos-relative h-min-20r flex-justify-end flex flex-column br-b br-solid br-black-transp-10">
          <transition  name="scaleIn" mode="out-in">
            <div v-if="messages.length < 1"  class="flex-center w-100 h-100 flex-column flex pos-absolute">
              <PlaceholderChat class="radius-100 mn-b-thin"/>
              <h4>Here you can view your chat history</h4>
              <p>Feel free to ask if you have any questions.</p>
            </div>

            <TransitionGroup v-else  name="list" tag="ul" class="w-100 o-hidden">
              <ChatMessage
                v-for="message in messages"
                :key="message._id"
                :message="message"
              />

            </TransitionGroup>
           </transition>
          
        </div>
      </div>

      
      <div class="flex-nowrap flex-v-center flex bg-white w-100 radius-big pd-small">
        <IconAdd
          class="mn-r-thin t-transp i-regular"
        />
        <input class="" placeholder="Enter your message" type="text" v-model="newMessage" @keyup.enter="sendMessage" />
      </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';

import IconAdd from '@pf/src/modules/icons/navigation/IconAdd.vue'
import PlaceholderChat from '@pf/src/modules/icons/placeholders/PlaceholderChat.vue'

import ChatMessage from '../blocks/ChatMessage.vue';
import chatStore from '../../store/chat.store.js';

const newMessage = ref('');
const allMessages = ref(null)

// Вычисляемые свойства
const messages = computed(() => chatStore.state.messages);

// Функция для отправки сообщения
const sendMessage = () => {
  if (newMessage.value) {
    const message = {
      text: newMessage.value,
      username: chatStore.state.username,
      chatId: chatStore.state.currentChatId
    };

    chatStore.methods.addMessage(message);
    console.log(allMessages.value.lastElementChild.offsetHeight)
    allMessages.value.scrollTop = allMessages.value.lastElementChild.offsetHeight
    
    newMessage.value = '';
  }
};

// // При добавлении нового сообщения автоматически прокручиваем вниз
watch(chatStore.state.messages, () => {
  allMessages.value.scrollTop = allMessages.value.lastElementChild.offsetHeight
}, {deep: true});

</script>

<style>
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
