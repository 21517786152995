import * as validationAuth from '@pf/src/modules/auth/views/middlewares/auth.validation';

const productRoutes = [{
  path: 'products',
  name: 'Products',
  meta: {
    title: {
      en: 'Products',
      ru: 'Товары'
    },
    authorize: []
  },
  component: () => import(/* webpackChunkName: 'Products' */ '@pf/src/modules/products/components/pages/Products.vue'),
},{
  path: 'products/add',
  name: 'ProductAdd',
  component: () => import('@pf/src/modules/products/components/pages/ProductEdit.vue')
},{
  path: 'products/:product',
  name: 'Product Organization',
  meta: {
    title: {
      en: 'Product',
      ru: 'Продукт'
    }
  },
  component: () => import('@pf/src/modules/products/components/pages/Product.vue')
}, {
  path: 'products/:product/edit',
  name: 'ProductEdit',
  beforeEnter: [validationAuth.requiresAuth],
  component: () => import('@pf/src/modules/products/components/pages/ProductEdit.vue')
}];  

export default productRoutes;
