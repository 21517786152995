<template>
  <transition 
    name="scaleTransition" 
    mode="out-in"
  >
    <section 
      v-if="FirstUse && route.meta.walkthrough"
      class="w-100 h-100" 
     >
      <component 
        :is="route.meta.walkthrough"
        name="Walkthrough"
        @updateFirstUse="updateFirstUse" 
        :slides="[1,2,3]" 
        class="tab"
      >
      </component>
    </section>
  </transition>

  <!-- {{current_url}} -->
  <transition name="moveFromTop" mode="out-in" appear>
    <component
      v-if="route.meta.sidebar && globals.state.isOpenSidebar"
      :is="route.meta.sidebar"
      :stateSidebar="globals.state.isOpenSidebar" 
      @closeSidebar="() => { 
        globals.state.isOpenSidebar = !globals.state.isOpenSidebar 
      }"

      class="transition-ease-in-out pos-absolute w-70 z-index-0  bg-light"
      :class="{
        'pos-l-70-negative': !globals.state.isOpenSidebar,
        'pos-l-0': globals.state.isOpenSidebar
      }"
      v-slot="{ Component }"
    >
      <component
        v-if="route.meta.navigation"
        :is="route.meta.navigation"
        class="h3 pd-big cols-1" 
      />
    </component>
  </transition>

  <router-view 
    v-slot="{ Component }"
    :class="{
      'pos-l-70': globals.state.isOpenSidebar,
      'pos-l-0': !globals.state.isOpenSidebar,
      'pd-t-extra': MOBILE_APP === 'ios'
    }"
    @click="globals.state.isOpenSidebar ? a => globals.state.isOpenSidebar = false : a => { console.log('not succes') }"
    class="transition-ease-in-out h-min-100 w-100 bg-white pos-relative"
  >
    <component ref="page" :is="Component"/>
  </router-view>
</template>

<script setup>
  import { computed, ref, onMounted, defineAsyncComponent, onBeforeMount } from 'vue'
  import { useHead } from '@unhead/vue'
  import { useI18n } from 'vue-i18n'
  import { useRoute } from 'vue-router'

  import * as globals from '@pf/src/modules/globals/views/store/globals'
  import * as auth from '@pf/src/modules/auth/views/store/auth'

  const props = defineProps({
    messages: {
      type: Object,
      required: true
    },
    logotype: {
      type: Object
    },
  })
  /////////////////////////////
  // LOADING
  /////////////////////////////
    // State
  let show = ref(false)
  // Preloader
  const page = ref(null)
  /////////////////////////////
  // META
  /////////////////////////////
  const { t, locale } = useI18n({
    messages: props.messages
  })


  const route = useRoute();

  const languages = ['en', 'ru'];
  const base_url = process.env.FILE_SERVER_URL;

  function removeTrailingSlash(url) {
    return url.endsWith('/') ? url.slice(0, -1) : url;
  }
  // Используйте эту функцию, чтобы удалить слэш в конце URL
  const metadata = computed(() => {return {
    title: t('meta.title'),
    description: t('meta.description'),
    meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1, shrink-to-fit=no' },
        { hid: 'description', name: 'description', content: t('meta.description') },
        { hid: 'keywords', name: 'keywords', content: t('meta.keywords') },
        { hid: 'og:title', property: 'og:title', content: t('meta.og.title') },
        { hid: 'og:description', property: 'og:description', content: t('meta.og.description') },
        { hid: 'og:image', property: 'og:image', content: base_url + '/favicon/cover.jpg' },
        { hid: 'og:url', property: 'og:url', content: removeTrailingSlash(`${base_url}${route.path}`) },
        { hid: 'twitter:title', name: 'twitter:title', content: t('meta.twitter.title') },
        { hid: 'twitter:description', name: 'twitter:description', content: t('meta.twitter.description') },
        { hid: 'twitter:image', name: 'twitter:image', content: base_url + '/favicon/cover.jpg' },
        { hid: 'twitter:card', name: 'twitter:card', content: t('meta.twitter.card') },
        ],
    link: [
      { hid: 'canonical', rel: 'canonical', href: removeTrailingSlash(`${base_url}${route.path}`) },
      ...generateAlternateLinks.value,
    ],
    htmlAttrs: { lang: t('meta.htmlAttrs.lang') }
  }});

  const generateAlternateLinks = computed(() => {
    return languages.map(lang => {
      let newPath = route.path;

      if (route.params.locale) {
        newPath = newPath.replace(`/${route.params.locale}`, `/${lang}`);
      } else {
        newPath = `/${lang}${newPath}`;
      }
      const href = removeTrailingSlash(`${base_url}${newPath}`);
      return { 
        hid: 'alternate', 
        rel: 'alternate', 
        hreflang: lang, 
        href: href
      };
    });
  });

  useHead(metadata)
  /////////////////////////////
  // FIRST USE
  /////////////////////////////
  const FirstUse = ref(false);

  import { Preferences } from '@capacitor/preferences';

  async function getFirstUse() {
    const ret = await Preferences.get({ key: 'first-use' });
    FirstUse.value = ret.value ? JSON.parse(ret.value) : true;
  }

  const updateFirstUse = (value) => {
    FirstUse.value = value;
  }

  onBeforeMount(async()=>{
    const { value } = await Preferences.get({ key: 'darkmode' });
    const isDarkMode = value ? JSON.parse(value) : false;
    
    // Устанавливаем тему
    globals.actions.setTheme(isDarkMode);
  })

  onMounted(async () => {
    await getFirstUse()
    if (page) show.value = true
  });
</script>