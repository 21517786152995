import * as validationAuth from '@pf/src/modules/auth/views/middlewares/auth.validation';

const departmentRoutes = [{
  path: 'departments/create',
  name: 'Department Creation',
  meta: {
    title: 'Создание отдела',
  },
  beforeEnter: [validationAuth.requiresAuth],
  component: () => import('@pf/src/modules/organizations/components/pages/DepartmentEdit.vue'),
}, {
  path: 'departments/:department',
  name: 'Department',
  meta: {
    title: 'Отдел',
  },
  beforeEnter: [validationAuth.requiresAuth],
  component: () => import('@pf/src/modules/organizations/components/pages/Department.vue'),
}, {
  path: 'departments/:department/edit',
  name: 'Department Edit',
  meta: {
    title: 'Редактирование отдела',
  },
  beforeEnter: [validationAuth.requiresAuth],
  component: () => import('@pf/src/modules/organizations/components/pages/DepartmentEdit.vue'),
}];

export default departmentRoutes;
