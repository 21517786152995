<template>
	<section class="t-left pd-medium">
		<!-- <img loading="lazy" src="@/assets/icons/password.png" class="i-extra mn-b-small"> -->
		<!-- Header -->
		<h3 class="mn-b-small">{{ t('forgotPasswordTitle') }}</h3>
		<p class="mn-b-small t-transp">{{ t('instructions') }}</p>
		
		<!-- Select -->
		<div class="mn-b-small p-small uppercase t-semi bg-light radius-big o-hidden">
			<Tab 
				v-model:selected="tabAuth"
				:tabs="[
					{name: t('phone'), value: 'phone'},
					{name: t('email'), value: 'email'}
				]"
				tabClass="pd-small pd-r-medium pd-l-medium w-100 pd-small radius-small"
			/>
		</div>

		<!-- Form -->
		<div class="pos-relative">
		<!-- Phone -->
			<transition name="slide-fade">
				<div v-if="tabAuth === 'phone'" class="mn-b-semi radius-small">
					<p class="mn-b-small t-transp">{{ t('smsInfo') }}</p>
					<FieldPhone
						@change="(event) => auth.state.user.phone = event" 	
						:dropdownOptions="{
							showDialCodeInSelection: true,
							showFlags: true,
							showDialCodeInList: true
						}"
						:validation="phoneValidation" 
						mode="national"
						:inputOptions="{placeholder: t('phonePlaceholder')}"
						class="bg-light h-4r pd-small radius-small mn-b-thin" 
					/>
				</div>
			</transition>
			<!-- Email -->
			<transition name="slide-fade">
				<div v-if="tabAuth === 'email'" class="mn-b-semi radius-small o-hidden">
					<p class="mn-b-small t-transp">{{ t('emailInfo') }}</p>
					<Field 
						v-model:field="auth.state.user.email" 		
						:placeholder="t('emailPlaceholder')" 	
						:validation="emailValidation" 
						class="bg-light h-4r pd-medium radius-small" 
					/>
				</div>
			</transition>
		</div>
		<!-- Button -->
		<Button :submit="onSubmit" :callback="redirectTo" class="w-100 bg-main">{{ t('sendCode') }}</Button>
	</section> 
</template>

<script setup>
// Import components
import Tab           from '@pf/src/components/Tab/Tab.vue'
import Field         from '@pf/src/components/Field/Field.vue'
import FieldPhone    from '@pf/src/components/FieldPhone/FieldPhone.vue'
import Button        from '@pf/src/components/Button/Button.vue'
// Import libs
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
// Localization
import text from '@pf/src/modules/auth/views/localization/ResetPassword.json'
// Import state
import * as auth from '@pf/src/modules/auth/views/store/auth'
import * as twofa from '@pf/src/modules/auth/views/store/twofa'
// Import validation
import * as inputsValidation from '@pf/src/modules/auth/views/validations/inputs.validation'
// Localization
const { t } = useI18n({
	useScope: 'global', 
	...text
})
// Validation
const phoneValidation = ref(null)
const emailValidation = ref(null)
// Accessing router
const route = useRoute()
const router = useRouter()
// Accessing state
const tabAuth = ref('phone')
// Methods
async function onSubmit() {
	try {
	  if (tabAuth.value === 'phone') await inputsValidation.validateInputs(
			phoneValidation, 
			inputsValidation.validatePhone, 
			auth.state.user.phone, 
			'Некорректный телефон'
		)
		if (tabAuth.value === 'email') await inputsValidation.validateInputs(
			emailValidation, 
			inputsValidation.validateEmail, 
			auth.state.user.email, 
			'Некорректный email'
		)
	} catch (error) {
		throw new Error
	}
	try {
  	await auth.actions.resetPassword(auth.state.user, tabAuth.value, 'reset-password')
  } catch (error) {
  	console.log(error)
		throw new Error
	}
}

function redirectTo () {
	router.push({ name: 'Enter Code', query: {type: tabAuth.value, method: 'reset-password'} })
}
</script>

<style lang="scss">
	.slide-fade-enter-active {
	  transition: all  0.3s cubic-bezier(0.4, 0, 0.2, 1);
	}

	.slide-fade-leave-active {
	  transition: all  0.3s cubic-bezier(0.4, 0, 0.2, 1);

	}

	.slide-fade-enter-from,
	.slide-fade-leave-to {
		position: absolute;
	  transform: translateX(20px);
	  opacity: 0;
	  left: 0;
	  top: 0;
	}

	.slide-fade-leave-to {

	}
</style>
