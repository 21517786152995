import { createI18n } from 'vue-i18n'

const supportedLocales = {
  en: "🌎 EN",
  ru: "🇷🇺 RU",
  zh: "🇨🇳 ZH",
  fr: "🇫🇷 FR",
  de: "🇩🇪 DE",
  it: "🇮🇹 IT"
}

const messages = {};

for (const locale of Object.keys(supportedLocales)) {
  messages[locale] = {
  }
}

const i18n = createI18n({
  locale: 'en',
  fallbackLocale: {
    'en': ['en-default'],
    'ru': ['ru-default', 'en-default'],
    'zh': ['zh-default', 'en-default'],
    'fr': ['fr-default', 'en-default'],
    'de': ['de-default', 'en-default'],
    'it': ['it-default', 'en-default'],
  },
  legacy: false,
  globalInjection: true,
  warnHtmlMessage: false,
  missingWarn: false,
  fallbackWarn: false,
  messages: messages,
  availableLocales: Object.keys(supportedLocales),
});

export default i18n;
