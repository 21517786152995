<script setup>
import { ref } from 'vue';

import Checkbox from "@pf/src/components/Checkbox/Checkbox.vue";
import Spoiler from "@pf/src/components/Spoiler/Spoiler.vue"

 import IconChevronBottom from '@pf/src/modules/icons/navigation/IconChevronBottom.vue'

const props = defineProps({
  title: {
    type: String,
    default: 'Sorting Options'
  },
  options: {
    type: Array
  }
});

const filter = defineModel('filter')
</script>

<template>
  <div class="w-100 o-scroll">
    <Spoiler 
      v-for="filterOption in options"
      class="o-hidden bg-white pd-medium radius-big mn-b-small"
      :status="true"
    >
      <template  #header="{ isOpen }">
        <div class="cursor-pointer w-100 flex-v-center flex-nowrap flex">
          <h4 class="w-100" >{{ filterOption.title }}</h4>
          <IconChevronBottom :class="{ 'rotate-180': isOpen }" fill="rgb(var(--black))" class="radius-big pd-micro bg-light i-semi"/>
        </div>
      </template>

      <template #content>
        <Checkbox 
          v-for="(checkbox, index) in  filterOption.options"
          :key="index"
          :label="checkbox.label"
          :value="checkbox.value"
          class="w-100 mn-t-small mn-b-small br-1px br-solid br-light  radius-small pd-small"
          :radio="filter.selected[filterOption.value]"
          @update:radio="event => filter.selected[filterOption.value] = event"
        />

      </template>

      
    </Spoiler>

    <button @click="() => filter.selected = {}"  class="bg-main w-100 button">Reset Filters</button>
  </div>
</template>
