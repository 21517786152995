<template>
    <footer ref="navRef" class="pos-relative bg-white">
        <nav class="t-black w-100 flex-justify-between flex-v-center flex">
            <!-- Home -->
            <router-link class="no-hover-color t-center  t-black t-medium" to="/">
              <svg class="mn-r-auto mn-l-auto i-big mn-b-thin"  width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M55.3446 22.7672L29.6446 4.36722C29.3068 4.1283 28.9033 4 28.4896 4C28.0758 4 27.6723 4.1283 27.3345 4.36722L1.63455 22.7672C1.28187 23.0968 1.06027 23.5427 1.01061 24.0228C0.960962 24.5029 1.08661 24.9848 1.36439 25.3796C1.64217 25.7743 2.05332 26.0552 2.52201 26.1706C2.9907 26.286 3.48529 26.2279 3.91455 26.0072L8.48455 22.8072V49.0072C8.48455 49.5377 8.69526 50.0464 9.07034 50.4214C9.44541 50.7965 9.95412 51.0072 10.4846 51.0072H46.4846C47.015 51.0072 47.5237 50.7965 47.8988 50.4214C48.2738 50.0464 48.4846 49.5377 48.4846 49.0072V22.8472L53.0546 26.0072C53.4842 26.2352 53.9823 26.2988 54.4554 26.1861C54.9286 26.0734 55.3445 25.7921 55.6253 25.3949C55.906 24.9977 56.0324 24.5118 55.9807 24.0281C55.929 23.5444 55.7029 23.0962 55.3446 22.7672ZM23.4846 47.0072V31.0072H33.4846V47.0072H23.4846ZM44.4846 47.0072H37.4846V29.0072C37.4846 28.4768 37.2738 27.9681 36.8988 27.593C36.5237 27.2179 36.015 27.0072 35.4846 27.0072H21.4846C20.9541 27.0072 20.4454 27.2179 20.0703 27.593C19.6953 27.9681 19.4846 28.4768 19.4846 29.0072V47.0072H12.4846V20.0072L28.4846 8.80722L44.4846 20.0072V47.0072Z" fill="rgb(var(--main))"/>
              </svg>
              <!-- <p class="uppercase t-semi p-small w-100">{{$t('navigation.home')}}</p> -->
            </router-link>
            <!-- Galler -->
            <router-link class="no-hover-color t-center  t-black t-medium" to="/gallery">
              <svg class="mn-r-auto mn-l-auto i-big mn-b-thin"  width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 1H5C3.6744 1.00159 2.40356 1.52888 1.46622 2.46622C0.528882 3.40356 0.00158786 4.6744 0 6V20C0.00158786 21.3256 0.528882 22.5964 1.46622 23.5338C2.40356 24.4711 3.6744 24.9984 5 25H21C22.2869 24.9957 23.5225 24.4955 24.4499 23.6033C25.3773 22.7111 25.925 21.4957 25.979 20.21C25.9855 20.1696 25.9895 20.1289 25.991 20.088C25.991 20.058 26 20.03 26 20V6C25.9984 4.6744 25.4711 3.40356 24.5338 2.46622C23.5964 1.52888 22.3256 1.00159 21 1ZM5 3H21C21.7956 3 22.5587 3.31607 23.1213 3.87868C23.6839 4.44129 24 5.20435 24 6V17.39L20.163 12.967C19.8931 12.6556 19.562 12.4032 19.1903 12.2254C18.8186 12.0476 18.4142 11.9483 18.0025 11.9336C17.5907 11.919 17.1803 11.9894 16.7969 12.1403C16.4135 12.2912 16.0653 12.5196 15.774 12.811L13.458 15.128L10.109 11.009C9.85174 10.6926 9.53266 10.4319 9.17126 10.243C8.80986 10.054 8.41374 9.94072 8.00708 9.91002C7.60042 9.87933 7.19178 9.93186 6.80611 10.0644C6.42044 10.197 6.06585 10.4068 5.764 10.681L2 14.1V6C2 5.20435 2.31607 4.44129 2.87868 3.87868C3.44129 3.31607 4.20435 3 5 3ZM2 20V16.8L7.108 12.159C7.20867 12.0675 7.32695 11.9975 7.4556 11.9534C7.58424 11.9092 7.72055 11.8917 7.85619 11.902C7.99182 11.9123 8.12393 11.9501 8.24443 12.0132C8.36493 12.0763 8.47129 12.1634 8.557 12.269L17.278 23H5C4.20435 23 3.44129 22.6839 2.87868 22.1213C2.31607 21.5587 2 20.7956 2 20ZM21 23H19.856L14.726 16.688L17.188 14.226C17.2863 14.1301 17.4027 14.0547 17.5304 14.0044C17.6582 13.9541 17.7947 13.9298 17.932 13.933C18.0692 13.9372 18.204 13.97 18.3278 14.0292C18.4516 14.0884 18.5617 14.1728 18.651 14.277L23.96 20.4C23.8635 21.1176 23.5106 21.7761 22.9666 22.254C22.4226 22.7318 21.7241 22.9968 21 23Z" fill="rgb(var(--main))"/>
              </svg>


              <!-- <p class="uppercase t-semi p-small w-100">{{$t('navigation.gallery')}}</p> -->
            </router-link>
            <!-- Events -->
            <router-link class="no-hover-color t-center  t-black t-medium" to="/events">
              <svg class="mn-r-auto mn-l-auto i-big mn-b-thin" width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.091 16C16.5123 16 15.9574 15.7893 15.5482 15.4142C15.139 15.0391 14.9092 14.5304 14.9092 14V5C14.9092 4.46957 15.139 3.96086 15.5482 3.58579C15.9574 3.21071 16.5123 3 17.091 3C17.6697 3 18.2246 3.21071 18.6338 3.58579C19.0429 3.96086 19.2728 4.46957 19.2728 5V14C19.2728 14.5304 19.0429 15.0391 18.6338 15.4142C18.2246 15.7893 17.6697 16 17.091 16Z" fill="rgb(var(--main))"/>
                <path d="M38.9091 16C38.3305 16 37.7755 15.7893 37.3663 15.4142C36.9572 15.0391 36.7273 14.5304 36.7273 14V5C36.7273 4.46957 36.9572 3.96086 37.3663 3.58579C37.7755 3.21071 38.3305 3 38.9091 3C39.4878 3 40.0427 3.21071 40.4519 3.58579C40.8611 3.96086 41.0909 4.46957 41.0909 5V14C41.0909 14.5304 40.8611 15.0391 40.4519 15.4142C40.0427 15.7893 39.4878 16 38.9091 16Z" fill="rgb(var(--main))"/>
                <path d="M17.0909 42.9999C16.6607 42.9982 16.2406 42.88 15.8835 42.6602C15.5264 42.4403 15.2482 42.1285 15.0838 41.7641C14.9194 41.3997 14.8762 40.9989 14.9596 40.612C15.0431 40.2252 15.2494 39.8696 15.5527 39.5899C15.9615 39.2174 16.5145 39.0083 17.0909 39.0083C17.6673 39.0083 18.2203 39.2174 18.6291 39.5899C18.9323 39.8696 19.1387 40.2252 19.2221 40.612C19.3055 40.9989 19.2623 41.3997 19.098 41.7641C18.9336 42.1285 18.6553 42.4403 18.2982 42.6602C17.9411 42.88 17.5211 42.9982 17.0909 42.9999Z" fill="rgb(var(--main))"/>
                <path d="M38.909 42.9999C38.4788 42.9982 38.0587 42.88 37.7016 42.6602C37.3445 42.4403 37.0663 42.1285 36.9019 41.7641C36.7375 41.3997 36.6943 40.9989 36.7778 40.612C36.8612 40.2252 37.0675 39.8696 37.3708 39.5899C37.7796 39.2174 38.3326 39.0083 38.909 39.0083C39.4854 39.0083 40.0384 39.2174 40.4472 39.5899C40.7505 39.8696 40.9568 40.2252 41.0402 40.612C41.1236 40.9989 41.0805 41.3997 40.9161 41.7641C40.7517 42.1285 40.4735 42.4403 40.1163 42.6602C39.7592 42.88 39.3392 42.9982 38.909 42.9999Z" fill="rgb(var(--main))"/>
                <path d="M17.091 34C16.6599 34.0003 16.2383 33.8835 15.8796 33.6644C15.5208 33.4453 15.2409 33.1336 15.0751 32.7688C14.9094 32.404 14.8653 32.0023 14.9484 31.6146C15.0314 31.2268 15.238 30.8702 15.5419 30.59C15.9592 30.2285 16.5115 30.0271 17.0856 30.0271C17.6597 30.0271 18.212 30.2285 18.6292 30.59C18.9325 30.8696 19.1388 31.2253 19.2223 31.6121C19.3057 31.999 19.2625 32.3998 19.0981 32.7642C18.9337 33.1286 18.6555 33.4404 18.2984 33.6603C17.9413 33.8801 17.5212 33.9983 17.091 34Z" fill="rgb(var(--main))"/>
                <path d="M28.0002 42.9999C27.5691 43.0002 27.1475 42.8834 26.7887 42.6643C26.43 42.4452 26.15 42.1336 25.9843 41.7688C25.8186 41.4039 25.7745 41.0023 25.8575 40.6145C25.9406 40.2267 26.1472 39.8702 26.4511 39.5899C26.8696 39.2307 27.4214 39.0308 27.9947 39.0308C28.5681 39.0308 29.1199 39.2307 29.5384 39.5899C29.9481 39.9633 30.1796 40.4703 30.182 40.9999C30.182 41.5303 29.9522 42.0391 29.543 42.4141C29.1338 42.7892 28.5789 42.9999 28.0002 42.9999Z" fill="rgb(var(--main))"/>
                <path d="M28.0002 34C27.5691 34.0003 27.1475 33.8835 26.7887 33.6644C26.43 33.4453 26.15 33.1336 25.9843 32.7688C25.8186 32.404 25.7745 32.0023 25.8575 31.6146C25.9406 31.2268 26.1472 30.8702 26.4511 30.59C26.8683 30.2285 27.4207 30.0271 27.9947 30.0271C28.5688 30.0271 29.1212 30.2285 29.5384 30.59C29.8417 30.8696 30.048 31.2253 30.1314 31.6121C30.2149 31.999 30.1717 32.3998 30.0073 32.7642C29.8429 33.1286 29.5647 33.4404 29.2076 33.6603C28.8504 33.8801 28.4304 33.9983 28.0002 34Z" fill="rgb(var(--main))"/>
                <path d="M38.9091 34C38.3305 34 37.7755 33.7893 37.3663 33.4142C36.9572 33.0391 36.7273 32.5304 36.7273 32C36.7268 31.4718 36.9543 30.965 37.36 30.59C37.7772 30.2285 38.3296 30.0271 38.9037 30.0271C39.4777 30.0271 40.0301 30.2285 40.4473 30.59C40.7506 30.8696 40.9569 31.2253 41.0403 31.6121C41.1238 31.999 41.0806 32.3998 40.9162 32.7642C40.7518 33.1286 40.4736 33.4404 40.1165 33.6603C39.7594 33.8801 39.3393 33.9983 38.9091 34Z" fill="rgb(var(--main))"/>
                <path d="M45.4545 53H10.5455C8.80949 53 7.14463 52.3679 5.91712 51.2426C4.68961 50.1174 4 48.5913 4 47V15C4 13.4087 4.68961 11.8826 5.91712 10.7574C7.14463 9.63214 8.80949 9 10.5455 9H45.4545C47.1905 9 48.8554 9.63214 50.0829 10.7574C51.3104 11.8826 52 13.4087 52 15V47C52 48.5913 51.3104 50.1174 50.0829 51.2426C48.8554 52.3679 47.1905 53 45.4545 53ZM10.5455 13C9.9668 13 9.41184 13.2107 9.00267 13.5858C8.5935 13.9609 8.36364 14.4696 8.36364 15V47C8.36364 47.5304 8.5935 48.0391 9.00267 48.4142C9.41184 48.7893 9.9668 49 10.5455 49H45.4545C46.0332 49 46.5882 48.7893 46.9973 48.4142C47.4065 48.0391 47.6364 47.5304 47.6364 47V15C47.6364 14.4696 47.4065 13.9609 46.9973 13.5858C46.5882 13.2107 46.0332 13 45.4545 13H10.5455Z" fill="rgb(var(--main))"/>
                <path d="M49.8182 24H6.18182C5.60316 24 5.04821 23.7893 4.63904 23.4142C4.22987 23.0391 4 22.5304 4 22C4 21.4696 4.22987 20.9609 4.63904 20.5858C5.04821 20.2107 5.60316 20 6.18182 20H49.8182C50.3968 20 50.9518 20.2107 51.361 20.5858C51.7701 20.9609 52 21.4696 52 22C52 22.5304 51.7701 23.0391 51.361 23.4142C50.9518 23.7893 50.3968 24 49.8182 24Z" fill="rgb(var(--main))"/>
              </svg>

              <!-- <p class="uppercase t-semi p-small w-100">{{$t('navigation.events')}}</p> -->
            </router-link>
          <!-- Community -->
          <router-link class="no-hover-color t-center  t-black t-medium" to="/community">
            <svg class="mn-r-auto mn-l-auto i-big mn-b-thin" width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M28.2899 50C24.4282 50.007 20.6332 48.9927 17.2899 47.06L12.5299 49.17C11.6317 49.567 10.6307 49.6695 9.67068 49.4627C8.71061 49.2558 7.84068 48.7503 7.1856 48.0186C6.53051 47.2869 6.12391 46.3666 6.02409 45.3896C5.92426 44.4126 6.13634 43.4291 6.62991 42.58L8.96991 38.58C6.65145 34.3531 5.78562 29.4816 6.50556 24.7147C7.22549 19.9478 9.49132 15.5493 12.9546 12.1956C16.418 8.84187 20.887 6.71863 25.6747 6.15233C30.4623 5.58604 35.3034 6.60805 39.4536 9.06121C43.6037 11.5144 46.8332 15.2629 48.6454 19.7303C50.4576 24.1977 50.7522 29.1367 49.4839 33.7879C48.2155 38.4391 45.4545 42.5448 41.6254 45.4739C37.7962 48.403 33.1109 49.9932 28.2899 50ZM17.4799 42.81C17.8631 42.811 18.238 42.9221 18.5599 43.13C22.2995 45.5441 26.7982 46.4939 31.1945 45.7975C35.5908 45.1011 39.5757 42.8075 42.3861 39.3558C45.1966 35.9041 46.6349 31.537 46.4258 27.0908C46.2167 22.6446 44.3748 18.4318 41.2529 15.2591C38.1309 12.0864 33.9483 10.1769 29.5061 9.89616C25.0638 9.61544 20.6741 10.9833 17.1776 13.7377C13.6811 16.4921 11.3235 20.4396 10.5563 24.8241C9.78918 29.2086 10.6664 33.722 13.0199 37.5C13.2068 37.8111 13.3055 38.1671 13.3055 38.53C13.3055 38.8929 13.2068 39.2489 13.0199 39.56L10.0899 44.56C10.0202 44.6791 9.99023 44.8174 10.0043 44.9547C10.0184 45.0919 10.0759 45.2212 10.1683 45.3237C10.2608 45.4262 10.3834 45.4966 10.5186 45.5247C10.6537 45.5528 10.7943 45.5371 10.9199 45.48L16.6699 43C16.9236 42.8806 17.1996 42.8159 17.4799 42.81Z" fill="rgb(var(--main))"/>
              <path d="M28.2898 30C27.7594 30 27.2507 29.7893 26.8756 29.4142C26.5005 29.0391 26.2898 28.5304 26.2898 28C26.2898 27.4696 26.5005 26.9609 26.8756 26.5858C27.2507 26.2107 27.7594 26 28.2898 26C28.8202 26 29.3289 26.2107 29.704 26.5858C30.0791 26.9609 30.2898 27.4696 30.2898 28C30.2898 28.5304 30.0791 29.0391 29.704 29.4142C29.3289 29.7893 28.8202 30 28.2898 30Z" fill="rgb(var(--main))"/>
              <path d="M20.2898 30C19.7594 30 19.2507 29.7893 18.8756 29.4142C18.5005 29.0391 18.2898 28.5304 18.2898 28C18.2898 27.4696 18.5005 26.9609 18.8756 26.5858C19.2507 26.2107 19.7594 26 20.2898 26C20.8202 26 21.3289 26.2107 21.704 26.5858C22.0791 26.9609 22.2898 27.4696 22.2898 28C22.2898 28.5304 22.0791 29.0391 21.704 29.4142C21.3289 29.7893 20.8202 30 20.2898 30Z" fill="rgb(var(--main))"/>
              <path d="M36.2898 30C35.7594 30 35.2507 29.7893 34.8756 29.4142C34.5005 29.0391 34.2898 28.5304 34.2898 28C34.2898 27.4696 34.5005 26.9609 34.8756 26.5858C35.2507 26.2107 35.7594 26 36.2898 26C36.8202 26 37.3289 26.2107 37.704 26.5858C38.0791 26.9609 38.2898 27.4696 38.2898 28C38.2898 28.5304 38.0791 29.0391 37.704 29.4142C37.3289 29.7893 36.8202 30 36.2898 30Z" fill="rgb(var(--main))"/>
            </svg>
            <!-- <p class="uppercase t-semi p-small w-100">{{$t('navigation.community')}}</p> -->
          </router-link>
            <!-- Booking -->
           <!-- <router-link class="no-hover-color t-center  t-black t-medium" to="/account">
            <svg class="mn-r-auto mn-l-auto i-big mn-b-thin"  width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 50.11C16.4087 50.11 14.8826 49.4779 13.7574 48.3526C12.6321 47.2274 12 45.7013 12 44.11V12C12 10.4087 12.6321 8.88258 13.7574 7.75736C14.8826 6.63214 16.4087 6 18 6H39C40.5913 6 42.1174 6.63214 43.2426 7.75736C44.3679 8.88258 45 10.4087 45 12V43.77C45.0008 44.8964 44.6846 46.0004 44.0874 46.9555C43.4902 47.9106 42.6362 48.6783 41.6231 49.1707C40.61 49.6632 39.4787 49.8605 38.3588 49.7401C37.2388 49.6198 36.1753 49.1865 35.29 48.49L30.15 44.49C29.802 44.2256 29.377 44.0825 28.94 44.0825C28.503 44.0825 28.078 44.2256 27.73 44.49L21.54 49.03C20.5033 49.754 19.2643 50.132 18 50.11ZM18 10C17.4696 10 16.9609 10.2107 16.5858 10.5858C16.2107 10.9609 16 11.4696 16 12V44.11C16.0009 44.4789 16.1038 44.8404 16.2973 45.1544C16.4909 45.4685 16.7675 45.7229 17.0966 45.8895C17.4257 46.0562 17.7945 46.1285 18.1622 46.0986C18.5299 46.0687 18.8821 45.9377 19.18 45.72L25.37 41.18C26.4261 40.4063 27.7062 39.9993 29.0152 40.0209C30.3241 40.0426 31.5901 40.4918 32.62 41.3L37.76 45.3C38.0522 45.531 38.403 45.6758 38.7731 45.7181C39.1431 45.7605 39.5176 45.6986 39.8544 45.5396C40.1912 45.3806 40.4769 45.1306 40.6793 44.818C40.8817 44.5053 40.9928 44.1424 41 43.77V12C41 11.4696 40.7893 10.9609 40.4142 10.5858C40.0391 10.2107 39.5304 10 39 10H18Z" fill="rgb(var(--main))"/>
            </svg> -->

            <!-- <p class="uppercase t-semi p-small w-100">{{$t('navigation.booking')}}</p> -->
          <!-- </router-link> -->

        </nav>
    </footer>
</template>

<script setup>
/////////////////////////////
// COMPONENT DEPENDENCIES
/////////////////////////////
import { ref } from 'vue';
// import { useRoute } from 'vue-router';

/////////////////////////////
// CREATED
/////////////////////////////
// const route = useRoute();
const navRef = ref(null);

// const isActiveRoute = computed(() => {
//     return route.path === '/account' || route.path === '/auth/signin';
// });
</script>
