/////////////////////////////
// DEPENDENCIES
/////////////////////////////
import { reactive, watch, computed  } from "vue";
// Dependencies
import Cookies from 'js-cookie'
// Globals 
import { setError }   from '@pf/src/modules/globals/views/store/globals'

/////////////////////////////
// State
/////////////////////////////
const state = reactive({
  organization: null,
  positions: [],
  currentState: false
});

/////////////////////////////
// Actions
/////////////////////////////
const actions = {

  toggleShopcart () {
    state.currentState = state.currentState ? false : true;
  },

  setShopcart() {
    try {
      const storedShopcart = localStorage.getItem('shopcart');

      if (storedShopcart) {
        const shopcartData = JSON.parse(storedShopcart);
        state.positions = shopcartData.positions; // Восстанавливаем продукты
        state.organization = shopcartData.organization; // Восстанавливаем _id организации
      } 
    } catch (error) {
      console.error(error);
      throw error;
    }
  },


  addProductToCart(product, organization) {
    const cartItem = state.positions.find(
      (item) => item._id === product._id
    );

    if (cartItem) {
      cartItem.quantity++;
    } else {
      state.positions.push({
        _id: product._id,
        images: product.images,
        name: product.name,
        price: product.price,
        quantity: 1,
        org_id: organization, // Добавляем _id организации к каждому продукту
      });
    }

    // Обновляем объект корзины в localStorage, включая _id организации
    localStorage.setItem('shopcart', JSON.stringify({
      positions: state.positions,
      organization: organization // Сохраняем _id организации в корне объекта корзины
    }));
  },


  removeProduct(_id) {
    const cartItem = state.positions.find(
      (item) => item._id === _id
    );
    const cartItemIndex = state.positions.indexOf(cartItem);

    if (cartItemIndex > -1) {
      state.positions.splice(cartItemIndex, 1);
      
      // Сохраняем весь объект корзины, а не только позиции
      localStorage.setItem('shopcart', JSON.stringify({
        positions: state.positions,
        organization: state.organization
      }));
    }
  },


  decrementItemQuantity(_id) {
    const cartItem = state.positions.find(
      (item) => item._id === _id
    );
    const cartItemIndex = state.positions.indexOf(cartItem);
    
    if (cartItemIndex > -1) {
      cartItem.quantity--;
      
      if (cartItem.quantity < 1) {
        state.positions.splice(cartItemIndex, 1);
      }

      // Сохраняем весь объект корзины, а не только позиции
      localStorage.setItem('shopcart', JSON.stringify({
        positions: state.positions,
        organization: state.organization
      }));
    }
  },


  incrementItemQuantity(_id) {
    const cartItem = state.positions.find(
      (item) => item._id === _id
    );

    if (cartItem) {
      cartItem.quantity++;

      // Сохраняем весь объект корзины, а не только позиции
      localStorage.setItem('shopcart', JSON.stringify({
        positions: state.positions,
        organization: state.organization
      }));
    }
  },


  resetShopcart() {
    state.positions = [];
    
    localStorage.removeItem('shopcart');
  },
};

/////////////////////////////
// Getters
/////////////////////////////
const getters = {
  cartTotalPrice: computed(() => {
    return Number(state.positions.reduce((total, product) => {
      return total + product.price * product.quantity;
    }, 0));
  }),

  cartTotalAmount: computed(() => {
    return Number(state.positions.reduce((total, product) => {
      return total + product.quantity;
    }, 0));
  }),

  getStoreCartState: computed(() => {
    return Number(state.currentState);
  }),
};
/////////////////////////////
// History
/////////////////////////////
const history = []
history.push(state) 

/////////////////////////////
// Watch
/////////////////////////////
watch(state, (newState, oldState) => {
  history.push(newState)
})

/////////////////////////////
// Module Export
/////////////////////////////
export {
  state,
  actions,
  getters
}