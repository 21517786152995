// Dependencies
import { reactive, ref, computed, toRefs, watch  } from "vue";
// Setup Axios
import $axios from '@pf/src/modules/globals/views/utils/axios-instance.js';
// Globals
import { setError }   from '@pf/src/modules/globals/views/store/globals'
// Init State
import orderInitState from "@pf/src/modules/orders/store/models/order"


const state = reactive({
  all: [], 
  current: {
    ...orderInitState
  }
});

const actions = {
  async create(order) {
    try {
      const response = await $axios.post('/api/orders/create', order);

      state.all.push(response.data);

      return response.data;
    } catch (error) {
      setError(error);
    }
  },

   async read( options = {}) {
    try {
     
      const response = await $axios.get('/api/orders/read', { params: options });

      if (options._id) {
        set(response.data[0], 'current')
      } else {
        set(response.data, 'all')
      }

      return response.data; 
    } catch (err) {
      setError(err)
      throw err;
    }
  },

  async update(order) {
    try {
      const response = await $axios.post(`/api/orders/update`, order);

      const index = state.all.findIndex(o => o._id === order._id);
      
      if (index !== -1) {
        state.all[index] = response.data;
      }

      return response.data; 
    } catch (error) {
      setError(error);
    }
  },

  async delete(orderID) {
    try {
      await $axios.delete(`/api/orders/${orderID}`);
      state.all = state.all.filter(o => o._id !== orderID);
    } catch (error) {
      setError(error);
    }
  },

  async changePaymentStatus(orderID, status) {
    try {
      const response = await $axios.post(`/api/orders/payment/${orderID}`, { status });
      const index = state.all.findIndex(o => o._id === orderID);
      if (index !== -1) {
        state.all[index] = response.data;
      }
    } catch (error) {
      setError(error);
    }
  },
}

const mutations = {  
  resetCustomer(stateObject) {  
    Object.assign(stateObject, customerInitState);
  },  
  resetOrder(stateObject) {
    state.current = { ...orderInitState }
    // Object.assign(stateObject, orderInitState);
  }  
}

const getters = {
  getTotal(positions) {
    return positions.reduce((total, product) => { 
      return total + product.price * product.quantity;
    }, 0)
  },
}

function set(eventData, property) { 
  state[property] = eventData
}

const history = []

history.push(state) 

watch(state, (newState, oldState) => {
  history.push(newState)
})

export {
  state,
  actions,
  mutations,
  getters
}