<template>
  <div>
    <div 
      v-once
      ref="editableDiv"
      :contenteditable="true"
      @input="handleInput"
      @keydown="handleKeyDown"
      @mouseup="checkTextSelection"
      tabindex="0"
      v-html="props.prop[props.content]"
      class="editable-div mn-b-thin"
      :class="{
        't-main t-semi': prop.type === 'Link',
        'h3': prop.type === 'H2'
      }"
    />
    <div v-if="isTextSelected" class="formatting-bar">
      <button @click="applyFormat('bold')">B</button>
      <button @click="applyFormat('italic')">I</button>
      <button @click="createLink">Link</button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, nextTick, onUpdated, watchEffect, watch } from 'vue'

import DOMPurify from 'isomorphic-dompurify';

const sanitizeConfig = {
  ALLOWED_TAGS: ['b', 'i', 'a', 'img'],
  ALLOWED_ATTR: ['href', 'target', 'src']
};

const props = defineProps({
  prop: Object,
  content: String,
  placeholder: String,
  index: Number,
  setFocus: Boolean
})

const emit = defineEmits(['deleteBlock', 'updateBlock', 'addBlock', 'focus'])

const editableDiv = ref(null)
const isTextSelected = ref(false)
const savedSelection = ref(null)

function handleInput(event) {
  const sanitizedContent = sanitizeHTML(event.target.innerHTML)
  emit('updateBlock', props.prop, { content: sanitizedContent })
}

function handleKeyDown(event) {
  if (event.key === 'Enter' && !event.shiftKey) {
    event.preventDefault()
    const selection = window.getSelection()
    const range = selection.getRangeAt(0)
    const newBlockContent = extractContentAfterCursor(range)
    
    // Update current block content
    const sanitizedContent = sanitizeHTML(editableDiv.value.innerHTML)
    emit('updateBlock', props.prop, { content: sanitizedContent })
    
    // Create new block
    emit('addBlock', 'Textarea', sanitizeHTML(newBlockContent), props.index)
  } else if (event.key === 'Backspace' && editableDiv.value.innerHTML.trim() === "") {
    event.preventDefault()
    emit('deleteBlock', props.prop, { setCaretToEnd: true })
  }
}

function extractContentAfterCursor(range) {
  const container = range.startContainer
  const cursorOffset = range.startOffset

  // Если курсор находится в текстовом узле
  if (container.nodeType === Node.TEXT_NODE) {
    const textAfterCursor = container.textContent.slice(cursorOffset)
    container.textContent = container.textContent.slice(0, cursorOffset)
    return textAfterCursor
  } 
  // Если курсор находится между элементами
  else if (container.nodeType === Node.ELEMENT_NODE) {
    const tempDiv = document.createElement('div')
    const childNodes = Array.from(container.childNodes)
    let foundCursor = false
    
    childNodes.forEach((node, index) => {
      if (index === cursorOffset) {
        foundCursor = true
      }
      if (foundCursor) {
        tempDiv.appendChild(node.cloneNode(true))
      }
    })
    
    // Удаляем перенесенные узлы из исходного контейнера
    while (container.childNodes[cursorOffset]) {
      container.removeChild(container.childNodes[cursorOffset])
    }
    
    return tempDiv.innerHTML
  }
  
  return ''
}

function focus(options = {}) {
  nextTick(() => {
    editableDiv.value.focus()
    
    let shouldSetCaretToEnd = options.setCaretToEnd || props.prop.setCaretToEnd

    if (props.prop.setFocus === true && !shouldSetCaretToEnd) {
      // Для нового блока ставим курсор в начало
      const range = document.createRange()
      range.setStart(editableDiv.value, 0)
      range.collapse(true)
      const selection = window.getSelection()
      selection.removeAllRanges()
      selection.addRange(range)
    } else {
      // Для существующего блока или после удаления ставим курсор в конец
      setCaretToEnd(editableDiv.value)
    }

    // Сбрасываем флаги
    emit('updateBlock', props.prop, { setFocus: false, setCaretToEnd: false })
  })
}
onMounted(() => {
  if (props.setFocus) {
    nextTick(() => { 
      focus() 
    })
  }
})

onUpdated(() => {
  if (props.setFocus) {
    nextTick(() => { 
      focus() 
    })
  }
})

function sanitizeHTML(html) {
  const sanitize = DOMPurify.sanitize(html, sanitizeConfig);
  return sanitize;
}


function setCaretToEnd(element) {
  const range = document.createRange()
  const selection = window.getSelection()
  range.selectNodeContents(element)
  range.collapse(false)
  selection.removeAllRanges()
  selection.addRange(range)
}

function checkTextSelection() {
  isTextSelected.value = window.getSelection().toString().length > 0
}

function applyFormat(format) {
  document.execCommand(format, false, null)
}

function createLink() {
  const url = prompt('Enter the URL:')
  if (url) {
    document.execCommand('createLink', false, url)
  }
}
</script>


<style lang="scss">
.editable-div {
  width: 100%;
  min-height: 1rem;
  background-color: transparent;
  outline: none;
  white-space: pre-wrap;
  word-wrap: break-word;
  
  &:empty::before {
    content: attr(placeholder);
    color: #999;
  }
  
  &:focus {
    border: 0;
  }
}

.formatting-bar {
  display: flex;
  gap: 5px;
  margin-top: 5px;
  
  button {
    padding: 2px 5px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 3px;
    cursor: pointer;
    
    &:hover {
      background-color: #e0e0e0;
    }
  }
}
</style>