<template>
  <div 
    class="mn-b-thin w-100 pd-medium bg-white radius-medium">
    
    <div 
      v-if="prop.content" 
      v-html="prop.content" 
      class="flex-center flex"
    />
    
    <div
      class="" 
      v-else
    >
      <Field 
        v-model:field="embedCode"
        type="textarea"   
        :placeholder="'Paste your embed code here'" 
        class="
          w-100
          br-solid
          br-1px
          br-black-transp-10
          pd-medium
          radius-small
          mn-b-thin
        "
      />
      <div class="flex-v-center flex-nowrap flex gap-small">
        
          <p class="t-center">Embeds from youtube.com, vimeo.com, soundcloud.com are allowed</p>
          <button 
            @click="onComponentClick"
            class="button-small button"
          >
            Save
        </button>
      </div>
    </div>
    <!-- <button v-if="prop.content" @click="editEmbedCode">Edit Embed Code</button> -->
  </div>
</template>

<script setup>
import { ref } from 'vue';

import Field         from '@pf/src/components/Field/Field.vue'

const embedCode = ref(null);

const props = defineProps([
  'label',
  'value',
  'prop'
]);

const emit = defineEmits(['input', 'updateBlock']);

function isValidEmbedCode(code) {
  const allowedSources = [
    /youtube\.com/,
    /facebook\.com/,
    /instagram\.com/,
    /twitter\.com/,
    /tiktok\.com/,
    /soundcloud\.com/,
    /vimeo\.com/,
    /reddit\.com/,
    /linkedin\.com/,
    /medium\.com/,
    /spotify\.com/,
  ];
  return allowedSources.some(pattern => pattern.test(code));
}

function onComponentClick() {

  if (embedCode.value && isValidEmbedCode(embedCode.value)) {
    emit('input', embedCode.value);
    emit('updateBlock', props.prop, { content: embedCode.value });
  } else {
    alert('Invalid embed code or source not allowed.');
  }
}

function editEmbedCode() {
  let embedInput = prompt('Edit embed code:', prop.content);
  if (embedInput && isValidEmbedCode(embedInput)) {
    embedCode.value = embedInput;
    emit('input', embedCode.value);
    emit('updateBlock', props.prop, { content: embedCode.value });
  } else {
    alert('Invalid embed code or source not allowed.');
  }
}
</script>

