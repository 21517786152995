<template>
  <div class="pd-thin"> 
    <SectionPageTitle
      title="Orders"
      @update:tabs_current="(update) => tab = update"
      :tabs_current="tab"
      :tabs="[
        { name: 'All',        value: 'all' },
        { name: 'Created',   value: 'created' },
        { name: 'Confirmed',  value: 'confirmed' },
        { name: 'Preparing',  value: 'preparing' },
        { name: 'Prepared',  value: 'prepared' },
        { name: 'In delivery',  value: 'in delivery' },
        { name: 'Delivered',  value: 'delivered' },
        { name: 'Closed',  value: 'closed' }
      ]"
      :actions="[
        { to: { name: 'AdminOrderAdd'}, label: 'Create Order' }
      ]"
      class="mn-b-small bg-light bg-light radius-big"
    />

    <Block
      title="Balance"
      class="mn-b-thin"
    >
      <div class="gap-micro flex-nowrap flex">
        <div class="w-100 bg-white radius-small pd-small">
          <p class="mn-b-thin">Total</p>
          <h3>{{formatPrice(totalPrice)}}</h3>
        </div>
        <div class="w-100 bg-white radius-small pd-small">
          <p class="mn-b-thin">Paid</p>
          <h3>{{formatPrice(totalPriceUnpaid)}}</h3>
        </div>
        <div class="w-100 bg-white radius-small pd-small">
          <p class="mn-b-thin">Unpaid</p>
          <h3>{{formatPrice(totalPrice)}}</h3>
        </div>
      </div>

    </Block>

    <Feed
      :search="true"
      :states="{
        empty: {
          title: 'No Orders Found',
          description: 'Currently, there are no orders.'
        }
      }"
      :store="{
        read: (options) => orders.actions.read(options)
      }"
      :options="{
        limit: 15,
        owner: route.params._id,
        ...(tab !== 'all' && { status: tab })
      }"
      v-slot="{ 
        items 
      }"
      class="gap-thin cols-3"
    >
      <router-link 
        v-for="order in items" 
        :to="{ 
          name: 'Order Edit', 
          params: { 
            order: order._id 

          }
        }" 
         class="bg-light pos-relative pd-medium radius-big"
      >
        <CardHeader 
          :entity="order"
          :entityType="'order'"
          :user="auth.state.user"
          :owner="order.creator" 
          :creator="order.creator"
          :date="order.createdAt"
          class="mn-b-small"
        />

        <div class="mn-b-small pd-small bg-white radius-small flex-nowrap flex"> 
          <div class="w-100">
            <h3 class="mn-b-thin">{{order.customer.target.profile?.name || order.customer.target.phone || order.customer.target.email || 'No name'}}</h3>
            <p class="t-truncate">{{order.delivery.address || 'Not specified'}}</p> 
          </div>

          <div class=" w-100 t-right">
            <h3 class="mn-b-thin">{{returnCurrency()}}{{orders.getters.getTotal(order.positions)}}</h3>
            <p>#{{order._id.slice(0, 4) + '...' + order._id.slice(-4)}}</p> 
          </div>
        </div>
     
        <div class="cols-1 mn-b-small gap-thin pd-small bg-white radius-small">
          <!-- Always display the first two positions -->
          <div
            v-for="(position, index) in order.positions.slice(0, 2)"
            :key="index"
            class="w-100 flex"
          >
            <p class="mn-r-auto">{{ position.name }}</p>
            <p class="t-right">
              {{ position.quantity }} {{ position.type }}
              x
              {{ position.price }}
              <span class="t-transp">{{ returnCurrency() }}</span>
            </p>
          </div>

          <transition name="fade">
            <!-- Display the rest of the positions if there are more than two and spoiler is true -->
            <div v-if="spoiler && order.positions.length > 2">
              <div
                v-for="(position, index) in order.positions.slice(2)"
                :key="index + 2"
                class="w-100 flex"
              >
                <p class="mn-r-auto">{{ position.name }}</p>
                <p class="t-right">
                  {{ position.quantity }} {{ position.type }}
                  x
                  {{ position.price }}
                  <span class="t-transp">{{ returnCurrency() }}</span>
                </p>
              </div>
            </div>
          </transition>

          <!-- Toggle button for additional positions, visible only if there are more than two positions -->
          <button
            v-if="order.positions.length > 2"
            @click.prevent="spoiler = !spoiler"
            class="radius-big bg-light-transp-50 pd-nano w-100 flex-center flex"
          >
            {{ !spoiler ? `+${order.positions.length - 2} more` : `Hide` }} 
          </button>
        </div>


        <div class="pd-small bg-white radius-small gap-micro flex-v-center flex-nowrap flex"> 
          <IconTime class="i-semi t-transp" fill="rgb(var(--black)" />
          <p class="t-medium mn-r-auto">{{formatDate(order.deadline)}}</p>

          <span 
            class="flex-child flex-child-shrink-0 capitalize w-max t-medium radius-big pd-b-nano pd-t-nano pd-r-thin pd-l-thin bg-main"
          >
            {{order.status}}
          </span>

          <span 
            class="flex-child flex-child-shrink-0 capitalize w-max t-medium radius-big pd-b-nano pd-t-nano pd-r-thin pd-l-thin t-white bg-red"
          >
            Unpaid
          </span>
        </div>
      

        <div v-if="order.comment" class="pos-relative radius-small bg-fifth-transp-10 mn-t-thin pd-small">
          <p class="mn-b-thin t-transp uppercase p-small t-medium">Comment</p>
          <p>{{order.comment}}</p>
        </div>
      </router-link>
    </Feed>
  </div>
</template>

<script setup>
  import { computed, onMounted, reactive, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';

  import Tab      from '@pf/src/components/Tab/Tab.vue'
  import Block from '@pf/src/components/Block/Block.vue';

  import * as orders from '@pf/src/modules/orders/store/orders';
  import * as auth from '@pf/src/modules/auth/views/store/auth';

  import CardOrder from '@pf/src/modules/orders/components/blocks/CardOrder.vue'
  import CardHeader  from '@pf/src/modules/globals/views/components/blocks/CardHeader.vue'

  import SectionPageTitle from '@pf/src/modules/globals/views/components/sections/SectionPageTitle.vue'

  import Feed from '@pf/src/components/Feed/Feed.vue'

  import IconTime  from '@pf/src/modules/icons/entities/IconTime.vue'

  const route = useRoute();
  const router = useRouter();

  const spoiler = ref(false)

  // Tab logic
  const tab = ref('all')

  const totalPrice = computed(() => {
    return calculateTotalSum(orders.state.all)
  });

   const totalPriceUnpaid = computed(() => {
    return calculateSum(orders.state.all,'unpaid')
  });

  
  function calculateTotalSum(orders) {
    return orders.reduce((totalSum, item) => {
      return totalSum + item.positions.reduce((sum, position) => sum + position.price, 0);
    }, 0);
  }

  function calculateSum(orders, status) {
    return orders.reduce((totalSum, item) => {
      if (item.payment.status === status) {
        return totalSum + item.positions.reduce((sum, position) => sum + position.price, 0);
      }
      return totalSum;
    }, 0);
  }


  onMounted(async () => {
   
  });
</script>
