<script setup>
import { ref } from 'vue';

import IconSearch from '@pf/src/modules/icons/navigation/IconSearch.vue';
import Field from '@pf/src/components/Field/Field.vue';

const emits = defineEmits(['search']);
const props = defineProps({
  placeholder: {
    type: String,
    default: 'Enter shop or product name'
  },
  button: {
    type: String,
    default: 'Search'
  },
});

const stateSearch = ref(null)

const updateSearch = (search) => {
  emits('search', search);
};
</script>

<template>
  <div class="flex-v-center flex-nowrap flex pd-small bg-light w-100 radius-big">
    <IconSearch class="i-medium t-transp mn-r-thin" />

    <Field
      :placeholder="placeholder"
      @update:field="search => updateSearch(search)"
      class="w-100"
    />

    <!-- <button 
      class="pd-r-small pd-l-small pd-t-thin pd-b-thin p-medium uppercase p-small t-medium cursor-pointer radius-extra bg-main" 
      @click="updateSearch(stateSearch)"
    >
      {{ button }}
    </button> -->
  </div>
</template>
