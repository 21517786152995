import layoutBackoffice from '../components/layouts/Backoffice.vue'
import Sidebar from '../components/partials/Sidebar.vue'

import layoutEmpty from '@pf/src/modules/globals/views/components/layouts/Empty.vue';

import * as validationAuth from '@pf/src/modules/auth/views/middlewares/auth.validation';

const backofficeRoutes = [{
  path: 'backoffice',
  name: 'Backoffice Root',

  component: layoutBackoffice,

  meta: {
    sidebar: Sidebar,
  },
  
  props: {
    newsletterPopup: 12344
  },

  beforeEnter: [
    validationAuth.requiresAdmin,
  ],
  children: [{
    path: '',
    name: 'Backoffice Admin',
    meta: {
      title: {
        en: 'Backoffice',
        ru: 'Управление'
      },
    },
    component: () => import(/* webpackChunkName: 'BackofficeGallery' */ '../components/pages/Dashboard.vue'),
  },{
    path: 'reports',
    name: 'Backoffice Reports',
    meta: {
      title: {
        en: 'Backoffice Reports',
        ru: 'Управление Жалобами'
      },
    },
    component: () => import(/* webpackChunkName: 'BackofficeGallery' */ '@pf/src/modules/reports/components/pages/BackofficeReports.vue'),
  },
  {
    path: 'organizations',
    name: 'Backoffice Organizations',
    meta: {
      title: {
        en: 'Backoffice Organizations',
        ru: 'Управление Организациями'
      },
      authorize: []
    },
    component: () => import(/* webpackChunkName: 'BackofficeOrganizations' */ '@pf/src/modules/organizations/components/pages/Organizations.vue'),
  },{
    path: 'events',
    name: 'Backoffice Events',
    meta: {
      title: {
        en: 'Backoffice Events',
        ru: 'Управление Событиями'
      },
      authorize: []
    },
    component: () => import(/* webpackChunkName: 'BackofficeEvents' */ '@pf/src/modules/events/components/pages/Events.vue'),
  },{
    path: 'community',
    name: 'Backoffice Community',
    meta: {
      title: {
        en: 'Backoffice Community',
        ru: 'Управление Сообществом'
      },
      authorize: []
    },
    component: () => import(/* webpackChunkName: 'BackofficeCommunity' */ '@pf/src/modules/community/components/pages/Community.vue'),
  }]
}];

export default backofficeRoutes;
