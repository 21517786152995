// Components
// import Client from "./components/layouts/Client.vue"

import getBrowserLocale from './views/localization/get-browser-locale.js';
import scrollBehavior from './views/router/scrollBehavior.js';

import layoutApp from './views/components/layouts/App.vue'
import layoutClient from './views/components/layouts/Client.vue'
import Header from '@pf/src/modules/globals/views/components/partials/Header.vue'
import Sidebar from '@pf/src/modules/globals/views/components/partials/Sidebar.vue'
import BottomNavigationBar from '@pf/src/modules/globals/views/components/partials/BottomNavigationBar.vue'

import * as mixins from './views/mixins/mixins.js'
import * as appRenderer from './views/utils/vue-app-renderer.js';
import * as storeGlobals from './views/store/globals.js';

export {
  // Client,
  mixins,
  scrollBehavior,
  getBrowserLocale,
  appRenderer,
  // Store
  storeGlobals,
  // Components
  layoutClient,
  layoutApp,
  Header,
  Sidebar
};

// Пример функции инициализации для модуля заказов
function initializeGlobals(app, store, router, options = {}) {
  const route = options.route || 'Home';

  const envVariables = [
    'NODE_ENV',
    'PORT',
    'APP_NAME',
    'DOMAIN_URL',
    'API_URL',
    'WSS_URL',
    'FILE_SERVER_URL',
    'WDT_TOKEN',
    'WDM_URL_PROD',
    'GOOGLE_MAPS_API_KEY',
    'MOBILE_APP'
  ];

  app.config.globalProperties.DOMAIN_URL = process.env.DOMAIN_URL;
  app.config.globalProperties.API_URL = process.env.API_URL;
  app.config.globalProperties.MOBILE_APP = process.env.MOBILE_APP;
  app.config.globalProperties.FILE_SERVER_URL = process.env.FILE_SERVER_URL;
  app.config.globalProperties.GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY;

  app
    .mixin(mixins.globalMixins);
  
  // Change Locale to Route Locale if available
  router.beforeEach((to, from, next) => {
    const locale = to.params.locale;

    if (locale) {
      if (!app.config.globalProperties.$i18n.availableLocales.includes(locale)) {
        return next({ path: '/404' });
      } else {
       app.config.globalProperties.$i18n.locale = locale;
       return next();
      }
    }

    return next();
    
  });

  store.addStore('globals', storeGlobals);

  router.addRoute(route, { path: '404', name: 'notfound', component: () => import('@pf/src/modules/globals/views/components/pages/404.vue') });
  router.addRoute(route, { path: '401', name: 'unauthorized', component: () => import('@pf/src/modules/globals/views/components/pages/401.vue') });
  router.addRoute(route, { path: ':pathMatch(.*)*', name: 'NotFound', redirect: { name: 'notfound' } });
}

const ModuleGlobals = {
  initialize: initializeGlobals,
  views: {
    store: {
      storeGlobals,
    },
    router: {
      // routerOrders
    },
    components: {
      // Pages
      layoutClient,
      layoutApp,
      Header,
      Sidebar,
      BottomNavigationBar
    }
  }
}

export default ModuleGlobals
