<template>
  <div>
    <header class="pd-medium flex-v-center flex-nowrap flex">
      <h1 class="mn-r-auto">{{ title }}</h1>

      <template  
        v-for="action in actions"   
        v-if="actions && actions.length > 0"
      >
        <router-link
          v-if="action.to"
          :key="action.to"
          :to="action.to"
          :class="action.class || 'radius-extra pd-thin uppercase t-medium hover-scale-1 transition-ease cursor-pointer t-white bg-second flex-center flex'"
        >
          {{ action.label }}
        </router-link>

        <button
          v-if="action.method"
          @click="action.method"
          :class="action.class || 'radius-extra pd-thin uppercase t-medium hover-scale-1 transition-ease cursor-pointer t-white bg-second flex-center flex'"
        >
          {{ action.label }}
        </button>
      </template>
    </header>

    <Tab
      v-if="tabs"
      :selected="tabs_current"
      @update:selected="updateTabsCurrent"
      :tabs="tabs"
      class="br-t br-solid br-black-transp-10 w-100 t-nowrap o-scroll h5"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue';

import Tab from '@pf/src/components/Tab/Tab.vue'

const emits = defineEmits([
  'update:tabs_current'
])

const props = defineProps({
  title: String,
  actions: Array,
  tabs: Array,
  tabs_current: [Object, String]
});

const updateTabsCurrent = (newValue) => {
  emits('update:tabs_current', newValue); // Emitting to parent component
}
</script>

<style scoped>
</style>
