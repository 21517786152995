import layoutEmpty from '@pf/src/modules/globals/views/components/layouts/Empty.vue';

const orders = {
  path: 'orders',
  component: layoutEmpty,
  name: 'Orders',
  meta: {
    title: {
      en: 'Orders',
      ru: 'Заказы'
    }
  },
  children: [{
    path: 'form',
    name: 'Form Order',
    meta: {
      title: {
        en: 'Order',
        ru: 'Заказ'
      }
    },
    component: () => import(/* webpackChunkName: 'Order' */ '@pf/src/modules/orders/components/pages/OrderCreate.vue')
  },{
    path: ':order',
    name: 'Order Status',
    meta: {
      title: {
        en: 'Order',
        ru: 'Заказ'
      }
    },
    component: () => import(/* webpackChunkName: 'Order' */ '@pf/src/modules/orders/components/pages/OrderBackoffice.vue')
  },
  {
    path: ':order/edit',
    name: 'Order Edit',
    meta: {
      title: {
        en: 'Order',
        ru: 'Заказ'
      }
    },
    component: () => import(/* webpackChunkName: 'Order' */ '@pf/src/modules/orders/components/pages/OrderBackoffice.vue')
  }]
};



export default orders;