<template>
  <div 
  	class="cols-2-1_2 gap-small pd-small bg-white"
  >

    <router-view
    	class="pd-medium br-solid br-1px br-grey-transp-25 radius-big w-m-66r t-center" 
    	v-slot="{ Component, route }"
    >
      <transition 
      	name="scaleIn" mode="out-in"
      >
        <component 
        	ref="page" 
        	:key="route.path" 
        	:localPosition="localPosition" 
        	:is="Component" 
        />
      </transition>
    </router-view>

    <section class="w-100 desktop-only o-hidden radius-big bg-grad-main">
      <!-- <SliderFeatures/> -->  
    </section>
  </div>
</template>

<script setup>
// Import components
import Tab from '@pf/src/components/Tab/Tab.vue'
import Field from '@pf/src/components/Field/Field.vue'
import Button from '@pf/src/components/Button/Button.vue'
// Import blocks
import SliderFeatures from '@pf/src/modules/auth/views/components/sections/SliderFeatures.vue'
// Import libs
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
// Import state
import * as auth from '@pf/src/modules/auth/views/store/auth'
// Import validation
import * as inputsValidation from '@pf/src/modules/auth/views/validations/inputs.validation'

const phoneValidation = ref(null)
const passswordValidation = ref(null)
const emailValidation = ref(null)
// Accessing router
const route = useRoute()
const router = useRouter()
// Accessing state
const tabAuth = ref('email')
// Methods
async function onSubmit() {
  try {
    await inputsValidation.validateInputs(
      emailValidation,
      inputsValidation.validateEmail,
      auth.state.user.email,
      'Некорректный email'
    )
    await inputsValidation.validateInputs(
      passswordValidation,
      inputsValidation.validatePassword,
      auth.state.user.password,
      'Некорректный пароль'
    )
  } catch (error) {
    throw new Error
  }
  await auth.actions.login(auth.state.user, tabAuth.value)
}

function redirectTo() {
  router.push({ name: 'Account' })
}
</script>