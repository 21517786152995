import * as validationAuth from '@pf/src/modules/auth/views/middlewares/auth.validation';

const membersRoutes = [{
  path: 'members',
  name: 'Organization Members',
  meta: {
    title: {
      en: 'Members',
      ru: 'Участники'
    }
  },
  component: () => import('@pf/src/modules/organizations/components/pages/Members.vue')
},{
  path: 'members/invite',
  name: 'Invite Member',
  component: () => import('@pf/src/modules/organizations/components/sections/MembersAdd.vue')
}];


export default membersRoutes;