<template>
  <div class="calendar">
     <div v-if="allowRange" class="br-b br-solid br-grey-transp-25 gap-thin flex flex-nowrap pd-thin">
      <button @click="selectToday" class="font-second t-nowrap pd-thin bg-main-transp-10 radius-small" >Today</button>
      <button @click="selectLastWeek" class="font-second t-nowrap pd-thin bg-main-transp-10 radius-small">Last Week</button>
      <button @click="selectLastMonth" class="font-second t-nowrap pd-thin bg-main-transp-10 radius-small">Last Month</button>
      <button @click="selectLastYear" class="font-second t-nowrap pd-thin bg-main-transp-10 radius-small">All time</button>
    </div>
    <div class="pd-thin flex flex-nowrap flex-v-center flex-justify-between">
      <button  @click.stop="prevMonth" class="aspect-ratio-1x1 w-10 pd-thin bg-main-transp-10 radius-small">&lt;</button>
      <span class="t-semi ">{{ monthYear }}</span>
      <button @click.stop="nextMonth" class="aspect-ratio-1x1 w-10 pd-thin bg-main-transp-10 radius-small" >&gt;</button>
    </div>

    <div class="pd-thin calendar__body">
      <div class="calendar__week">
        <div v-for="(day, index) in daysOfWeek" :key="index" class="calendar__weekday">
          {{ day }}
        </div>
      </div>
      <div class="gap-micro calendar__dates">
        <div
          v-for="day in daysInMonth"
          :key="day.date"
          :class="[
            'flex flex-center cursor-pointer aspect-ratio-1x1 radius-small',
            { 'bg-main t-white': day.isToday },
            { 'calendar__date--selected': isSelected(day.date) },
            { 'calendar__date--range': isInRange(day.date) },
            { 'calendar__date--disabled': !isSameMonth(day.date) },
          ]"
          @click.stop="selectDate(day.date)"
        >
          {{ day.day }}
        </div>
      </div>
    </div>
   
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'

const props = defineProps({
  modelValue: [Date, Object],
  allowRange: Boolean,
})

const emit = defineEmits(['update:modelValue'])

const dateCalendar = defineModel('date')

const today = new Date()
const currentDate = ref(today)
const selectedDate = ref(null)
const startDate = ref(null)
const endDate = ref(null)

const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

const toUTC = (date) => {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
}

const toEndOfDayUTC = (date) => {
  const endOfDay = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59));
  return endOfDay;
}

const monthYear = computed(() => {
  const month = currentDate.value.toLocaleString('default', { month: 'long', timeZone: 'UTC' })
  const year = currentDate.value.getUTCFullYear()
  return `${month} ${year}`
})

const daysInMonth = computed(() => {
  const days = []
  const firstDay = new Date(Date.UTC(
    currentDate.value.getUTCFullYear(),
    currentDate.value.getUTCMonth(),
    1
  ))
  const lastDay = new Date(Date.UTC(
    currentDate.value.getUTCFullYear(),
    currentDate.value.getUTCMonth() + 1,
    0
  ))

  const firstDayOfWeek = firstDay.getUTCDay()

  let date = new Date(firstDay)

  date.setUTCDate(date.getUTCDate() - firstDayOfWeek)

  for (let i = 0; i < firstDayOfWeek; i++) {
    days.push({
      date: new Date(date),
      day: date.getUTCDate(),
      isToday: isToday(date),
    })
    date.setUTCDate(date.getUTCDate() + 1)
  }

  let day = 1

  for (date = firstDay; date <= lastDay; date.setUTCDate(date.getUTCDate() + 1)) {
    days.push({
      date: new Date(date),
      day,
      isToday: isToday(date),
    })
    day++
  }

  const lastDayOfWeek = days[days.length - 1].date.getUTCDay()

  for (let i = lastDayOfWeek + 1; i <= 6; i++) {
    days.push({
      date: new Date(date),
      day: date.getUTCDate(),
      isToday: isToday(date),
    })
    date.setUTCDate(date.getUTCDate() + 1)
  }

  return days
})

const isToday = (date) => {
  const today = new Date()
  return (
    date.getUTCDate() === today.getUTCDate() &&
    date.getUTCMonth() === today.getUTCMonth() &&
    date.getUTCFullYear() === today.getUTCFullYear()
  )
}

const isSameMonth = (date) => {
  return (
    date.getUTCMonth() === currentDate.value.getUTCMonth() &&
    date.getUTCFullYear() === currentDate.value.getUTCFullYear()
  )
}

const isSelected = (date) => {
  if (props.allowRange) {
    if (startDate.value && endDate.value) {
      return date >= new Date(startDate.value) && toEndOfDayUTC(date) <= new Date(endDate.value);
    } else if (startDate.value) {
      return date.getTime() === new Date(startDate.value).getTime();
    }
  } else {
    return selectedDate.value && date.getTime() === new Date(selectedDate.value).getTime();
  }
}


const isInRange = (date) => {
  if (startDate.value && endDate.value) {
    return date > startDate.value && toEndOfDayUTC(date) < endDate.value
  }
  return false
}

const selectDate = (date) => {
  const formattedDate = toUTC(date)

  if (!props.allowRange) {
    selectedDate.value = formattedDate
    dateCalendar.value = formattedDate

  } else if (!startDate.value && !endDate.value) {
    startDate.value = formattedDate
    dateCalendar.value.start = formattedDate
  } else if (!endDate.value) {

    endDate.value = toEndOfDayUTC(date)

    if (formattedDate < startDate.value) {
      const temp = toEndOfDayUTC(startDate.value)

      startDate.value = formattedDate
      endDate.value = temp
    }

    dateCalendar.value = { start: startDate.value, end: endDate.value }
  } else {
    startDate.value = formattedDate
    endDate.value = null
    dateCalendar.value = {start: formattedDate, end: null }
  }
}

const prevMonth = () => {
  currentDate.value = new Date(Date.UTC(
    currentDate.value.getUTCFullYear(),
    currentDate.value.getUTCMonth() - 1,
    1
  ))
}

const nextMonth = () => {
  currentDate.value = new Date(Date.UTC(
    currentDate.value.getUTCFullYear(),
    currentDate.value.getUTCMonth() + 1,
    1
  ))
}

const selectToday = () => {
  const today = new Date();  // Предположим, что переменная today - это текущая дата
  const todayStart = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate(), 0, 0, 0, 0)); // Начало дня в UTC
  const todayEnd = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate(), 23, 59, 59, 999)); // Конец дня в UTC

  startDate.value = todayStart.toISOString();
  endDate.value = todayEnd.toISOString();
  dateCalendar.value =  { start: startDate.value, end: endDate.value };
}

const selectLastWeek = () => {
  const today = new Date();
  const lastWeek = new Date(today);
  lastWeek.setUTCDate(today.getUTCDate() - 7);
  lastWeek.setUTCHours(0, 0, 0, 0);

  startDate.value = lastWeek;
  endDate.value = toEndOfDayUTC(today);
  
  today.setUTCHours(23, 59, 59, 999);

  const start = lastWeek.toISOString();
  const end = today.toISOString();
  
  dateCalendar.value = { start: start, end: end };
}

const selectLastMonth = () => {
  const today = new Date();
  
  // Создаем дату для прошлого месяца
  const lastMonth = new Date(today);
  lastMonth.setUTCMonth(today.getUTCMonth() - 1);
  lastMonth.setUTCHours(0, 0, 0, 0);
  
  // Устанавливаем конец текущего дня до 23:59:59
  startDate.value = lastMonth;
  endDate.value = toEndOfDayUTC(today);

  today.setUTCHours(23, 59, 59, 999);
  
  // Форматируем даты в ISO строки
  const start = lastMonth.toISOString();
  const end = today.toISOString();
  
  dateCalendar.value = { start: start, end: end };
}



const selectLastYear = () => {

  startDate.value = null;
  endDate.value = null;
  dateCalendar.value =  { start: null, end: null };
}
</script>


<style >
.calendar__week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  margin-bottom: 10px;
}

.calendar__weekday {
  text-align: center;
}

.calendar__dates {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: 1fr;
}


.calendar__dates::before {
  content: '';
  width: 0;
  padding-bottom: 100%;
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}

.calendar__dates  > *:first-child {
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}


.calendar__date--today {
  background-color: #f0f0f0;
  font-weight: bold;
}

.calendar__date--selected {
  background-color: #007bff;
  color: #fff;
}

.calendar__date--range {
  background-color: #e9ecef;
}

.calendar__date--disabled {
  color: #ccc;
  cursor: not-allowed;
}
</style>