import { defineAsyncComponent } from 'vue'
import { createRouter, createMemoryHistory, createWebHistory } from 'vue-router';

import { scrollBehavior, layoutClient, Header } from '@pf/src/modules/globals/globals.client';

import * as validationAuth from '@pf/src/modules/auth/views/middlewares/auth.validation';

import Navigation from '@pf/src/modules/globals/views/components/partials/Navigation.vue'
import Sidebar from '@pf/src/modules/globals/views/components/partials/Sidebar.vue'

import Logotype from '@pf/src/modules/icons/logos/Logotype.vue'

import landing from '@pf/src/modules/landing/router/landing';
import users from '@pf/src/modules/users/router/users';
import community from '@pf/src/modules/community/router/blogposts';
import organizations from '@pf/src/modules/organizations/router/organizations';
import events from '@pf/src/modules/events/router/events';
import gallery from '@pf/src/modules/gallery/router/gallery.router';
import marketplace from '@pf/src/modules/marketplace/router/marketplace';
import legal from '@pf/src/modules/legal/router/legal';
import backoffice from '@pf/src/modules/backoffice/router/backoffice.js'

let Walkthrough;

if (__MOBILE_APP__) {
  Walkthrough = defineAsyncComponent(() =>
    import('@pf/src/modules/globals/views/components/sections/Walkthrough.vue').then(_ => _.default)
  );
} else {
  Walkthrough = null;
}

const isServer = typeof window === 'undefined';

let history = isServer ? createMemoryHistory() : createWebHistory();

const routes = [{
  path: '/:locale?',

  components: {
    default: layoutClient
  },

  // beforeEnter: [
  //   validationAuth.initUser
  // ],

  name: 'Home',

  meta: {
    navigation: Navigation,
    sidebar: Sidebar,
    header: {
      component: Header,
      logotype: Logotype,
      location: true
    },
    walkthrough: Walkthrough,

  },
  
  children: [
    ...landing,
    ...gallery,
    ...users,
    ...marketplace,
    ...community,
    ...events,
    ...organizations,
    ...backoffice,
    ...legal,
  ]
}];

export function _createRouter() {
  return createRouter({
    routes,
    history,
    scrollBehavior
  });
}