import * as validationAuth from '@pf/src/modules/auth/views/middlewares/auth.validation';

const productRoutes = [{
  path: 'leftovers',
  name: 'Leftovers',
  meta: {
    title: {
      en: 'Leftovers',
      ru: 'Остатки'
    },
    authorize: []
  },
  component: () => import(/* webpackChunkName: 'ProductsLeftovers' */ '@pf/src/modules/products/components/pages/Leftovers.vue'),
},{
  path: 'leftovers/add',
  name: 'LeftoverAdd',
  meta: {
    title: {
      en: 'Leftovers Add',
      ru: 'Управление Остатками'
    },
    authorize: []
  },
  component: () => import(/* webpackChunkName: 'LeftoverEdit' */ '@pf/src/modules/products/components/pages/EditLeftover.vue')
},{
  path: 'leftovers/:leftover',
  name: 'LeftoverEdit',
  meta: {
    title: {
      en: 'Leftovers Edit',
      ru: 'Управление Остатками'
    },
    authorize: []
  },
  component: () => import(/* webpackChunkName: 'LeftoverEdit' */ '@pf/src/modules/products/components/pages/EditLeftover.vue')
}];  

export default productRoutes;
