const landing = [
  {
    path: '',
    name: "Home Page",
    meta: {
      title_hide: true,
      header_theme: 'dark',
      breadcrumbs: {
        hide: true
      },
    },
    component: () => import(/* webpackChunkName: 'Home' */ '@pf/src/modules/landing/components/pages/Home.vue'),
  },
  {
    path: 'governance',
    name: 'Governance',
    meta: {
      title: {
        en: 'Governance',
        ru: 'Управления'
      },
      header_theme: 'dark',
    },
    component: () => import(/* webpackChunkName: 'Governance' */ '@pf/src/modules/landing/components/pages/Governance.vue'),
  },
  {
    path: 'farming',
    name: 'Farming',
    meta: {
      title: {
        en: 'Governance',
        ru: 'Управления'
      }
    },
    component: () => import(/* webpackChunkName: 'Governance' */ '@pf/src/modules/landing/components/pages/Farming.vue'),
  },
];

export default landing;