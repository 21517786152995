import * as validationOwnership from '@pf/src/modules/auth/views/middlewares/ownership.validation';

const gallery = [{
  path: 'gallery',
  name: 'Backoffice Gallery',
  meta: {
    title: {
      en: 'Backoffice Gallery',
      ru: 'Управление Галерей'
    },
  },
  beforeEnter: [
    validationOwnership.requiresAccess('gallery', 'read')
  ],
  component: () => import(/* webpackChunkName: 'BackofficeGallery' */ '@pf/src/modules/gallery/components/sections/BackofficeGallery.vue'),
}];

export default gallery;