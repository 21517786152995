<template>
	<div class="gap-thin">
		<div class="gap-thin mn-b-thin flex-nowrap flex">
			<div
				class="bg-white w-100 w-min-15r br-solid br-1px br-black-transp-5 pd-small radius-small" 
			>
				{{productSelected.name || productSelected._id.name}}
			</div>
			<!-- <SelectMulti
				v-model="productSelected"
				:options="products"
				label="name"
				:multiple="false"
				:taggable="false"
				placeholder="Select a product"
				class="bg-white w-100 w-min-15r br-solid br-1px br-black-transp-5 pd-small radius-small" 
			/> -->
			<Field 
				v-if="!showPrice"
				v-model:field="productQuantity" 
				type="number"  
				label="Qty" 
				class="bg-white w-100 w-max-10r br-solid br-1px br-black-transp-5 pd-small radius-small" 
			>
			<Select 
				v-model:select="productQuantityType" 
				:options="[
					'pcs',
					'g',
					'ml'
				]"
				class="bg-light pd-r-thin pd-l-thin pd-t-nano pd-b-nano radius-thin"
			/>

			</Field>
			<button 
				v-if="!product"
				:disabled="!isProductValid" 
				@click="addProduct" 
				class="bg-main aspect-1x1 radius-small pd-small"
			>
				<IconAdd class="i-semi"/>
			</button>

			<button 
				v-if="product"
				@click="deleteProduct" 
				class="bg-red aspect-1x1 radius-small pd-small"
			>
				<IconDelete class="i-semi"/>
			</button>
		</div>
		<div v-if="showPrice" class="gap-thin flex-nowrap flex">
			<Field 
				v-model:field="productPrice"
				type="number"   
				label="Price" 
				:symbol="returnCurrency()"
				class="bg-white w-100 br-solid br-1px br-black-transp-5  pd-small radius-small" 
			/>
			<Field 
				v-model:field="productQuantity" 
				type="number"  
				label="Qty" 
				class="bg-white w-100 br-solid br-1px br-black-transp-5 pd-small radius-small" 
			>
			<Select 
				v-model:select="productQuantityType" 
				:options="[
					'pcs',
					'g',
					'ml'
				]"
				class="bg-light pd-r-thin pd-l-thin pd-t-nano pd-b-nano radius-thin"
			/>

			</Field>
		</div>

		
		
	</div>
</template>

<script setup>
	import { ref, computed, watch } from 'vue';

	import Field from '@pf/src/components/Field/Field.vue';
  import Select from '@pf/src/components/Select/Select.vue';
  import SelectMulti from '@pf/src/components/SelectMulti/SelectMulti.vue';

  import { IconAdd, IconDelete} from '@pf/src/modules/icons/icons.client.js';

	const props = defineProps({
	  product: Object,
	  products: Array,
	  array: Array,
	  showPrice: {
	  	type: Boolean,
		  default: true
	  }
	});

	const emits = defineEmits([
		'add',
		'delete',
		'update'
	])

	const productSelected = ref(null);
	const productQuantity = ref(1);
	const productQuantityType = ref('pcs');
	const productPrice = ref(0);

	const isProductValid = computed(() => 
    productSelected.value 
    && productQuantity.value > 0 
    && productPrice.value >= 0
	);

	watch(props.array, () => {
	  productSelected.value = props.product
		productQuantity.value = props.product.quantity || 1
		productPrice.value = props.product.price
		productQuantityType.value = props.product.type
	});

	if (props.product) {
		productSelected.value = props.product
		productQuantity.value = props.product.quantity || 1
		productPrice.value = props.product.price
		productQuantityType.value = props.product.type
	}

	watch([productSelected, productQuantity, productPrice, productQuantityType], () => {
	  emits('update', {
	    ...productSelected.value,
	    price: productPrice.value,
	    quantity: productQuantity.value || 1,
	    type: productQuantityType.value
	  });
	});


	function addProduct() {
		emits('add', {
			...productSelected.value,
			price: productPrice.value,
			quantity: productQuantity.value || 1,
			type: productQuantityType.value
	  });
	}

	function deleteProduct() {
	  emits('delete', {
			...productSelected.value,
			price: productPrice.value,
			quantity: productQuantity.value || 1,
			type: productQuantityType.value
	  });
	}
</script>