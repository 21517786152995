/////////////////////////////
// DEPENDENCIES
/////////////////////////////
import { reactive, watch, computed  } from "vue";
// Globals 
import { setError }   from '@pf/src/modules/globals/views/store/globals'
// Setup Axios
import $axios from '@pf/src/modules/globals/views/utils/axios-instance.js';

const state = reactive({
  all: [],
  current: {
    order: 1,
    name: "",
    url: "",
    status: "draft",
    subcategories: [],
    filters: [],
    localization: [],
  },
});

const actions = {
  async fetchCategories(options) {
    return await $axios.get(`/categories`, { params: options }).then(
      categories => {
        state.all = categories.data;
        return Promise.resolve(categories.data);
      },
      error => {
        setError(error);
        return Promise.reject(error);
      }
    );
  },

  updateCategories(categories) {
    return $axios.post(`/categories`, categories).then(
      (categoriesUpdated) => {
        state.all = categories;
        return Promise.resolve(categories);
      },
      (error) => {
        setError(error);
        return Promise.reject(error);
      }
    );
  },

  fetchCategory(url) {
    return $axios.get("/categories/" + url).then(
      (category) => {
        state.current = category.data;
        return Promise.resolve(category.data);
      },
      (error) => {
        setError(error);
        return Promise.reject(error);
      }
    );
  },

  addCategory() {
    return $axios.post("/categories/add", state.current).then(
      (category) => {
        state.all.push(category.data);
        return Promise.resolve(category.data);
      },
      (error) => {
        setError(error);
        return Promise.reject(error);
      }
    );
  },

  updateCategory(url) {
    return $axios.post("/categories/" + url, state.current).then(
      (category) => {
        state.current = category.data;
        return Promise.resolve(category.data);
      },
      (error) => {
        setError(error);
        return Promise.reject(error);
      }
    );
  },

  resetCategory(){
    state.current = {
      order: 0,
      name: "",
      url: "",
      status: "draft",
      subcategories: [],
      filters: [],
      localization: [],
    }
  },

  deleteCategory(url) {
    return $axios.delete("/categories/" + url).then(
      (response) => {
        const index = state.all.findIndex((c) => c.url === url);
        state.all.splice(index, 1);
      },
      (error) => {
        setError(error);
      }
    );
  },
};

const history = [];
history.push(state);

watch(state, (newState) => {
  history.push(newState);
});

export { state, actions };