<template>

  <div 
    id="sidebar" class="o-hidden" v-click-outside="clickedOutside"
  >
    <div 
      class="pd-medium pos-sticky pos-t-0 h-100vh flex-column flex flex-justify-content"
    >
      <div>
        <slot></slot>
      </div>

      <Checkbox 
        :label="'Darkmode'"
        :radio="globals.state.theme.darkmode"
        @update:radio="event => globals.actions.toggleTheme()"
        name="prices"
        class=" t-semi pd-small p-big mn-b-small br-2px br-solid radius-extra br-black-transp-5"
      />

      <Menu 
        v-if="(auth.state.access.roles) && (auth.state.access.roles.includes('ROLE_MODERATOR') || auth.state.access.roles.includes('ROLE_ADMIN'))"
        class="mn-b-regular bg-black t-white"
      >
        <MenuItem 
          @click="router.push({
            name: 'Backoffice Admin', 
            params: {
              _id: route.params._id
            }
          })" 
          class="cursor-pointer"
          theme="white"
        >
          <span>
            Backoffice
          </span>
        </MenuItem> 
      </Menu>

   
    </div>
  </div>

</template>

<script setup>
import { ref, watch } from 'vue';

// Mobile Module
import Menu from '@pf/src/components/Menu/Menu.vue'
import MenuItem from '@pf/src/components/Menu/MenuItem.vue'

import clickOutside from '@pf/src/components/FieldPhone/click-outside';

import { useRoute, useRouter} from 'vue-router'

import * as auth      from '@pf/src/modules/auth/views/store/auth'
import * as globals      from '@pf/src/modules/globals/views/store/globals'

import Checkbox    from '@pf/src/components/Checkbox/Checkbox.vue'

const router  = useRouter()
const route   = useRoute()

const isOpen = ref(false);

const props = defineProps(['stateSidebar'])
const emits = defineEmits(['closeSidebar'])

watch(() => route.path, () => {
  if (props.stateSidebar === true) emits('closeSidebar');
});

let vClickOutside = clickOutside

function clickedOutside(event) {
  // Проверяем, не является ли элемент, по которому кликнули, кнопкой меню
  const isMenuButton = event.target.classList.contains('menu-btn');

  console.log(event.target.classList.contains('menu-btn'))
  
  if (isMenuButton) { 
    return null;
  } else if  (props.stateSidebar === true) {
    emits('closeSidebar');
  }
}

</script>

<style lang="scss" >
  #sidebar {
    ul {
      li {
        margin-bottom: 1rem;
      }
    }
  }
</style>