<template>

  <div v-if="search || date || (sort && !sort.hideButton)" class="flex-nowrap flex gap-thin">

    <BlockSearch 
      v-if="search"
      :placeholder="search.placeholder || 'Search...'"
      :class="search.class || 'bg-light'"
      @search="updateSearch"
    />

    <template
      v-if="filter"
    >
      <div 
        :class="filter.class || 'bg-light'"
        class="flex-center flex pd-thin bg-light radius-extra aspect-1x1 h-100"
      >
        <IconFilter @click="() => filter.active = !filter.active" class="w-1r h-auto"/>
      </div>

    
      <BottomSheet
        v-if="filter.active"
        :show="filter.active"
        @toggle="() => filter.active = false"
        class="z-index-10 pos-fixed pos-t-0 pos-r-0"
        :class="{
          'pos-t-0':  filter.active,
          'pos-t-100': !filter.active
        }"
      >
        <BlockFilter
          v-model:filter="filter"
          :options="filter.options"
          class="h-100 w-100 pd-small bg-light"
        />
      </BottomSheet>  
    </template>

    <Dropdown 
      v-if="date"
      :label="{
        component: ButtonDate,
        props: { date: date }
      }" 
      :class="date.class || 'bg-light'"
      class="flex-child flex-child-shrink-0 pd-r-small pd-l-small pd-thin bg-light radius-big"
      :align="search ? 'right' : 'left'"
    >

      <Calendar
        v-model:date="date"
        :locale="$i18n.locale"
        :allowRange="true"
        class="radius-semi w-100 o-hidden bg-light"
      />
    </Dropdown> 

    <Dropdown 
      v-if="sort && !sort.hideButton"
      :label="{
        component: ButtonSort,
        props: { order: sort.order },
        class: 'w-1r'
      }" 
      :class="sort.class || 'bg-light'"
      class="radius-extra aspect-1x1 h-100"
      :align="search ? 'right' : 'left'"
    >

      <BlockSorting
        v-model:param="sort.param"
        v-model:order="sort.order"
        :options="sort.options"
        class="h-100 pd-small radius-semi t-white bg-black"
      />
    </Dropdown> 
  </div>

  <TransitionGroup 

    v-if="isLoading"
    tag="ul" 
    name="scaleTransition" 
  >
    <SkeletonEvent
      v-if="isLoading"
      v-for="i in currentLimit" 
      :key="'skeleton-' + i"
      class="mn-b-small radius-big o-hidden d-block"
    />
  </TransitionGroup>

  <TransitionGroup 
    tag="ul" 
    v-if="!isLoading && itemsList.length < 1"
    name="feed"
  >
    <EmptyState
      v-if="!isLoading && itemsList.length < 1"
      :title="states.empty.title"
      :description="states.empty.description"
      :action="states.empty.action"
      :callback="states.empty.callback"
      class="pd-medium mn-b-thin bg-light radius-big"
    />
   </TransitionGroup>

  <TransitionGroup 
    tag="ul" 
    name="feed"
    :class="$attrs.class"
  >
    <slot
      v-if="!isLoading && itemsList.length > 0"
      :items="itemsList"
    >
    </slot>
  </TransitionGroup>

  <button
    v-if="showLoadMore && hasMoreItems && itemsList.length > 0 && !isLoadingExtra"
    @click="loadMoreItems"
    class="col-w-100 mn-t-thin w-100 t-black bg-main button"
  >
    Load More
  </button>

  <div v-else key="sentinel" ref="sentinel" class="sentinel"></div> 

  <div

    v-if="isLoadingExtra"
    class="col-w-100 w-100 pos-relative"
  >
    <Loader/>
  </div>
 

</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, shallowRef, watch } from 'vue';

import SkeletonEvent from '@pf/src/modules/icons/skeletons/SkeletonEvent.vue'

import Loader from '@pf/src/components/Loader/Loader.vue';
import EmptyState from '@pf/src/components/EmptyState/EmptyState.vue';
import Dropdown from "@pf/src/components/Dropdown/Dropdown.vue";
import Calendar from '@pf/src/components/DatePicker/Calendar.vue'
import BottomSheet from '@pf/src/components/BottomSheet/BottomSheet.vue';

import BlockSearch from '@pf/src/modules/globals/views/components/blocks/BlockSearch.vue'
import BlockSorting  from '@pf/src/modules/globals/views/components/blocks/BlockSorting.vue'
import BlockFilter  from '@pf/src/modules/globals/views/components/blocks/BlockFilter.vue'

import ButtonSort  from '@pf/src/modules/globals/views/components/elements/ButtonSort.vue'
import ButtonDate  from '@pf/src/modules/globals/views/components/elements/ButtonDate.vue'

import IconFilter from '@pf/src/modules/icons/navigation/IconFilter.vue'
import IconEvents from '@pf/src/modules/icons/entities/IconEvents.vue'

const search = defineModel('search')
const sort = defineModel('sort')
const filter = defineModel('filter')
const date = defineModel('date')
const items = defineModel('items')

// Пропсы
const props = defineProps({
  showLoadMore: {
    type: Boolean,
    default: true
  },
  search: {
    type: [Boolean, Object],
    default: false
  },
  states: {
    type: Object,
    default: () => ({
      empty: {
        title: String,
        description: String,
      },
    }),
  },
  store: {
    type: Object,
    default: () => ({
      read: Function,
      state: Object,
    }),
  },
  external: {
    type: Boolean,
    default: false
  },
  options: {
    type: Object,
    default: () => ({
      category: null,
      limit: 20, 
      skip: 0 ,
      owner:  null,
      creator:  null,
      user:  null,
      status: null,
      period:  null,
      contain:  null,
    }),
  },
});
  
const itemsList = shallowRef(items.value || []);
const hasMoreItems = ref(false);

let isLoading = ref(true);
let isLoadingExtra = ref(false);
let sentinel = ref(null)

let currentSkip = ref(props.options.skip ? props.options.skip : 0);
let currentLimit = ref(props.options.limit ? props.options.limit : 10);

// let sortParam = ref(sort.value?.param || 'createdAt');
// let sortOrder = ref(sort.value?.order || 'asc');

let currentSearch = ref('');

function debounce(fn, delay) {
  let timeoutId = null;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn(...args), delay);
  };
}

const debouncedSearch = debounce((value) => {
  currentSearch.value = value;
  currentSkip.value = 0;
  currentLimit.value = 10;
  fetchItems();
}, 300);

function updateSearch(search) {
  debouncedSearch(search);
}

const loadMoreItems = async () => {
  isLoadingExtra.value = true

  if (hasMoreItems.value) {
    currentSkip.value += currentLimit.value;
    
    const data = await props.store.read({
      skip: currentSkip.value,
      limit: currentLimit.value,
      search: currentSearch.value,
      dateStart: date.value ? date.value.start : null,
      dateEnd: date.value ? date.value.end : null,
      sortParam: sort.value ? sort.value.param : null,
      sortOrder: sort.value ? sort.value.order : null,
      ...props.options
    });

    if (data.length === 0) {
      hasMoreItems.value = false;
    } else if (data.length < currentLimit.value) {
      hasMoreItems.value = false;
    } else {
      hasMoreItems.value = true;
    }

    itemsList.value = [...itemsList.value, ...data];
    
    if (items.value) items.value = itemsList.value;
  }

  isLoadingExtra.value = false
};

const fetchItems = async () => {
  isLoading.value = true;
  const data = await props.store.read({
    skip: currentSkip.value,
    limit: currentLimit.value,
    dateStart: date.value ? date.value.start : null,
    dateEnd: date.value ? date.value.end : null,
    sortParam: sort.value ? sort.value.param : null,
    sortOrder: sort.value ? sort.value.order : null,
    search: currentSearch.value,
    ...props.options
  });

  if (data.length === 0) {
    hasMoreItems.value = false;
  } else if (data.length < currentLimit.value) {
    hasMoreItems.value = false;
  } else {
    hasMoreItems.value = true;
  }

  itemsList.value = data;
  if (items.value) items.value = itemsList.value;
  isLoading.value = false;
};

watch(
  () => [props.external, date.value, sort.value, props.options],
  ([newExternal, newDate, newSort, newOptions], [oldExternal, oldDate, oldSort, oldOptions]) => {
    if (
      newExternal !== oldExternal ||
      newDate !== oldDate ||
      newSort?.order !== oldSort?.order ||
      newSort?.param !== oldSort?.param ||
      JSON.stringify(newOptions) !== JSON.stringify(oldOptions)
    ) {
      currentSkip.value = 0;
      fetchItems();
    }
  },
  { deep: true }
);

let observer = null

onMounted(async () => {
  await fetchItems()

  if (typeof window !== 'undefined' && !props.showLoadMore) {
    observer = new IntersectionObserver(entries => {
      if (entries.some(entry => entry.isIntersecting)) {
        loadMoreItems()
      }
    })

    if (sentinel.value) observer.observe(sentinel.value)
  }
});

onUnmounted(() => {
  if (observer) {
    observer.disconnect();
  }
});
</script>


<style>
.feed-move, /* apply transition to moving elements */
.feed-enter-active,
.feed-leave-active {
  transition: all 0.5s ease;
}

.feed-enter-from,
.feed-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.feed-leave-active {
  position: absolute;
}
</style>