import { reactive, readonly } from 'vue';

const state = reactive({
  messages: [],
  currentChatId: null,
  username: null,
  websocket: null
});

const methods = {
  connectWebSocket() {
    console.log('process env')
      console.log(process.env.WSS_URL)

    return new Promise(function(resolve, reject) {

      state.websocket = new WebSocket(process.env.WSS_URL);

      state.websocket.onopen = function() {
        resolve(state.websocket);
      };
      state.websocket.onmessage = (event) => {
        const message = JSON.parse(event.data);

        if (message.chatId === state.currentChatId) {
          state.messages.push(message);
        }
      };
      state.websocket.onerror = function(err) {
        reject(err);
      };
    });
  },
  setMessages(messages) {
          console.log(process.env.API_URL)
    state.messages = messages;
  },
  addMessage(message) {
    if (state.websocket) {
      state.websocket.send(JSON.stringify(message));
    }
  },
  async setCurrentChat(chatId) {
    state.currentChatId = chatId;
    state.messages = []; // Очистка текущих сообщений при смене чата

    if (state.websocket) {
      state.websocket.send(JSON.stringify({ type: 'joinChat', chatId }));
    }

    const response = await fetch(`/messages/${chatId}`);
    const messages = await response.json();

    methods.setMessages(messages);
  },
  setUsername(username) {
    state.username = username;
  }
};

export default {
  state: readonly(state),
  methods
};
