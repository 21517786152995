import layoutEmpty from '@pf/src/modules/globals/views/components/layouts/Empty.vue';

import membersRoutes from './members.router.js';
import departmentRoutes from './departments.router.js'

import productRoutes from '@pf/src/modules/products/router/products.router.js'
import eventsRoutes from '@pf/src/modules/events/router/events';
import categoriesRoutes from '@pf/src/modules/products/router/categories.router.js'
import leftoversRoutes from '@pf/src/modules/products/router/leftovers.router.js'

import paymentsRoutes from '@pf/src/modules/wallet/views/router/payments.router.js'
import ordersBackofficeRoutes from '@pf/src/modules/orders/router/orders.backoffice.router.js'
import galleryBackofficeRoutes from '@pf/src/modules/gallery/router/gallery.backoffice.router.js'

import backofficeRoutes from '@pf/src/modules/organizations/router/backoffice.router.js'

import * as validationAuth from '@pf/src/modules/auth/views/middlewares/auth.validation';

const organizations = [{
  path: 'organizations',
  component: layoutEmpty,
  meta: {
    title: {
      en: 'Organizations',
      ru: 'Организации'
    },
    breadcrumbs: {
      hidden: true
    },
  },
  children: [{
    path: 'create',
    name: 'Create Organization',
    meta: {
      title: {
        en: 'Create Organization',
        ru: 'Создать Организацию'
      }
    },
    beforeEnter: [validationAuth.requiresAuth],
    component: () => import('@pf/src/modules/organizations/components/pages/OrganizationEdit.vue')
  },{
    path: ':_id',
    name: 'OrganizationRoot',
    meta: {
      title: {
        en: 'Group',
        ru: 'Группа'
      },
    },
    component: layoutEmpty,
    children: [
      {
        path: '',
        name: 'Organization',
        meta: {
          title: {
            en: 'Group',
            ru: 'Группа'
          },
          breadcrumbs: {
            hidden: true
          },
        },
        component: () => import('@pf/src/modules/organizations/components/pages/Organization.vue'),
      },
      ...membersRoutes,
      ...departmentRoutes,
      ...productRoutes,
      ...eventsRoutes.map(route => ({
        ...route,
        path: route.path,
        name: `Organization_${route.name}`,
        meta: {
          ...route.meta,
          organizationScoped: true
        },
        props: true
      })),
      ...categoriesRoutes,
      ...leftoversRoutes,
      ...paymentsRoutes,
      ...ordersBackofficeRoutes,
      ...galleryBackofficeRoutes,
      ...backofficeRoutes 
    ],
  },{
    path: ':_id/edit',
    name: 'Organization Edit',
    meta: {
      title: {
        en: 'Edit Organization',
        ru: 'Редактировать Организацию'
      }
    },
    beforeEnter: [validationAuth.requiresAuth],
    component: () => import('@pf/src/modules/organizations/components/pages/OrganizationEdit.vue'),
  }]
}];

export default organizations;
