import * as validationAuth from '@pf/src/modules/auth/views/middlewares/auth.validation';

const categoriesRoutes = [{
  path: 'categories',
  name: 'Categories Organization',
  meta: {
    title: {
      en: 'Category',
      ru: 'Категория'
    }
  },
  component: () => import('@pf/src/modules/products/components/pages/Categories.vue')
}, {
  path: 'categories/add',
  name: 'CategoryAdd',
  component: () => import('@pf/src/modules/products/components/pages/CategoryEdit.vue')
},{
  path: 'categories/:category/edit',
  name: 'CategoryEdit',
  beforeEnter: [validationAuth.requiresAuth],
  component: () => import('@pf/src/modules/products/components/pages/CategoryEdit.vue')
}];  

export default categoriesRoutes;
