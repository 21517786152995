<template>
  <div class="radius-big o-hidden pos-relative cols-2-1_3">
    <Map 
      :apiKey="GOOGLE_MAPS_API_KEY" 
      :location="{
        lat: spot.location.coordinates[1],
        lng: spot.location.coordinates[0]
      }" 
      :locale="$i18n.locale"
      class=""
    />

    <div class="pd-medium w-100">

        <!-- v-if="user === event.creator.target._id" -->
      <IconEdit
        @click="$router.push({
        name: 'Spot Edit',
        params: {
          _id: props.organization._id,
          spot: spot._id
        }
      })" 
        class="z-index-2 pos-absolute pos-t-regular pos-r-regular i-regular t-transp"
      />
   
    
        <p 
        @click="$router.push(`/app/organization/${props.organization._id}/spots/${spot._id}`)"
        class="pd-thin bg-white radius-small mn-b-thin p-small w-max"
      >
        {{spot.address}}
      </p>

      <h3 
        @click="$router.push(`/app/organization/${props.organization._id}/spots/${spot._id}`)"
        class="mn-b-thin w-100"
        v-html="spot.profile.name"
      />

    

      <p class="d-block p-big mn-b-semi">{{spot.profile.description}}</p>

      <h4>Delivery:</h4>
      <Chips 
        v-if="type !== 'short' && spot.delivery.length > 0" 
        :chips="spot.delivery"
      />

      <h4>Accepted Payment:</h4>
      <Chips 
        v-if="type !== 'short' && spot.payment.length > 0" 
        :chips="spot.payment"
      />



    </div>
  </div>

</template>

<script setup>
import { ref } from 'vue';

import IconEdit from '@pf/src/modules/icons/navigation/IconEdit.vue';

import Chips  from '@pf/src/components/Chips/Chips.vue';
import Map      from '@pf/src/components/Map/Map.vue';

const props = defineProps({
  spot: Object,
  organization: Object
});
</script>
<style scoped>
.subspotsartment {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
}
</style>