<script setup="props">
	import { computed, onMounted, toRefs, ref, inject } from 'vue'

	import { useRouter,useRoute } from 'vue-router'
	import { useI18n } from 'vue-i18n'
	
	import * as globals from '@pf/src/modules/globals/views/store/globals'
	import * as shopcart from '@pf/src/modules/orders/store/shopcart'
	import * as auth from '@pf/src/modules/auth/views/store/auth'
	
	// Globasls Component
	import Navigation from '@pf/src/modules/globals/views/components/partials/Navigation.vue'
	// Prometheus Component
	import Button from '@pf/src/components/Button/Button.vue'
	import Select from '@pf/src/components/Select/Select.vue'
	import Checkbox from '@pf/src/components/Checkbox/Checkbox.vue'
	// Icons module
	import IconShopcart from '@pf/src/modules/icons/entities/IconShopcart.vue'
	import IconProfile from '@pf/src/modules/icons/entities/IconProfile.vue'
	// Props
	const props = defineProps({
    theme: {
    	type: String,
	    default: "light"
    },
    logotype: {
	    type: Object
	  },
	  location: {
	    type: Boolean,
		  default: true
	  },
  });
	// Accessing state
	const router = useRouter()
	const route = useRoute()
	// Localization
	const { t } = useI18n()
	// const search = computed(() => store.products.state.search)

	function openLocationPopup() {
	  globals.state.isOpenLocationPopup = true;
	}
	/////////////////////////////
	// MOUNTED
	/////////////////////////////
	onMounted(() => {
    shopcart.actions.setShopcart()
  });
</script>

<template>
	
	<div class="flex-nowrap flex-v-center flex-justify-start flex gap-micro w-min-20 w-max-20r">
		<!-- MENU -->
		<button  
			aria-label="menu"
			@click="() => globals.state.isOpenSidebar = !globals.state.isOpenSidebar" 
			class="mobile-only menu-btn"
			:class="{
				'menu-btn_active': globals.state.isOpenSidebar
			}"
		>
	    <span
	    	class="no-events"
		    :class="{
		      'bg-black': theme === 'light',
		      'bg-white': theme === 'dark'
		    }"
		  >
		    <span class="menu-btn__before" :class="{ 'bg-black': theme === 'light', 'bg-white': theme === 'dark' }"></span>
		    <span class="menu-btn__after" :class="{ 'bg-black': theme === 'light', 'bg-white': theme === 'dark' }"></span>
		  </span>
	  </button>

		<!-- LOGO -->
		<component
			v-if="logotype"
		  :is="logotype"
		  @click="router.push({ path: '/' })" 
		  :theme="theme"
		  class="cursor-pointer"
		/>

		<button
			v-if="location"
			aria-label="button_location"
    	class="
    		cursor-pointer
    		bg-transp 
    		pd-l-micro pd-r-micro
    		radius-extra 
    		uppercase 
    		t-semi
    		br-solid 
    		br-2px 
    		transition-linear
    	
    	"
    	:class="{
    		'fill-black br-black t-black hover-bg-black hover-t-white hover-fill-white': theme === 'light',
    		'fill-white br-white t-white hover-bg-white hover-t-black hover-fill-black': theme === 'dark'  
    	}"
    	@click="openLocationPopup()"
    >
    	<svg class="i-small" :fill="'inherit'" xmlns="http://www.w3.org/2000/svg" width="50" height="67" viewBox="0 0 50 67" fill="none">
			  <path d="M25 0C11.207 0 0 11.207 0 25C0 38.793 20.832 66.668 25 66.668C29.168 66.668 50 38.793 50 25C50 11.207 38.793 0 25 0ZM25 33.332C20.418 33.332 16.668 29.582 16.668 25C16.668 20.418 20.418 16.668 25 16.668C29.582 16.668 33.332 20.418 33.332 25C33.332 29.582 29.582 33.332 25 33.332Z" :fill="'inherit'"/>
			</svg>
    	 {{globals.state.position?.country ? globals.state.position.country : 'World'}}
    </button>
  </div>

  <div class="flex-nowrap flex">	
		<!-- NAGIVATION -->
		<slot></slot>
		<Checkbox 
      name="darkmode"
      :label="'Darkmode'"
      :radio="globals.state.theme.darkmode"
      @update:radio="event => globals.actions.toggleTheme()"
      class="mn-l-thin desktop-only t-semi pd-micro br-2px br-solid radius-extra"
      :theme="theme"
      :class="{
    		'br-light t-black': theme === 'light',
    		'br-dark t-white': theme === 'dark'  
    	}"
    />
  </div>

	<div class="flex-justify-end flex-v-center flex-nowrap flex gap-micro w-min-20 w-max-20r">
		<Button
			aria-label="shopcart"
			:submit="a => shopcart.actions.toggleShopcart()" 
			:counter="shopcart.getters.cartTotalAmount" 
			:showSucces="false"
			:showLoader="false"
			class="pd-zero mn-r-micro"
			> 
			<IconShopcart 
				class="i-semi"
				:fill="theme === 'light' ? 'rgb(var(--black))' : 'rgb(var(--white))'" 
			/>
			<!-- <div class="w-max p-small pos-absolute pos-t-100 pos-r-0">Product Added to Shopcart</div> -->
		</Button>
		
		<Button 
			aria-label="profile"
			class="pd-zero bg-transp"
			:submit="
				auth.state.access.status === false 
				? 
				a => router.push({name: 'Sign In'}) 
				: 
				a => router.push({ name: 'User Profile', params: { _id: auth.state.user._id }})
			"
			:showSucces="false"
			:showLoader="false"
		>
			<IconProfile 
				class="i-semi"
				:fill="theme === 'light' ? 'rgb(var(--black))' : 'rgb(var(--white))'" 
			/>
		</Button>


		<Select 
			v-model:select="$i18n.locale"
		  :options="[{name: '🌎&nbsp;En', value: 'en'}, {name: '🇷🇺&nbsp;Ru', value: 'ru'}]"
		  :property="'value'"
      class="pos-relative uppercase pd-micro t-semi radius-thin"
      :class="{
    		'bg-light t-black': theme === 'light',
    		'bg-dark t-white': theme === 'dark'  
    	}"
    />
  </div>
</template>

<style lang="scss">
.location-button { 
	&:hover {
		box-shadow: inset 0 0 0 2px rgb(var(--main));
	}
}

.menu-btn {
  display: block;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  position: relative;
}
.menu-btn span,
.menu-btn__before,
.menu-btn__after {
  position: absolute;
  top: 50%;
  margin-top: -1px;
  left: 50%;
  margin-left: -10px;
  width: 20px;
  height: 2px;
}
.menu-btn__before,
.menu-btn__after {
  display: block;
  transition: 0.2s;
}
.menu-btn__before {
  transform: translateY(-5px);
}
.menu-btn__after {
  transform: translateY(5px);
}
.menu-btn_active .menu-btn__before {
  transform: rotate(-35deg);
  width: 10px;
  transform-origin: left bottom;
}
.menu-btn_active .menu-btn__after {
  transform: rotate(35deg);
  width: 10px;
  transform-origin: left top;
}

.menu-btn_active span:before {
  transform: rotate(-35deg);
  width: 10px;
  transform-origin: left bottom;
}
.menu-btn_active span:after {
  transform: rotate(35deg);
  width: 10px;
  transform-origin: left top;
}

.menu-block {
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu-nav {
  background-color: #fff;
  height: 50px;
  
}
.menu-nav__link {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  margin-right: 20px;
}
.menu-nav__link {
  transition: 0.5s;
  transform-origin: right center;
  transform: translateX(50%);
  opacity: 0;
}
.menu-nav__link_active {
  transform: translateX(0%);
  opacity: 1;
}

</style>