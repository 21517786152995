<template>
	<section class="z-index-4 transition-cubic pd-big bg-white shop-cart" :class="{'shop-cart-active':shopcart.state.currentState === true}">
    <!-- ---------------------------------------------------------------- -->
    <!-- 01. Popup Header  -->
    <!-- ---------------------------------------------------------------- -->
    <div class="flex-nowrap flex">
      <div class="mn-b-small w-100">
        <h4 class="mn-b-thin p-big">{{t('title')}}</h4>
        <p class="t-transp p-small">{{t('subtitle')}} {{ t('positions', { count: StoreCartAmount }) }}</p>
      </div>
      <IconCross @click="shopcart.actions.toggleShopcart" class="button-icon"/>
    </div>
    <!-- ---------------------------------------------------------------- -->
    <!-- 02. Popup Content  -->
    <!-- ---------------------------------------------------------------- -->
    <div class="mn-b-big shopcart-content">
      <!-- Empty State -->
      <p v-if="!(shopcart.state.positions.length > 0)" class="mn-t-medium"><i>{{t('emptystate')}}</i></p>
      <!-- Shopcart positions -->
      <CardOrderItem 
        v-for="product in shopcart.state.positions" 
        :key="product._id" 
        :editable="true" 
        :product="product" 
        :increase="product => shopcart.actions.incrementItemQuantity(product._id)"
        :decrease="product => shopcart.actions.decrementItemQuantity(product._id)"
        :remove="product => shopcart.actions.removeProduct(product._id)"
      />
    </div>
    <!-- ---------------------------------------------------------------- -->
    <!-- 03. Footer  -->
    <!-- ---------------------------------------------------------------- -->
    <div  class="shopcart-footer pd-b-medium pd-t-medium pd-big transition-cubic bg-light t-black">
      <div v-if="shopcart.state.positions.length > 0" class="mn-b-small total-price">
        <p class="t-transp mn-b-micro">{{ t('intotal') }}</p>
        <p class="t-demi p-big">{{cartTotalPrice}} {{returnCurrency()}}</p>
      </div>
      <button v-if="shopcart.state.positions.length > 0" @click="openOrder()" class="bg-main t-black w-100 button">{{ t('fastorder') }}</button>
    </div>  
  </section>
  <!-- ---------------------------------------------------------------- -->
  <!-- 04. Color Overlay  -->
  <!-- ---------------------------------------------------------------- -->
  <div 
    @click="shopcart.actions.toggleShopcart" 
    :class="{'active':shopcart.state.currentState === true}" 
    class="color-overlay z-index-3">
  </div>
</template>


<script setup>
  /////////////////////////////
  // COMPONENT DEPENDENCIES
  /////////////////////////////
	import { computed,onMounted } from 'vue'
  // Route
  import { useRouter,useRoute } from 'vue-router'
  // i18n
  import { useI18n } from 'vue-i18n'
  // Components
  import CardOrderItem from '@pf/src/modules/orders/components/blocks/CardOrderItem.vue';

  import IconCross from '@pf/src/modules/icons/navigation/IconCross.vue';


  import * as shopcart from '@pf/src/modules/orders/store/shopcart';
  /////////////////////////////
  // CREATED
  /////////////////////////////
  const route = useRoute()
  const router = useRouter()
  // Accessing state
  const cartTotalPrice = shopcart.getters.cartTotalPrice
  const StoreCartAmount = shopcart.getters.cartTotalAmount
  // 
  // router.beforeEach((to, from, next) => {
  //   if (shopcart.state.currentState === true) {
  //     shopcart.actions.toggleShopcart();
  //   }
  //   next(); // Убедитесь, что вызываете next() для продолжения перехода по роуту
  // });

  /////////////////////////////
  // LOCALIZATION
  /////////////////////////////
  const text = {
    messages: {
      en: {
        title: 'Your order',
        subtitle: 'You have',
        positions: 'no products in shop cart | 1 product in shop cart | {count} products in shop card',
        emptystate: "You don't have any positions in your shop cart yet. Maybe something needs to be added?",
        intotal: 'In total',
        fastorder: 'Checkout'
      },
      ru: {
        title: 'Ваш заказ',
        subtitle: 'У вас',
        positions: 'нет товаров в корзине | 1 товар в корзине | {count} товаров в корзине',
        emptystate: "У вас еще нет товаров в корзине. Может стоит что-нибудь добавить?",
        intotal: 'Итого',
        fastorder: 'Подтвердить заказ'
      }
    }
  }

  const { t } = useI18n(text)
  /////////////////////////////
  // MOUNTED
  /////////////////////////////
  function openOrder() {
    // store.dispatch("shopcart/toggleShopCart");
    // store.dispatch("shopcart/setSearch");
    router.push({name: 'Form Order'})
    shopcart.actions.toggleShopcart();
  }
</script>

<style lang="scss">
.shop-cart {
  display: block;
  position: fixed;

  right: -33%;
  top: 0;

  height: 100%;
  width: 33%;

  overflow-y: scroll; 

  .shopcart-footer {
    position: fixed;
    bottom: 0;
    
    width: 33%;
    right: -33%;
  }
}

.shop-cart-active {
  right: 0;

  .shopcart-footer {
    right: 0;
  }
}

@media screen and (max-width: 1025px) {
  .shop-cart {
    width: 100%;
    right: -100%;

    .shopcart-footer {
      width: 100%;
      right: -100%;
    }

    &-active {
      right: 0;

      .shopcart-footer {
        right: 0;
      }
    }
  }
}
</style>


