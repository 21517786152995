<template>
  <section id="account" class="gap-thin pd-thin cols-2-1_3">
    
    <div v-if="route.meta.sidebar" class="w-100">
      <transition name="scaleIn" mode="out-in">
        <component :is="route.meta.sidebar" />
      </transition>
    </div>


    <div class="w-100">
      <router-view  v-slot="{ Component, route }">
        <transition   name="scaleIn" mode="out-in">
          <component ref="page" :key="route.path" :is="Component" />
        </transition>
      </router-view>
    </div>
 
  </section>
</template>


<script setup= props>
/////////////////////////////
// COMPONENT DEPENDENCIES
/////////////////////////////
import { ref, onMounted } from 'vue';
/////////////////////////////
// HELPERS
/////////////////////////////
import { useRoute, useRouter } from 'vue-router'

import * as auth  from '@pf/src/modules/auth/views/store/auth'
import * as organizations  from '@pf/src/modules/organizations/store/organizations'

const route = useRoute()
const router = useRouter()
/////////////////////////////
// CREATED
/////////////////////////////
const props = defineProps({
  newsletterPopup: Number
});

const selectedOrganization = ref(null);
const publics = ref([]);
/////////////////////////////
// MOUNTED
/////////////////////////////
onMounted(async () =>{
})
</script>

<style lang="scss">
</style>
