import { reactive } from 'vue';

// Globals 
import { setError }   from '@pf/src/modules/globals/views/store/globals'

class Store {
  constructor(apiUrl) {
    this.state = reactive({
      items: [],
      current: {}
    });
    this.apiUrl = apiUrl;
  }

  // Simplified request method to avoid repetition and enhance maintainability
  async request(endpoint, options = {}) {
    const url = endpoint.startsWith('http') ? endpoint : `${this.apiUrl}${endpoint}`;

    const defaultOptions = {
      credentials: 'include', // Assumes credentials needed for each request
      headers: {}
    };
    const finalOptions = {
      ...defaultOptions,
      ...options
    };

    // Set default headers if content type is not explicitly set
    if (!finalOptions.headers['Content-Type'] && options.body) {
      finalOptions.headers['Content-Type'] = 'application/json';
      finalOptions.body = JSON.stringify(options.body);
    }

    try {
      const response = await fetch(url, finalOptions);

      if (!response.ok) {
        const errorData = await response.json();
        const error = new Error(errorData.message || 'Unknown error');
        error.errorCode = errorData.errorCode || 'UNKNOWN_ERROR';
        throw error;
      }

      return await response.json();

    } catch (error) {
      console.error(error);
      setError(error);
      throw error;
    }
  }

  async create(item) {
    return await this.request('/create', {
      method: 'POST',
      body: item
    });
  }

  async read(options = {}) {
    const queryParams = new URLSearchParams(options).toString();
    
    return await this.request(`/read?${queryParams}`);
  }

  async update(item) {
    return await this.request('/update', {
      method: 'PUT',
      body: item
    });
  }

  async delete(item) {
    return await this.request(`/delete`, {
      method: 'DELETE',
      body: item
    });
  }

  // Enhanced Mutations utilizing Vue reactivity system efficiently 
  setItems(items, state) {
    const targetState = state || this.state.items;

    state.splice(0, state.length, ...items);
  }

  addItem(item, state, property = '_id') {
    const targetState = state || this.state.items;
    const existingItemIndex = targetState.findIndex(i => i[property] === item[property]);
    
    if (existingItemIndex === -1) {
      targetState.splice(0, 0, item);
    } else {
      Object.assign(targetState[existingItemIndex], item);
    }
  }

  updateItem(item, state, property = '_id') {
      const targetState = state || this.state.items;
      const index = targetState.findIndex(i => i[property] === item[property]);
      if (index !== -1) {
        targetState.splice(index, 1, item);
      }
    }

  removeItem(item, state, property = '_id') {
    const targetState = state || this.state.items;
    // Поиск и удаление элемента по указанному ключу
    const index = targetState.findIndex(i => i[property] === item[property]);
    if (index !== -1) {
      targetState.splice(index, 1);
    }
  }
}

export default Store;