<template>

<section class="pd-thin">

	<!-- Ask to login  -->
	<AskToLogin class="mn-b-thin" v-if="auth.state.access.status === false" /> 

	<!-- Order form -->
	<div class="gap-small mn-b-thin cols-2">
		<!-- Empty State   -->
		<EmptyState  
			v-if="shopcart.state.positions < 1" 
		/>	

		<Succes 
			v-if="order.status === true && shopcart.state.positions.length > 0" 
			:order="order" 
		/>
		<!-- Order Form -->
		<div
			v-if="orders.state.current.status !== true && shopcart.state.positions.length > 0" 
			class="order-form col"
		>
			<FormCustomerDetails
				:customer="orders.state.current.customer"
			/>
			<FormDelivery 	
				v-if="orders.state.current.status !== true && shopcart.state.positions.length > 0"
				:order="orders.state.current"  
			/>
			<FormPayment 	
				v-if="orders.state.current.status !== true && shopcart.state.positions.length > 0"
				:order="orders.state.current"  
			/>
		</div>

		<!-- Order positions -->
		<div 
			v-if="shopcart.state.positions.length > 0" 
			class="bg-light pd-medium radius-big"
		>
			<h4 class="font-second mn-b-thin">Your Order</h4>

			<ul class="flex gap-thin flex-wrap mn-b-thin">
				<CardOrderItem 
					v-for="product in shopcart.state.positions" 
					:key="product._id" 
					:editable="true" 
					:product="product" 
	        :increase="product => shopcart.actions.incrementItemQuantity(product._id)"
	        :decrease="product => shopcart.actions.decrementItemQuantity(product._id)"
	        :remove="product => shopcart.actions.removeProduct(product._id)"
	        class="w-100 bg-white pd-small radius-medium"
	      />
			</ul>

			<div 
				class="bg-main pd-thin radius-medium  mn-b-thin"
			>	
				<p class="pd-small br-b br-solid br-black-transp-10">
					Subtotal: 
					{{ returnCurrency() }} 
					{{ cartTotalPrice }}
				</p>
				<p class="pd-small br-b br-solid br-black-transp-10">
					Delivery: 
					{{ returnCurrency() }} 
					{{ deliveryCost }}
				</p>
				<p class="pd-small h3">
					Total: 
					{{ returnCurrency() }} 
					{{ deliveryCost +  cartTotalPrice.value }}
				</p>
			</div>
			<!-- Send order -->
		  <button 
		  	v-if="orders.state.current.status !== true && shopcart.state.positions.length > 0"
		   	:disabled="
		      errorName 
		      || errorPhoneOrMessenger 
		      || errorCity 
		      || errorAddress 
		      || errorDelivery 
		      || errorPayment
		    "
		    @click="handleCreate()" 
		    class="bg-main mn-b-small pd-small radius-big w-100 button"
		  >
		    Place an Order
		  </button>

		  <p class='mn-b-thin'>
				I agree that placing the order places me under an obligation to make a payment in accordance with <a class="t-semi font-second t-second" href="/legal/terms-of-use" target="_blank">Terms of Use.</a>
			</p>
			<p >
				
			</p>
		</div>

	</div>

	
	
</section>

</template>


<script setup>
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import components
///////////////////////////////////////////////////////////////////////////////////////////////////
// Block
import CardOrderItem from '@pf/src/modules/orders/components/blocks/CardOrderItem.vue'
// Sections
import AskToLogin from '@pf/src/modules/orders/components/sections/AskToLogin.vue'
import Succes from '@pf/src/modules/orders/components/sections/Succes.vue'
import EmptyState from '@pf/src/modules/orders/components/sections/EmptyState.vue'

import FormCustomerDetails from '@pf/src/modules/orders/components/sections/FormCustomerDetails.vue'
import FormDelivery from '@pf/src/modules/orders/components/sections/FormDelivery.vue'
import FormPayment from '@pf/src/modules/orders/components/sections/FormPayment.vue'
///////////////////////////////////////////////////////////////////////////////////////////////////
// Import libs
///////////////////////////////////////////////////////////////////////////////////////////////////
import { ref, computed, onMounted, watch } from 'vue'
import { useRoute,useRouter } from 'vue-router'
// Store
import * as globals from '@pf/src/modules/globals/views/store/globals';
import * as auth from '@pf/src/modules/auth/views/store/auth';
import * as shopcart from '@pf/src/modules/orders/store/shopcart';
import * as organizations from '@pf/src/modules/organizations/store/organizations';
import * as orders from '@pf/src/modules/orders/store/orders';
import * as users from '@pf/src/modules/users/store/users';
///////////////////////////////////////////////////////////////////////////////////////////////////
// Variables and computed
///////////////////////////////////////////////////////////////////////////////////////////////////
// Accessing router and store
const route = useRoute()
const router = useRouter()
// COMPUTED
const order = computed(() => orders.state.current)
const user = computed(() => users.state.current)
const cartTotalPrice = computed(() => shopcart.getters.cartTotalPrice)
const deliveryCost = computed(() => {
  if (orders.state.current.delivery.type === 'pickup') return 0;
  if (orders.state.current.delivery.type === 'mail') return 350;
  if (orders.state.current.delivery.type === 'courier') {
    const distance = orderOrganization.value[0]?.distance || 0;
    return Math.trunc(100 + 20 * distance - 7.5 * (distance / 10));
  }
  return 0;
});

// Computed property for total cost
const totalCost = computed(() => {
  return deliveryCost.value + cartTotalPrice.value.value;
});
// Organization
const orderOrganization = ref({})


onMounted(async()=> {
	orderOrganization.value = await organizations.actions.read({
	  _id: shopcart.state.organization,
	  location: globals.state.position?.location,
	})
})
///////////////////////////////////////////////////////////////////////////////////////////////////
// WATCHERS
///////////////////////////////////////////////////////////////////////////////////////////////////
watch(() => orders.state.current.delivery.address, async (newFilterValue, oldFilterValue) => {
	orderOrganization.value = await organizations.actions.read({
	  _id: shopcart.state.organization,
	  location: orders.state.current.delivery.location,
	})
}, { deep: true })
/////////////////////////////
// Store Verification
/////////////////////////////
const errorName = computed(() => {
  if (orders.state.current.customer.name?.length < 2) { return true } else { return false }
});

const errorPhoneOrMessenger = computed(() => {
  const hasMessengerType = orders.state.current.customer.messenger?.type;
  const hasMessengerValue = orders.state.current.customer.messenger?.value?.length >	 2;
  const hasPhone = orders.state.current.customer.number?.length > 0;
  // Ошибка, если нет ни номера телефона, ни корректного типа и значения мессенджера
  return !hasPhone && !(hasMessengerType && hasMessengerValue);
});

const errorAddress = computed(() => {
	let error = orders.state.current.delivery.type !== "Pickup" && orders.state.current.delivery.address < 2
  
  if (error) { return  true } else { return false }
});

const errorDelivery = computed(() => {
  if (orders.state.current.delivery.type) {return false } else {return true } 
})

const errorPayment = computed(() => {
  if (orders.state.current.payment.type) { return false } else {return true } 
})
/////////////////////////////
// Methods
/////////////////////////////
async function handleCreate() {

	orders.state.current.status = 'created';

	orders.state.current.owner = {
		target: shopcart.state.organization,
		type: 'organization'
	}

	if (auth.state.user._id) {
		orders.state.current.creator = {
			target: auth.state.user._id,
			type: 'User'
		}
	}

	orders.state.current.positions = shopcart.state.positions

 	const referralCode = localStorage.getItem('referalCode');
  
  if (referralCode) {
    orders.state.current.referralCode = referralCode;
  }

  let order = await orders.actions.create(orders.state.current);

  if (order) {
  	shopcart.actions.resetShopcart()

  	router.push({
  		name: 'Order Status',
  		params: {
  			order: order._id
  		}
  	})
  } else {
  	alert('something wrong')
  }
}
</script>

<style lang="scss">

.round-wrapper {
	position: relative;

	.round {
		margin: 0;
		margin-right: 1rem;
	}

	input:checked {
		background: #00ff88;
	}

	.round-checkmark {
		position: absolute;
		width: 0.5rem;
		height: 0.5rem;
		background: white;

		left: 0.5rem;
		top:  0.5rem;

		opacity: 1;
	}
}
</style>