<template>
	<div id="app-wrapper" class="pos-relative">
		<transition name="moveFromTop" appear>
			<Loader v-if="!page" class="pos-fixed"/>
		</transition>

		<header 
			v-if="!MOBILE_APP && route.meta.header.component"
			id="header" 
			class="
				pd-thin 
				gap-micro
				flex-justify-between
				flex-nowrap
				flex
				h-4r
				w-100
				z-index-2 
				pos-relative pos-t-0
			"
			:class="{
	  		't-black bg-white': headerTheme === 'light',
	  		't-white bg-black': headerTheme === 'dark'  
	  	}"
		>
      <component
        ref="header" 
        :is="route.meta.header.component"
        :theme="headerTheme"
        :logotype="route.meta.header.logotype"
        :location="route.meta.header.location"
      >
        <component
          v-if="route.meta.navigation"
          :is="route.meta.navigation"
          class="mn-l-auto desktop-only"
        />
      </component>
	  </header>

		<transition name="moveFromTop" mode="out-in" appear>
			<NavigationBar
		  	v-if="MOBILE_APP"
		  	:logotype="route.meta.header.logotype"
		  />
		</transition>

	  <Popup 
	  	v-if="route.meta.header.location"
	  	@close-popup="closeLocationPopup" 
	  	:isPopupOpen="globals.state.isOpenLocationPopup"
	  	class="bg-white pd-semi w-m-33r radius-big"
	  >	
	  	<LocationSelection />
	  </Popup>

		<section 
			id="screen" 
			ref="screen"
			@scroll="handleScroll"
			class="transition-ease-in-out h-min-100 w-100 bg-white pos-relative o-y-scroll o-x-hidden"
		>
			<ShopCart />

			<!-- <section v-if="!route.meta?.breadcrumbs?.hide" class="pd-thin pd-b-zero">
				<Breadcrumbs 
					v-if="!MOBILE_APP"
					class="bg-light pd-small radius-big"
				/>
			</section> -->

			<Suspense>
				<router-view  
					v-slot="{ Component, route }" 
					:class="{'mn-b-extra': MOBILE_APP}"
				>
					<Status 
						v-if="globals.state.error.show" 
						:data="globals.state.error"
						@close="globals.state.error.show = false"
						class="z-index-7" 
					/>

					<transition @before-enter="scrollTop" name="scaleTransition" mode="out-in" appear>
		      	<component 
		      		ref="page"  
		      		:is="Component" 
		      		:key="route.path"
		      	/>
		      	<!-- Key пока выключил непонятно какие проблемы это вызовет -->
		      	<!--  -->
		      </transition>

		      <!-- <Footer /> -->
				</router-view>
			</Suspense>

			<router-view  
				name="defaultBottom"
				v-slot="{ Component, route }" 
			>
				<component 
      		:is="Component" 
      	/>
			</router-view>

			<component
		  	v-if="route.meta.radio?.page"
        :is="route.meta.radio.page"
      />
     
		</section>

		<component
	  	v-if="route.meta.radio?.player"
      :is="route.meta.radio.player"
    />

    <BottomNavigationBar 
      v-if="MOBILE_APP && route.meta.title_hide"
      class="pd-small z-index-2"
    />
	</div>
</template>



<script setup>
	import { computed, ref, onMounted  } from 'vue';
	// Router
	import { useRoute } from 'vue-router';
	// Store
	import * as globals from '@pf/src/modules/globals/views/store/globals';
	// Partials
	import Status from '@pf/src/components/Status/Status.vue';
	import Popup from '@pf/src/components/Popup/Popup.vue';
	// import Breadcrumbs from '@pf/src/components/Breadcrumbs/Breadcrumbs.vue'
	import Loader from '@pf/src/components/Loader/Loader.vue';

	import BottomNavigationBar from '@pf/src/modules/globals/views/components/partials/BottomNavigationBar.vue';

	import NavigationBar from '@pf/src/modules/globals/views/components/partials/NavigationBar.vue';
	import LocationSelection from '@pf/src/modules/globals/views/components/partials/LocationSelection.vue';
	// import Footer from '@pf/src/modules/globals/views/components/partials/Footer.vue'

	import ShopCart from '@pf/src/modules/orders/components/partials/ShopCart.vue';
	/////////////////////////////
  // LOADING
  /////////////////////////////
    // State
  let show = ref(false)
  // Preloader
  const page = ref(null)
	/////////////////////////////
	// CREATED
	/////////////////////////////
	const route = useRoute()
	// const router = useRouter()
	// Ref Code
	const referalCode = ref(route.query.referalCode);
	/////////////////////////////
	// Methods
	/////////////////////////////
	function closeLocationPopup() {
	  globals.state.isOpenLocationPopup = false;
	}
	function scrollTop(){
		document.getElementById('app').scrollIntoView();
	}
	// Scrolling header
	const scrollOffset = ref(0)
	let isScrolled = false
	const header = ref(null)

	const headerTheme = computed(() => {
		if (scrollOffset.value > 50) {
			return 'light'
		} else {
			return route.meta.header_theme || 'light'
		}
	})

	const handleScroll = () => {
	  scrollOffset.value = event.target.scrollTop
	};

	onMounted(async () => {
		 
		if ('serviceWorker' in navigator) {
	   	window.addEventListener('load', () => {
	     	navigator.serviceWorker.register('/service-worker.js').then(registration => {
	       	console.log('SW registered: ', registration);
	     	}).catch(registrationError => {
	       	console.log('SW registration failed: ', registrationError);
	     	});
	   });
	 	}
		
		const savedPosition = localStorage.getItem('position');

	  if (savedPosition) {
	    globals.state.position = JSON.parse(savedPosition);
	  } else {
  		globals.state.isOpenLocationPopup = true;
	  }

		if (referalCode.value) {
		  localStorage.setItem('referalCode', referalCode.value);
		}

		if (page.value) show.value = true
	});
</script>

<style lang="scss">;	
	.moveFromTop-enter-active,
	.moveFromTop-leave-active {
		transform: translateY(0);
		opacity: 1;
		transition: all 0.5s ease; 
		
	}
	.moveFromTop-enter-from,
	.moveFromTop-leave-to {
		transform: translateY(-1rem);
		opacity: 0;
		transition: all 0.5s ease;
	}

	.moveFromTopAbsolute-enter-active,
	.moveFromTopAbsolute-leave-active {
		transform: translateY(0);
		opacity: 1;
		transition: all 0.5s ease; 
		
	}
	.moveFromTopAbsolute-enter-from,
	.moveFromTopAbsolute-leave-to {
		position: absolute;
		transform: translateY(-1rem);
		opacity: 0;
		transition: all 0.5s ease;
	}
	.ScaleOut-enter-active,
	.ScaleOut-leave-active {
		opacity: 1;
		transform: scale(1);
		transition: all 0.3s ease; 
		
	}
	.ScaleOut-enter-from,
	.ScaleOut-leave-to {
		opacity: 0;
		transform: scale(0.9);
		transition: all 0.3s ease;
	}
	.slide-fade-enter-active {
		// min-height: 100vh;
	  transition: all  0.3s cubic-bezier(0.4, 0, 0.2, 1);
	}

	.slide-fade-leave-active {
		// min-height: 100vh;
	  transition: all  0.3s cubic-bezier(0.4, 0, 0.2, 1);
	}

	.slide-fade-enter-from,
	.slide-fade-leave-to {
		min-height: 0;
	  transform: translateX(20px);
	  opacity: 0;
	  left: 0;
	  top: 0;
	}

	.scaleTransition-5px-enter-active,
  .scaleTransition-5px-leave-active {
    transform: translateY(0px);  
    opacity: 1;
    z-index: 1;
    transition: all 0.2s ease;
  }

  .scaleTransition-5px-enter-from,
  .scaleTransition-5px-leave-to {
    opacity: 0;
    z-index: 0;
    position: absolute;
    transform: translateY(-30px); 
    transition: all 0.2s ease;
  }

  .scaleTransition-enter-from,
  .scaleTransition-leave-to {
    opacity: 0;
    z-index: 0;
    position: absolute;
    width: inherit;
    height: inherit;
    display: block;
    transform: translateY(-30px); 
    transition: all 0.5s ease;
  }

  .scaleTransition-enter-active,
  .scaleTransition-leave-active {
    transform: translateY(0px);  
    opacity: 1;
    z-index: 1;
    transition: all 0.5s ease;
  }
  .scaleTransition-enter-from,
  .scaleTransition-leave-to {
    opacity: 0;
    z-index: 0;
    position: absolute;
    width: inherit;
    height: inherit;
    display: block;
    transform: translateY(-30px); 
    transition: all 0.5s ease;
  }

  .scaleIn-enter-active,
  .scaleIn-leave-active {
    // background: red;
    transition: all 0.5s ease;
    > section,div { transform-origin: 0 0; transform: translateZ(0px);  transition: all 0.5s ease; }
  }
  .scaleIn-enter-from,
  .scaleIn-leave-to {
    opacity: 0;
    transform: scale(0.95);
    > section,div { transform: translateZ(-30px);  transition: all 0.5s ease; }
  }
</style>